import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import "./leftside.scss";
import { primaryMenu, secondaryMenu } from "../../assets/constant";
import { accountService } from "../../_services/accountServices";
import { AuthContext } from "../../context/AuthContext";

const LeftSide = () => {
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  let arrayP1 = location.pathname.split("/");
  arrayP1.push("home");
  let givenRoute = arrayP1[2];

  const __primaryMenu = primaryMenu.map((value, index) => {
    let arrayP2 = value.linkTo.split("/");
    arrayP2.push("home");
    let currentRoute = arrayP2[2];

    return (
      <Link key={index} to={value.linkTo}>
        <li
          className={
            givenRoute === currentRoute ? "menuList-active" : "menuList"
          }
        >
          <span className="menuIcon"> {value.icon}</span>
          <span className="menuLabel">{value.label}</span>
        </li>
      </Link>
    );
  });

  const __secondaryMenu = secondaryMenu.map((value, index) => {
    const { label, icon, linkTo } = value;

    const disconnect = () => {
      dispatch({ type: "LOGOUT" });
      accountService.logout();
      navigate("/");
      window.location.reload(true);
    };

    const handleDisconnect = label === "Deconnexion" ? disconnect : null;

    let arrayP2 = value.linkTo.split("/");
    arrayP2.push("home");
    let currentRoute = arrayP2[2];

    return (
      <Link key={index} to={linkTo}>
        <li
          className={
            givenRoute === currentRoute ? "menuList-active" : "menuList"
          }
          onClick={handleDisconnect}
        >
          <span className="menuIcon"> {icon}</span>
          <span className="menuLabel">{label}</span>
        </li>
      </Link>
    );
  });

  return (
    <div className="leftside">
      <ul className="primaryMenu">
        <span className="title">MENU</span>
        <div className="menu">{__primaryMenu}</div>

        <span className="title">UTILISATEUR</span>
        <div className="menu">{__secondaryMenu}</div>
      </ul>

      <div className="themeModeCard">
        <div className="light"></div>
        <div className="dark"></div>
      </div>
    </div>
  );
};

export default LeftSide;
