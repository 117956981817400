import React from 'react'

const SyntheseGlobalPresence = ({ nbPresence, nbAbsence, nbRetard, nbAbsenceJustif, nbRetardJustif }) => {

    return (
        <table style={{ minWidth: '80%', padding: "30px 30px" }}>

            <tr>
                <th style={{ backgroundColor: "#EEEEFF", height: "2rem", color: '#999' }}>Présence</th>
                <td><span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#2595FE", fontSize: '14px' }}>{nbPresence}</span> </td>
            </tr>

            <tr>
                <th style={{ backgroundColor: "#EEEEFF", height: "2rem", color: '#999' }}>Retard</th>
                <td><span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#2595FE", fontSize: '14px' }}>{nbRetard}</span> </td>
            </tr>
            <tr>
                <th style={{ backgroundColor: "#EEEEFF", height: "2rem", color: '#999' }}>Retard Justifié</th>
                <td><span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#2595FE", fontSize: '14px' }}>{nbRetardJustif}</span> </td>
            </tr>
            <tr>
                <th style={{ backgroundColor: "#EEEEFF", height: "2rem", color: '#999' }}>Absence</th>
                <td><span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#2595FE", fontSize: '14px' }}>{nbAbsence}</span> </td>
            </tr>
            <tr>
                <th style={{ backgroundColor: "#EEEEFF", height: "2rem", color: '#999' }}>Absence Justifié</th>
                <td><span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#2595FE", fontSize: '14px' }}>{nbAbsenceJustif}</span> </td>
            </tr>

        </table>
    )
}

export default SyntheseGlobalPresence