import axios from "axios";
import { URL_BACKEND_APP } from "../App.constants";

const url_api = axios.create({
  baseURL: URL_BACKEND_APP + "/paiement",
});

const url_api_montant = axios.create({
  baseURL: URL_BACKEND_APP + "/montant",
});

const PAIEMENT = {
  create: (data, token) => {
    return url_api.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return url_api.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return url_api.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return url_api.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return url_api.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
const MONTANT = {
  create: (data, token) => {
    return url_api_montant.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return url_api_montant.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return url_api_montant.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return url_api_montant.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return url_api_montant.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export const API_PAIEMENT = { PAIEMENT, MONTANT };
