import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, Typography, Divider,
} from '@mui/material'
import { PrintRounded, ChromeReaderModeRounded } from '@mui/icons-material'

import TableFinanceTrimesterHistoriqueUnpaid from '../../Tableau/Table_FinanceTrimesterHistoriqueUnpaid'
import { useReactToPrint } from 'react-to-print'
import { accountService } from '../../_services/accountServices'

const FinancePaiementTrimesterUnpaid = ({ open, setOpen, unPaidNumber, unPaidList }) => {

    const [eleveSearch, setEleveSearch] = useState("");
    const [selectedClasse, setSelectedClasse] = useState("TOUTES")

    const classes = accountService.getClasseList();

    const StyledIconTitle = styled('span')({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem'
    })

    const handleChange = (e) => {
        setEleveSearch(e.target.value)
    }


    const printRef = useRef()

    const handleClickPrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Catalogue semestriel des élèves irréguliers"

    })

    return (
        <>
            <Dialog fullWidth maxWidth='md' sx={{ height: "500px", top: "1rem" }}
                open={open}
            // onClose={() => setOpenDialog(false)}
            >
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>
                    <StyledIconTitle>
                        <ChromeReaderModeRounded color='primary' />
                        <Typography variant='h6' color="primary" sx={{ fontWeight: "500", fontSize: "15px" }} >Catalogue semestrielle des élèves irréguliers</Typography>
                        <Typography variant='text' color="#fff" sx={{ fontWeight: "600", padding: '1px 5px', borderRadius: '10px', backgroundColor: '#0091EA', fontSize: '14px' }} >{unPaidNumber}</Typography>

                    </StyledIconTitle>

                    <select
                        style={{ border: '2px solid #0091EA', color: '#0091EA' }}
                        defaultValue={selectedClasse} onChange={(e) => setSelectedClasse(e.target.value)}
                    >

                        {classes.map((value, index) => {
                            // return <option key={index} value={value} >{value.toUpperCase()}</option>
                            return <option key={index} value={index === 0 ? 'TOUTES' : value.designation}>{index === 0 ? 'TOUTES' : value.designation.toUpperCase()}</option>

                        })}
                    </select>

                    <input type="text" value={eleveSearch} onChange={handleChange} placeholder='  Rechercher...'
                        style={{ border: '2px solid #0091EA', color: '#000', borderRadius: '4px', fontSize: '16px', padding: '0px 15px' }}
                        onFocus={{ color: '#0091EA' }}

                    />

                </DialogTitle>
                <Divider />
                <DialogContent>

                    <DialogContentText>

                        <TableFinanceTrimesterHistoriqueUnpaid unPaidList={unPaidList} searchByClasse={selectedClasse} eleveSearch={eleveSearch} printRef={printRef} />

                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button variant='text' startIcon={<PrintRounded color='primary' />} onClick={handleClickPrint} color='primary'> Imprimer </Button>

                    <Button variant='text' onClick={() => setOpen(false)} color='warning'>Quitter</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FinancePaiementTrimesterUnpaid