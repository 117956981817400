import React, { useState, useId, useEffect } from "react";
import {
  CheckCircleOutline,
  CloudOff,
  VerifiedOutlined,
} from "@mui/icons-material";
import PresenceDetail from "../../component/dialog/PresenceDetail.tsx";
import HeaderPrint from "../../component/headerPrint/HeaderPrint";
import {
  EleveType,
  PonctualiteType,
  requestType,
} from "../../types/GlobalType";

const ListElevesCotationJournalier = ({
  listEleve,
  searchByClasse,
  formatDate,
  printRef,
  msg,
  forceUpdateOnUpdate,
}: {
  listEleve: PonctualiteType[];
  searchByClasse: string;
  formatDate: string;
  printRef: any;
  msg: any;
  forceUpdateOnUpdate: () => void;
}) => {
  console.log(listEleve);
  const [isOpen, setIsOpen] = useState(false);

  const listId = useId();
  const [singleData, setsingleData] = React.useState<PonctualiteType>();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const tableBody = (
    <tbody>
      {listEleve &&
        listEleve
          .filter((item: PonctualiteType) => {
            return searchByClasse === "TOUTES"
              ? item.classe_designation?.includes("")
              : item.classe_designation?.includes(searchByClasse);
          })
          .map((item: PonctualiteType, index) => {
            var justif: string = "";
            if (item.cote_eleve_designation !== "Presence") {
              if (item.status_justif === 0) {
                justif = "non";
              } else if (item.status_justif === 1) {
                justif = "oui";
              }
            }

            return (
              <tr
                key={listId}
                className={index % 2 === 0 ? "" : "print_row_color"}
                onClick={() => {
                  setsingleData(item);
                  setIsOpen(true);
                }}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #9db2d857",
                }}
              >
                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    color: "#777",
                  }}
                >{`00${index + 1}`}</td>
                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    color: "#777",
                  }}
                >
                  {item.nom}
                </td>
                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    color: "#777",
                  }}
                >
                  {item.postnom}
                </td>
                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    color: "#777",
                  }}
                >
                  {item.classe_designation}
                </td>
                <td style={{ textAlign: "center", position: "relative" }}>
                  {item.cote_eleve_id === 1 ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        color: "#fff",
                        fontWeight: "700",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "skyblue",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      P
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        color: "#555",
                        fontWeight: "700",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      P
                    </div>
                  )}
                </td>
                <td style={{ textAlign: "center", position: "relative" }}>
                  {item.cote_eleve_id === 2 ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        color: "#fff",
                        fontWeight: "700",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "skyblue",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      R
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        color: "#555",
                        fontWeight: "700",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      R
                    </div>
                  )}
                </td>

                <td style={{ textAlign: "center", position: "relative" }}>
                  {item.cote_eleve_id === 3 ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        color: "#fff",
                        fontWeight: "700",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "skyblue",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      A
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        color: "#555",
                        fontWeight: "700",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      A
                    </div>
                  )}
                </td>
                <td style={{ textAlign: "center", position: "relative" }}>
                  {!!item.status_justif ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        color: "#fff",
                        fontWeight: "700",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#16841d7e",
                        // backgroundColor: "skyblue",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VerifiedOutlined />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        color: "#555",
                        fontWeight: "700",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      J
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
    </tbody>
  );

  const tableBodyNoData = (
    <tbody>
      <tr>
        <td colSpan={7}>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "4rem",
            }}
          >
            <CloudOff sx={{ color: "#3c72d5ed", fontSize: "4rem" }} />
            <span style={{ fontSize: "13px", color: "#3c72d5ed" }}>
              Aucune donnée disponible pour le jour selectionné
            </span>
          </span>
        </td>
      </tr>
    </tbody>
  );
  const tableBodyNoConnexion = (
    <div style={{ marginTop: "30px" }}>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
        }}
      >
        <CloudOff sx={{ color: "skyblue", fontSize: "4rem" }} />
        <span
          style={{ fontSize: "13px", color: "skyblue", fontStyle: "italic" }}
        >
          Connexion impossible, réessayer plus tard
        </span>
      </span>
    </div>
  );

  return (
    <>
      {listEleve ? (
        <>
          <div ref={printRef}>
            <HeaderPrint title={"ETPM - Listes de présence des élèves"} />

            <table
              style={{
                borderCollapse: "collapse",
                margin: "5px",
                padding: "0px 10px",
                marginTop: "10px",
                width: "99.5%",
                minHeight: "300px",
              }}
            >
              <thead style={{ backgroundColor: "#EEEEFF", height: "3.0rem" }}>
                <tr style={{ fontSize: "13px", color: "#3c72d5ed" }}>
                  <th>Num</th>
                  <th>Nom</th>
                  <th>Post-nom</th>
                  <th>Classe</th>
                  <th>P</th>
                  <th>R</th>
                  <th>A</th>
                  <th>Justif</th>
                </tr>
              </thead>

              {listEleve.length === 0 ? (
                <>{tableBodyNoData}</>
              ) : (
                <>{tableBody}</>
              )}
            </table>
          </div>
        </>
      ) : (
        <>{/* <SimpleBackdrop /> */}</>
      )}

      {msg.type === "ERR_NETWORK_FETCH" ? tableBodyNoConnexion : <></>}

      <PresenceDetail
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        eleveData={singleData}
        formatDate={formatDate}
        forceUpdateOnUpdate={forceUpdateOnUpdate}
      />
    </>
  );
};

export default ListElevesCotationJournalier;
