import React from "react";
import "./UpdateCommunication.scss";

import { Close, CommentOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
}
const UpdateCommunication = (props: Props) => {
  const handleChange = (event) => {
    // setRole(event.target.value);
  };
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className="UpdateCommunication">
      <div className="dialog">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <CommentOutlined className="ico" />
              <span>Modification - Communiqué </span>
            </div>
            <div className="right_">
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </div>
          </div>
        </div>

        <div className="body">
          <div className="items_com">
            <h5>Destinateur</h5>
            <select
              //   onChange={handleChange}
              name="destinateur"
              // value={data.annee_existence}
            >
              <option value="hhhh">PARENTS</option>
              <option value="hhhh">ENSEIGNANTS</option>
            </select>
          </div>

          <div className="items_com">
            <h5>Objet</h5>
            <input type="text" placeholder="Objet du comminiqué" />
          </div>
          <div className="items_com">
            <textarea rows={8} placeholder="communiqué..." />
          </div>
          <div className="btns">
            <button className="cancel" onClick={() => props.setOpen(false)}>
              Annuler
            </button>
            <button className="send">Modifier</button>
          </div>
          <span className="brouillon">Enregister en tant que brouillon</span>
        </div>
      </div>
    </div>
  );
};

export default UpdateCommunication;
