import React, { useState } from "react";
import ChartRetardThree from "../../chart/ChartRetardThree";
import { monthNames } from "../../../assets/constant";
import { useEffect } from "react";
import API from "../../../App.constants";
import axios from "axios";
import SyntheseGlobalPresence from "../../../Tableau/elevePonctualite/SynytheseGlobalPresence";
import { accountService } from "../../../_services/accountServices";
import toast from "react-hot-toast";
import { PonctualiteType, requestType } from "../../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import { API_ELEVE } from "../../../apiCaller/eleve.Caller";
import moment from "moment";

const RapportGlobal = () => {
  const [allPonctualite, setallPonctualite] = React.useState<PonctualiteType[]>(
    []
  );

  const exactJour = moment().format("YYYY-MM");
  const [defaultFormatDate, setdefaultFormatDate] = useState(exactJour);

  const handleChange = (e) => {
    let monthYear = e.target.value;
    setdefaultFormatDate(monthYear);
  };

  const navigate = useNavigate();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });
  const fetchAllPonctualite = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();
    if (!token) return navigate("/");

    API_ELEVE.PONCTUALITE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: [] = [];

        if (response.data === "") {
          setallPonctualite(empyData);
        } else {
          setallPonctualite(response.data.data);
        }

        console.log(response);
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  let listEleve: PonctualiteType[] = [];
  let listPresence: PonctualiteType[] = [];
  let listAbsence: PonctualiteType[] = [];
  let listRetard: PonctualiteType[] = [];
  let listAbsenceJustify: PonctualiteType[] = [];
  let listRetardJustify: PonctualiteType[] = [];

  if (allPonctualite.length > 0) {
    for (let item of allPonctualite) {
      if (
        moment(item.ponctualite_eleve_created_at).format("YYYY-MM") ===
        defaultFormatDate
      ) {
        listEleve.push(item);
      }
    }
  }

  if (listEleve.length > 0) {
    for (let item of listEleve) {
      switch (item.cote_eleve_id) {
        case 1: {
          listPresence.push(item);
          break;
        }
        case 2: {
          listRetard.push(item);
          break;
        }
        case 3: {
          listAbsence.push(item);
          break;
        }
        case 4: {
          listRetardJustify.push(item);
          break;
        }
        case 5: {
          listAbsenceJustify.push(item);
          break;
        }

        default:
          break;
      }
    }
  }

  useEffect(() => {
    fetchAllPonctualite();
  }, []);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "5fr 2fr",
        gap: "10px",
        marginTop: "20px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "0px 0px 12px 12px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: ".2rem",
            backgroundColor: "#fff",
            borderRadius: "0px 8px 8px 0px ",
          }}
        >
          <div className="inputDate">
            <input
              type="month"
              value={defaultFormatDate}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="tableList">
          {/* <PaiementTable /> */}
          <SyntheseGlobalPresence
            nbPresence={listPresence.length}
            nbAbsence={listAbsence.length}
            nbRetard={listRetard.length}
            nbAbsenceJustif={listAbsenceJustify.length}
            nbRetardJustif={listRetardJustify.length}
          />
        </div>
      </div>

      {/* -----------------LEFT SIDE/ IT SHOWS DETAIL INFORMATION OF CLASS AND SPECICIFIC STUDENT-------------- */}
      <div className="detailClasse">
        <div className="detail">
          <div className="detailTitle">
            <span className="optionTitle">Synthèse mensuelle de présence</span>
            <hr />
          </div>

          <ChartRetardThree
            nbPresence={listPresence.length}
            nbAbsence={listAbsence.length}
            nbRetard={listRetard.length}
            nbAbsenceJustif={listAbsenceJustify.length}
            nbRetardJustif={listRetardJustify.length}
          />
        </div>
      </div>
    </div>
  );
};

export default RapportGlobal;
