import React from 'react'
import Chart from 'react-apexcharts'

const ChartRetardThree = ({ nbPresence, nbAbsence, nbRetard, nbAbsenceJustif, nbRetardJustif }) => {

    const series = [nbPresence, nbRetard, nbAbsence, nbRetardJustif, nbAbsenceJustif];

    const options = {
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '30%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    }
                }
            }
        },
        colors: ['#0084ff', '#85E9F5', '#FC0000', '#00C56E', '#601674'],
        labels: ['Présence', 'Retard', 'Absence', 'Retard Justifié', 'Absence Justifié'],
        legend: {
            show: true,
            labels: {
                useSeriesColors: true,
            },
            floating: true,
            fontSize: '12px',
            position: 'left',
            offsetX: 10,
            offsetY: -10,
            formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
            itemMargin: {
                vertical: 2
            }
        }
    }


    return (
        <Chart
            type='radialBar'
            series={series}
            options={options}
            height={300}
        />
    )
}

export default ChartRetardThree