import "./communique.scss";
import {
  ArrowBackRounded,
  CallMadeOutlined,
  DeleteOutline,
  FeedbackRounded,
  FilterAltOutlined,
  ForwardToInboxOutlined,
  MailOutlined,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { Divider, Stack, Box, Tooltip } from "@mui/material";
import React, { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import NewCommunication from "./NewCommunication.tsx";
import UpdateCommunication from "./UpdateCommunication.tsx";
import SingleCommunication from "./SingleCommunication.tsx";
import toast from "react-hot-toast";

const ACTION_TYPES = {
  activateDelete: "activateDelete",
  selectedItem: "selectedItem",
  initalized: "initalized",
};

const initialState = {
  openDelete: false,
  selected: 0,
};

function deleteReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.activateDelete:
      return { ...state, openDelete: true };
    case ACTION_TYPES.selectedItem:
      return { ...state, selected: payload };
    case ACTION_TYPES.initalized:
      return initialState;

    default:
      break;
  }
}

const Communique = () => {
  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openSingle, setOpenSingle] = useState(false);

  const [state, dispatch] = useReducer(deleteReducer, initialState);

  const notify = () => toast.error("Aucun communiqué selectionné");

  const handleDelete = () => {
    console.log("HandleDelete====>");

    if (state.selected === 0) return notify();
  };

  return (
    <>
      <Box
        bgcolor="#eee"
        flex={5}
        p={1}
        height="100%"
        sx={{ marginLeft: "200px", marginTop: "66px" }}
      >
        <Stack direction="row" justifyContent="space-between">
          <div className="homeMenu">
            <Link to="/home" style={{ marginTop: "5px" }}>
              <ArrowBackRounded
                sx={{
                  color: "#0091EA",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                }}
              />
            </Link>
            <div className="homeTitleItem">
              <span className="homeTitle"> Communiqué</span>
            </div>
            <div className="homeTitleItem2"></div>
          </div>
          <div className="Searchbar_">
            <Search className="icon" />
            <input
              placeholder="Rechercher"
              type="text"
              // value={props.searchValue}
              // onChange={(e) => props.setSearchValue(e.target.value)}
            />
          </div>
        </Stack>

        <Divider sx={{ marginTop: "5px" }} />

        <div className="containerMsg">
          <div className="msgList">
            <div className="com_title">
              <span>Liste</span>
              <div className="com_option">
                <div className="com_del">
                  <DeleteOutline
                    className="icoDel"
                    onClick={() =>
                      dispatch({ type: ACTION_TYPES.activateDelete })
                    }
                  />
                  {state.openDelete && (
                    <>
                      <span onClick={handleDelete}>Supprimer</span> |{" "}
                      <span
                        onClick={() =>
                          dispatch({ type: ACTION_TYPES.initalized })
                        }
                      >
                        Annuler
                      </span>
                    </>
                  )}
                </div>
                <div className="filter">
                  <select name="filter" id="">
                    <option value="Tous">Tous</option>
                    <option value="Tous">Envoyés</option>
                    <option value="Tous">Brouillons</option>
                    <option value="Tous">Parents</option>
                    <option value="Tous">Enseignants</option>
                  </select>
                  <FilterAltOutlined />
                </div>
              </div>
            </div>
            <div className="listMsg">
              <div className="com_content">
                <div className="box_msg">
                  {state.openDelete && (
                    <div
                      className={state.selected === 0 ? "case" : "filledCase"}
                      onClick={() => {
                        if (state.selected === 0) {
                          dispatch({
                            type: ACTION_TYPES.selectedItem,
                            payload: 1,
                          });
                        } else {
                          dispatch({
                            type: ACTION_TYPES.selectedItem,
                            payload: 0,
                          });
                        }
                      }}
                    ></div>
                  )}
                  <div
                    className="left"
                    onClick={() => {
                      setOpenSingle(true);
                    }}
                  >
                    {/* <input type="checkbox" name="" id="" /> */}
                    <div className="message">
                      <div className="sender small">
                        <div className="avatar">
                          <span>A</span>
                        </div>
                        <span>Hello world</span>
                      </div>
                      <div className="sender">
                        <div className="avatar">A</div>
                      </div>
                      <div className="text">
                        <span className="label_recipient">Cc: Parents, </span>
                        <span className="label_subject">Frais Scolaire</span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dignissimos maxime dolor, dolores facilis porro non
                        reprehenderit veritatis. Adipisci obcaecati iusto minima
                        labore, dolores alias. Eos expedita fugiat dolor magni
                        laudantium!
                        <span className="date">22/02/2024</span>
                        <span className="label_send">Envoyé </span>
                        <span className="label_draft">Brouillon </span>
                      </div>
                    </div>
                  </div>

                  <div className="icons">
                    <div>
                      <Tooltip title={"Modifier"}>
                        <MoreVert
                          className="icon"
                          onClick={() => setOpenUpdate(true)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="listMsg">
              <div className="com_content">
                <div className="box_msg">
                  <div className="left">
                    {/* <input type="checkbox" name="" id="" /> */}
                    <div className="message">
                      <div className="sender small">
                        <div className="avatar">
                          <span>A</span>
                        </div>
                        <span>Hello world</span>
                      </div>
                      <div className="sender">
                        <div className="avatar">A</div>
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dignissimos maxime dolor, dolores facilis porro non
                        reprehenderit veritatis. Adipisci obcaecati iusto minima
                        labore, dolores alias. Eos expedita fugiat dolor magni
                        laudantium!
                        <span className="date">22/02/2024</span>
                      </div>
                    </div>
                  </div>

                  <div className="icons">
                    <div>
                      <Tooltip title={"Modifier"}>
                        <MoreVert className="icon" />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="listMsg">
              <div className="com_content">
                <div className="box_msg">
                  <div className="left">
                    {/* <input type="checkbox" name="" id="" /> */}
                    <div className="message">
                      <div className="sender small">
                        <div className="avatar">
                          <span>A</span>
                        </div>
                        <span>Hello world</span>
                      </div>
                      <div className="sender">
                        <div className="avatar">A</div>
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dignissimos maxime dolor, dolores facilis porro non
                        reprehenderit veritatis. Adipisci obcaecati iusto minima
                        labore, dolores alias. Eos expedita fugiat dolor magni
                        laudantium!
                        <span className="date">22/02/2024</span>
                      </div>
                    </div>
                  </div>

                  <div className="icons">
                    <div>
                      <Tooltip title={"Modifier"}>
                        <MoreVert className="icon" />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialogMsg">
            <button onClick={() => setOpenNew(true)}>Nouveau</button>

            <div className="items_msg">
              <div className="left_part">
                <div className="icoContainer">
                  <MailOutlined className="ico" />
                </div>
                <span>Total</span>
              </div>
              <span>4</span>
            </div>
            <div className="items_msg">
              <div className="left_part">
                <div className="icoContainer">
                  <CallMadeOutlined className="ico" />
                </div>
                <span>Envoyés</span>
              </div>
              <span>4</span>
            </div>
            <div className="items_msg">
              <div className="left_part">
                <div className="icoContainer">
                  <FeedbackRounded className="ico" />
                </div>
                <span>Draft</span>
              </div>
              <span>4</span>
            </div>
            <hr />
            <div className="items_msg">
              <div className="left_part">
                <div className="icoContainer">
                  <ForwardToInboxOutlined className="ico" />
                </div>
                <span>Parent</span>
              </div>
              <span>4</span>
            </div>
            <div className="items_msg">
              <div className="left_part">
                <div className="icoContainer">
                  <ForwardToInboxOutlined className="ico" />
                </div>
                <span>Enseignant</span>
              </div>
              <span>4</span>
            </div>
          </div>
        </div>
      </Box>

      {openNew && <NewCommunication setOpen={setOpenNew} />}
      {openSingle && <SingleCommunication setOpen={setOpenSingle} />}
      {openUpdate && <UpdateCommunication setOpen={setOpenUpdate} />}
    </>
  );
};

export default Communique;
