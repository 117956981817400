import React from 'react';
import '../../assets/styles/printpage.css'
import SimpleBackdrop from '../../component/loader/CircularBackdrop';
import HeaderPrint from '../../component/headerPrint/HeaderPrint';
// import { PREFIX } from '../../App.constants';


const TableauRetardDetailPlus = ({ dataDetailPlus, printRef }) => {

    // ---------------------------------
    //     TABLEAU DETAIL PLUS ELEVE
    // ---------------------------------


    const tableBody = (
        <tbody>
            {(dataDetailPlus) &&

                dataDetailPlus.map((item, index) => {

                    // console.log("-------------item-----------")
                    // console.log(item)
                    // const { id_card, noms, designation, photo,jour, heure_arrive, heure_depart, cote, motif, justification } = item
                    const { date, heure_arrive, heure_sortie, cote, justification } = item;

                    var justif = ''
                    if (cote !== 'Presence') {
                        if (justification === 0) {
                            justif = "Non "
                        } else if (justification === 1) {
                            justif = "Oui"
                        }
                    }

                    return (
                        <tr key={index} style={{ borderBottom: '1px solid #9db2d857' }}>
                            <td style={{ textAlign: 'center', fontSize: "13px" }}>{`0${index + 1}`}</td>
                            <td style={{ textAlign: 'center', fontSize: "13px" }}>{date}</td>
                            <td style={{ textAlign: 'center', fontSize: "13px" }}>{(heure_arrive === "") || (heure_arrive === null) ? ("--") : (heure_arrive)}</td>
                            <td style={{ textAlign: 'center', fontSize: "13px" }}>{(heure_sortie === "") || (heure_sortie === null) ? ("--") : (heure_sortie)}</td>

                            <td style={{ textAlign: 'center' }}>
                                <span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#76b6f7", fontSize: '12px', borderRadius: "2px" }}>
                                    {cote === 'Presence' ? 'Présent' : ''}
                                </span>
                            </td>

                            <td style={{ textAlign: 'center' }}>
                                <span style={{ color: "#fff", padding: "3px 5px", backgroundColor: "#999", fontSize: '12px', borderRadius: "2px" }}>
                                    {cote === 'Retard' ? 'Retard' : ''}
                                </span>
                            </td>


                            <td style={{ textAlign: 'center' }}>
                                <span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#000", fontSize: '12px', borderRadius: "2px" }}>
                                    {cote === 'Absence' ? 'Absent' : ''}
                                </span>
                            </td>

                            <td style={{ textAlign: 'center' }}>
                                <span style={{ color: "#fff", padding: "1px 5px", backgroundColor: "#76b6f7", fontSize: '12px', borderRadius: "2px" }}>
                                    {justif}
                                </span>
                            </td>
                        </tr>
                    )
                })}

        </tbody>
    )

    return (
        <table style={{ borderCollapse: 'collapse' }} ref={printRef} >
            <thead style={{ backgroundColor: "#EEEEFF", height: "2.5rem" }}>
                <tr>
                    <th colSpan={8}>
                        <HeaderPrint title={`Catalogue de Ponctualité - Elève`} />
                    </th>
                </tr>
                <tr style={{ fontSize: "13px" }}>
                    <th>Num</th>
                    <th>Date</th>
                    <th>Arrivée</th>
                    <th>Sortie</th>
                    <th>P</th>
                    <th>R</th>
                    <th>A</th>
                    <th>Justif</th>
                </tr>
            </thead>

            {
                dataDetailPlus ?
                    <>

                        {tableBody}

                    </>
                    :
                    <>
                        <SimpleBackdrop />
                    </>
            }

        </table>
    )
}

export default TableauRetardDetailPlus