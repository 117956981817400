import "./navbar.scss";
import LOGO from "../../assets/images/chiksoft_blue.png";
import {
  ArrowDropDown,
  BarChartOutlined,
  Brightness5Outlined,
  Language,
  Logout,
  Person,
  SmsFailedOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from 'react-router-dom'
// import { accountService } from '../../_services/accountServices';
import { Offline, Online } from "react-detect-offline";
import { PREFIX } from "../../App.constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { accountService } from "../../_services/accountServices";

const NavBar = () => {
  // const navigate = useNavigate()
  // const [open, setOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { dispatch } = useContext<any>(AuthContext);
  const disconnect = () => {
    dispatch({ type: "LOGOUT" });
    accountService.logout();
    navigate("/");
    window.location.reload();
  };
  // useEffect(() => {
  //   Update network status
  //   const handleStatusChange = () => {
  //     setIsOnline(navigator.onLine);
  //   };

  //   Listen to the online status
  //   window.addEventListener("online", handleStatusChange);

  //   Listen to the offline status
  //   window.addEventListener("offline", handleStatusChange);

  //   Specify how to clean up after this effect for performance improvment
  //   return () => {
  //     window.removeEventListener("online", handleStatusChange);
  //     window.removeEventListener("offline", handleStatusChange);
  //   };
  // }, [isOnline]);

  // const showMenu = () => {};

  // const username = accountService.getUserName().split('"');
  // let user = username.split('"')

  return (
    <div className="navbar">
      <div className="logo">
        <img src={LOGO} alt="logo" />
        <div className="appname">
          <span>CHIK</span>
          <span>Soft</span>
        </div>
      </div>
      <>
        <div className="icons">
          {/* <Link to="/messages">
            <Tooltip title="Notifications">
              <div className="message">
                <SmsFailedOutlined className="icon" />
                <span>10</span>
              </div>
            </Tooltip>
          </Link> */}

          {/* <Link to="/rapports">
            <Tooltip title="Rapports">
              <div className="notification">
                <BarChartOutlined className="icon" />
                <span>3</span>
              </div>
            </Tooltip>
          </Link> */}

          <div className="language">
            <Language className="langIcon" />
            <span>{"Admin"}</span>
          </div>

          <Tooltip title="Paramètre du compte">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <div className="user">
                <Person className="icon" />
                {/* <span>{capitalize(username)}</span> */}
                <ArrowDropDown />
              </div>
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          className="setting_menu"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Link to="/profil">
            <MenuItem sx={{ fontSize: "small" }}>
              <Avatar />
              Profile
            </MenuItem>
          </Link>
          <Divider />

          <Link to="/home/configuration/configuration">
            <MenuItem sx={{ fontSize: "small" }}>
              <ListItemIcon>
                <Brightness5Outlined fontSize="small" />
              </ListItemIcon>
              Configuration
            </MenuItem>
          </Link>
          {/* <Link to="/configuration">
        <MenuItem sx={{ fontSize: "small" }}>
          <ListItemIcon>
            <History fontSize="small" />
          </ListItemIcon>
          Activités
        </MenuItem>
      </Link>
      <Divider /> */}

          <MenuItem onClick={disconnect} sx={{ fontSize: "small" }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Déconnexion
          </MenuItem>
        </Menu>
      </>
    </div>
  );
};

export default NavBar;
