import React from 'react'
import styled from '@emotion/styled'

import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, Typography, Divider,
} from '@mui/material'
import { ChromeReaderModeRounded } from '@mui/icons-material'
import Tableau from './ListDataTable'

const ListAgentDialog = ({ open3, setOpen3, userData }) => {

    const StyledIconTitle = styled('span')({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem'
    })

    return (
        <>
            <Dialog fullWidth maxWidth='md' sx={{ height: "500px", top: "2rem" }}
                open={open3}
            >
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>
                    <StyledIconTitle>
                        <ChromeReaderModeRounded color='primary' sx={{ fontSize: 18 }} />
                        <Typography variant='h6' color="primary" sx={{ fontWeight: "500", fontSize: 15 }} >Liste des agents</Typography>
                    </StyledIconTitle>

                    {/* <div className="inputDate" style={{ marginTop: "5px" }}>
                        <input type="date" style={{ height: "35px" }} defaultValue={exactJour} value={defaultDay} onChange={handleDateChange} />
                    </div> */}

                </DialogTitle>
                <Divider />
                <DialogContent>

                    <DialogContentText>

                        <Tableau userData={userData} />

                    </DialogContentText>

                </DialogContent>
                <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    {/* <Button variant='text' startIcon={<PrintRounded color='primary' />} onClick={handleClickPrint} color='primary'> Imprimer </Button> */}

                    <Button variant='text' onClick={() => setOpen3(false)} color='warning'>Quitter</Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default ListAgentDialog;