import React from 'react';
import './header.scss'
import { ArrowBackRounded } from '@mui/icons-material';

import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = ({ backTo, icon, primaryTitle, secondaryTitle }) => {
    return (

        < Stack direction="row" justifyContent="space-between" >
            <div className='homeMenu'>
                <Link to={backTo} style={{ marginTop: '5px' }}>
                    <ArrowBackRounded sx={{ color: "#0091EA", backgroundColor: "#fff", borderRadius: "50%" }} />
                </Link>
                <div className='homeTitleItem'>
                    <span className='homeTitle'> {primaryTitle}</span>
                </div>
                <div className='homeTitleItem2'>
                    {/* <ContentCopyOutlined sx={{ color: "#1565C0" }} /> */}
                    {icon}
                    <span className='homeTitleSmall' >{secondaryTitle}</span>
                </div>
            </div>
        </Stack >
    )
}

export default Header
