import React from "react";
import "./search.scss";
import Search from "@mui/icons-material/Search";

type Props = {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
};
const SearchBar = () => {
  return (
    <div className="Searchbar">
      <Search className="icon" />
      <input
        // placeholder={props.label}
        type="text"
        // value={props.searchValue}
        // onChange={(e) => props.setSearchValue(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
