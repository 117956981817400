import { Alert, Snackbar } from '@mui/material';
import React from 'react'

const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
};

const SnackbarNotification = ({ text, severty }) => {
    return (
        <Snackbar open={true} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severty} sx={{ width: '100%' }}>
                {text}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarNotification
