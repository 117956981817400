import React, { useState } from "react";

import {
  AvTimerOutlined,
  AccessTimeRounded,
  Assessment,
  Assignment,
  ArrowBackRounded,
} from "@mui/icons-material";
import { Stack, Box, Fab, Divider } from "@mui/material";
import RapportJournalier from "../component/retard/personnel/Rapport_journalier.tsx";
import RapportMensuel from "../component/retard/personnel/Rapport_global_presence.tsx";
import RapportGlobal from "../component/retard/personnel/Rapport_global.tsx";
import { Link } from "react-router-dom";

const Retard = () => {
  const [showEleve, setshowEleve] = useState(true);
  const [showPersonnel, setshowPersonnel] = useState(true);

  const [showStackOne, setShowStackOne] = useState(true);
  const [showStackTwo, setShowStackTwo] = useState(false);
  const [showStackThree, setShowStackThree] = useState(false);

  const handleShowOne = () => {
    setShowStackOne(true);
    setShowStackTwo(false);
    setShowStackThree(false);
  };
  const handleShowTwo = () => {
    setShowStackOne(false);
    setShowStackTwo(true);
    setShowStackThree(false);
  };

  const handleShowThree = () => {
    setShowStackOne(false);
    setShowStackTwo(false);
    setShowStackThree(true);
  };

  const handleShowEleve = () => {};
  const handleShowPersonnel = () => {};

  return (
    <Box
      bgcolor="#eee"
      flex={5}
      p={1}
      sx={{ marginLeft: "200px", marginTop: "66px" }}
    >
      {/* ------------------PAGE TITLE-------------------- */}

      <Stack direction="row" justifyContent="space-between">
        <div className="homeMenu">
          <Link to="/home" style={{ marginTop: "5px" }}>
            <ArrowBackRounded
              sx={{
                color: "#0091EA",
                backgroundColor: "#fff",
                borderRadius: "50%",
              }}
            />
          </Link>
          <div className="homeTitleItem">
            <span className="homeTitle"> Ponctualité Personnel</span>
          </div>
          <div className="homeTitleItem2">
            <AvTimerOutlined sx={{ color: "#1565C0" }} />
            <span className="homeTitleSmall">
              {showStackOne && "Rapport jounalier"}
              {showStackTwo && "Rapport global journalier"}
              {showStackThree && "Rapport global mensuel"}
            </span>
          </div>
        </div>
        <div className="rightBtn">
          <Link to="/home/retard-eleve">
            <span
              className={showEleve === false ? "clickedSpan" : "clickedNotSpan"}
              onClick={handleShowEleve}
            >
              Elèves
            </span>
          </Link>
          <Link to="/home/retard-personnel">
            <span
              className={
                showPersonnel === true ? "clickedSpan" : "clickedNotSpan"
              }
              onClick={handleShowPersonnel}
            >
              Personnels
            </span>
          </Link>
        </div>
      </Stack>
      <Divider sx={{ marginTop: "8px" }} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "10px",
          marginTop: "8px",
        }}
      >
        <div
          className={setShowStackOne ? "dashbordItem-active" : "dashbordItem"}
        >
          <div className="dashbordItemRetard">
            <div className="dashDesc">
              <div
                className={
                  showStackOne ? "dashbordItemDown-active " : "dashbordItemDown"
                }
                onClick={handleShowOne}
              >
                Rapport jounalier
              </div>
              <div className="descTitle2">
                Aperçu de ponctualitée journalière
              </div>
            </div>
            <div className="dashIcon">
              <Fab aria-label="add">
                <AccessTimeRounded color="primary" />
              </Fab>
            </div>
          </div>
        </div>

        <div
          className={setShowStackTwo ? "dashbordItem-active" : "dashbordItem"}
        >
          <div className="dashbordItemRetard">
            <div className="dashDesc">
              <div
                className={
                  showStackTwo ? "dashbordItemDown-active " : "dashbordItemDown"
                }
                onClick={handleShowTwo}
              >
                Rapport global journalier
              </div>
              <div className="descTitle2">Ponctualité globale</div>
            </div>
            <div className="dashIcon">
              <Fab aria-label="add">
                <Assignment color="primary" />
              </Fab>
            </div>
          </div>
        </div>

        <div
          className={setShowStackThree ? "dashbordItem-active" : "dashbordItem"}
        >
          <div className="dashbordItemRetard">
            <div className="dashDesc">
              <div
                className={
                  showStackThree
                    ? "dashbordItemDown-active "
                    : "dashbordItemDown"
                }
                onClick={handleShowThree}
              >
                Rapport global mensuel
              </div>
              <div className="descTitle2">Aperçu globale mensuelle</div>
            </div>
            <div className="dashIcon">
              <Fab aria-label="add">
                <Assessment color="primary" />
              </Fab>
            </div>
          </div>
        </div>
      </div>

      {/* -------TABLE OF CONTENT------------ */}

      {showStackOne && <RapportJournalier />}
      {showStackTwo && <RapportMensuel />}
      {showStackThree && <RapportGlobal />}
    </Box>
  );
};
export default Retard;
