import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  openMsg: boolean;
  setOpenMsg: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
  textBtn: string;
  textStyle: string;
  setOpen: string;
  statusText: string;
};
const NotificationDialog = (props: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.statusText === "Unauthorized") {
      navigate("/");
    }
    props.setOpenMsg(false);
    // window.location.reload(true);
  };
  return (
    <>
      <Dialog open={props.openMsg}>
        <DialogTitle> {props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack>
              <Alert>
                <AlertTitle>
                  <i>{props.message}</i>
                </AlertTitle>
              </Alert>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "#eee",
              padding: "5px 20px",
              fontWeight: "600",
              marginRight: "15px",
            }}
            variant="text"
            color="success"
            size="small"
            onClick={handleClick}
          >
            {props.textBtn}
          </Button>
          {/* <Button sx={{ background: "#eee", padding: "5px 20px", fontWeight: "600", marginRight: "15px" }} variant='text' color='success' size='small' onClick={handleClick}>{textBtnActualiser}</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationDialog;
