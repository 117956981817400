import ReactApexChart from "react-apexcharts";
import "./ChartColumn.scss";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { depenseContext, paiementContext } from "../../../context/AppContext";
import moment from "moment";
import { PaiementType } from "../../../types/GlobalType";

const ColumnChart = ({ isLoading }) => {
  const paiementData: PaiementType[] = useContext(paiementContext);
  const depenseData = useContext(depenseContext);
  //force reload
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [reducerReloadValue, forceReload] = React.useReducer((x) => x + 1, 0);
  // const [updateComp, SetUpdateComp] = React.useState(0);

  //get set of all the year included in the orders & the currentDate
  let yearsValaible: any[] = [];
  let yearsArray: any[] = [];
  if (paiementData.length > 0) {
    for (let item of paiementData) {
      let y = moment(item.paiement_created_at).year();
      yearsValaible.push(y.toString());
    }
  }
  var now = moment();
  var currentYear = moment(now).year();
  yearsValaible.push(currentYear.toString());

  const yearSet = new Set(yearsValaible);
  yearsArray = Array.from(yearSet);

  // const [selectedYear, setselectedYear] = useState();
  const [selectedYear, setselectedYear] = useState<string>(
    currentYear.toString()
  );
  //-----------------------------------------------
  //get the number of order by selected year year
  let paiementBySelectedYear: PaiementType[] = [];
  if (paiementData.length > 0) {
    paiementData.forEach((element) => {
      if (element.paiement_created_at?.includes(selectedYear))
        paiementBySelectedYear.push(element);
    });
  }

  console.log(selectedYear);
  console.log(paiementBySelectedYear);

  //-----------------------------------------------
  //get the number of paiement per month of a selected year
  //months containing command & secondly filter orders which are sold
  let jan: any[] = [];
  let fev: any[] = [];
  let mar: any[] = [];
  let avr: any[] = [];
  let mai: any[] = [];
  let jun: any[] = [];
  let jui: any[] = [];
  let aou: any[] = [];
  let sep: any[] = [];
  let oct: any[] = [];
  let nov: any[] = [];
  let dec: any[] = [];
  //months containing command sold(commande reglées)
  let jan_sold = [];
  let fev_sold = [];
  let mar_sold = [];
  let avr_sold = [];
  let mai_sold = [];
  let jun_sold = [];
  let jui_sold = [];
  let aou_sold = [];
  let sep_sold = [];
  let oct_sold = [];
  let nov_sold = [];
  let dec_sold = [];
  //push data in month containing order&push data in month is those order are sold
  if (paiementBySelectedYear.length > 0) {
    for (let item of paiementBySelectedYear) {
      let month = moment(item.paiement_created_at).month();

      switch (month) {
        case 0:
          jan.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) jan_sold.push(item);
          break;
        case 1:
          fev.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) fev_sold.push(item);
          break;
        case 2:
          mar.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) mar_sold.push(item);
          break;
        case 3:
          avr.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) avr_sold.push(item);
          break;
        case 4:
          mai.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) mai_sold.push(item);
          break;
        case 5:
          jun.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) jun_sold.push(item);
          break;
        case 6:
          jui.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) jui_sold.push(item);
          break;
        case 7:
          aou.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) aou_sold.push(item);
          break;
        case 8:
          sep.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) sep_sold.push(item);
          break;
        case 9:
          oct.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) oct_sold.push(item);
          break;
        case 10:
          nov.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) nov_sold.push(item);
          break;
        case 11:
          dec.push(item);
          // if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) dec_sold.push(item);
          break;
        default:
          break;
      }
    }
  }

  let reducer = (accum: number, curvalue: PaiementType) =>
    accum + curvalue.montant;
  let jan_sum = jan.reduce(reducer, 0);
  let fev_sum = fev.reduce(reducer, 0);
  let mar_sum = mar.reduce(reducer, 0);
  let avr_sum = avr.reduce(reducer, 0);
  let mai_sum = mai.reduce(reducer, 0);
  let jun_sum = jun.reduce(reducer, 0);
  let jui_sum = jui.reduce(reducer, 0);
  let aou_sum = aou.reduce(reducer, 0);
  let sep_sum = sep.reduce(reducer, 0);
  let oct_sum = oct.reduce(reducer, 0);
  let nov_sum = nov.reduce(reducer, 0);
  let dec_sum = dec.reduce(reducer, 0);
  // console.log(jan, fev, mar, avr, mai, jun, jui, aou, sep, oct, nov, dec);
  // console.log(
  // jan.length,
  // fev.length,
  // mar.length,
  // avr.length,
  // mai.length,
  // jun.length,
  // jui.length,
  // aou.length,
  // sep.length,
  // oct.length,
  // nov.length,
  // dec.length
  // );
  //-----------------------------------------------
  // console.log(
  // jan_sold.length,
  // fev_sold.length,
  // mar_sold.length,
  // avr_sold.length,
  // mai_sold.length,
  // jun_sold.length,
  // jui_sold.length,
  // aou_sold.length,
  // sep_sold.length,
  // oct_sold.length,
  // nov_sold.length,
  // dec_sold.length
  // );

  //-----------------------------------------------
  //state of our chart

  const state = {
    series: [
      {
        name: "Prévisions",
        data: [
          1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000,
          1000,
        ],
        // data: [
        //   jan.length,
        //   fev.length,
        //   mar.length,
        //   avr.length,
        //   mai.length,
        //   jun.length,
        //   jui.length,
        //   aou.length,
        //   sep.length,
        //   oct.length,
        //   nov.length,
        //   dec.length,
        // ],
        color: "#1b2b4a28",
      },
      {
        name: "Paiements",
        // data: [65, 35, 41, 36, 26, 45, 48, 52, 53, 41, 33, 45],
        data: [
          jan_sum,
          fev_sum,
          mar_sum,
          avr_sum,
          mai_sum,
          jun_sum,
          jui_sum,
          aou_sum,
          sep_sum,
          oct_sum,
          nov_sum,
          dec_sum,
        ],
        color: "#54ed75d6",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Fév",
          "Mar",
          "Avr",
          "Mai",
          "Jun",
          "Jul",
          "Aou",
          "Sep",
          "Oct",
          "Nov",
          "Déc",
        ],
      },
      yaxis: {
        title: {
          text: "Nombres",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            // return val + " poussins";
            return val;
          },
        },
      },
    },
  };

  return (
    <div className="chartContainer_col">
      <div className="chartHeader_col">
        <span className="title_chart">
          Statistiques annuelles des paiements
        </span>
        <select
          name=""
          // value={selectedYear}
          // onChange={(e) => setselectedYear(e.target.value)}
        >
          <option>2021</option>
        </select>
      </div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ColumnChart;
