import { People, PaidSharp, ArrowBackRounded } from "@mui/icons-material";
import { Stack, Box, Divider } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import PaiementJournalier from "../../component/dialog/PaiementJournalier.tsx";
import ListElevesPaiement from "../../Tableau/ListElevesPaiement.tsx";
// import { monthNames } from '../../assets/constant';
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { accountService } from "../../_services/accountServices";
import { API_CLASSE } from "../../apiCaller/classe.Caller";
import toast from "react-hot-toast";
import {
  ClasseType,
  EleveType,
  PaiementType,
  requestType,
} from "../../types/GlobalType";
import { API_ELEVE } from "../../apiCaller/eleve.Caller";

import { API_PAIEMENT } from "../../apiCaller/paiement.Caller.js";

const Paiement = () => {
  const [open, setOpen] = React.useState(false);
  const [totalEleve, setTotalEleve] = useState(0);
  const [montant, setMontant] = useState(0);
  const [eleveSearch, setEleveSearch] = useState("");
  const [selectedClasse, setSelectedClasse] = useState("TOUTES");

  const [classes, setclasses] = useState<ClasseType[]>([]);
  const [eleveData, setEleveData] = React.useState<EleveType[]>([]);
  const [paiementData, setpaiementData] = React.useState<PaiementType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const fetchAllClasse = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_CLASSE.CLASSE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData = [];

        if (response.data === "") {
          setclasses(empyData);
        } else {
          setclasses(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard", {
            duration: 5000,
          });
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };
  const fetchAllEleve = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_ELEVE.ELEVE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: EleveType[] = [];

        if (response.data === "") {
          setEleveData(empyData);
        } else {
          setEleveData(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard", {
            duration: 5000,
          });
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  const fetchAllPaiement = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_PAIEMENT.PAIEMENT.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Data: {
        //   config: null;
        //   data: { data: PaiementType[]; message: string };
        //   status: number;
        //   statusText: string;
        // } = {
        //   config: null,
        //   data: { data: [], message: "Empty data" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: PaiementType[] = [];

        if (response.data === "") {
          setpaiementData(empyData);
        } else {
          setpaiementData(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setEleveSearch(e.target.value);
  };

  let sumMontant: number = 0;
  if (paiementData.length > 0) {
    for (let item of paiementData) {
      sumMontant = sumMontant + item.montant;
    }
  }

  useEffect(() => {
    fetchAllClasse();
    fetchAllEleve();
    fetchAllPaiement();
  }, []);

  const handleClick_HistoriqueMensuel = () => {
    setOpen(true);
  };

  console.log(paiementData);

  return (
    <Box
      bgcolor="#eee"
      flex={5}
      p={1}
      sx={{ marginLeft: "200px", marginTop: "60px" }}
    >
      {/* ------------------PAGE TITLE-------------------- */}

      <Stack direction="row" justifyContent="space-between">
        <div className="homeMenu">
          <Link to="/home" style={{ marginTop: "5px" }}>
            <ArrowBackRounded
              sx={{
                color: "#0091EA",
                backgroundColor: "#fff",
                borderRadius: "50%",
              }}
            />
          </Link>
          <div className="homeTitleItem">
            <span className="homeTitle"> Paiement</span>
          </div>
          <div className="homeTitleItem2">
            <PaidSharp sx={{ color: "#1565C0" }} />
            <span className="homeTitleSmall">Paiement des frais scolaires</span>
          </div>
        </div>
      </Stack>
      <Divider sx={{ marginTop: "3px" }} />

      <Header
        montant={sumMontant}
        onClickHistorique={handleClick_HistoriqueMensuel}
        totalEleve={paiementData.length}
      />

      <Stack direction="row" sx={{ margin: ".7rem 0rem" }}>
        <div className="paymentContenair">
          <div className="paiementMsg">
            <div className="iconPaiement">
              <People sx={{ color: "#888" }} />
              <span className="classeTitle">Classes</span>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <select
                defaultValue={selectedClasse}
                onChange={(e) => setSelectedClasse(e.target.value)}
                className="selectClasse"
              >
                <option value={"TOUTES"}>{"TOUTES"}</option>
                {classes.map((value, index) => {
                  return (
                    <option key={index} value={value.classe_designation}>
                      {value.classe_designation.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="input-class">
            <input
              type="text"
              value={eleveSearch}
              onChange={handleChange}
              placeholder="   Rechercher par nom..."
              className="search-bar"
            />
          </div>
        </div>
      </Stack>

      {/* tableau de tous les eleves pour le paiement */}
      <div className="tableList">
        {/* <PaiementTable /> */}
        <ListElevesPaiement
          searchByClasse={selectedClasse}
          eleveSearch={eleveSearch}
          data={eleveData}
          // handleChange={handleChange}
        />
      </div>

      {/* dialog montant l'historique mensuelle en cliquant sur historique*/}

      {open && (
        <PaiementJournalier
          setOpen={setOpen}
          dataPaiement={paiementData}
          dataEleve={eleveData}
        />
      )}
    </Box>
  );
};

export default Paiement;
