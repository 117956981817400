import React, { useState } from "react";
import "./updateschoolyear.scss";

import { Close, SchoolOutlined } from "@mui/icons-material";

import "react-phone-input-2/lib/style.css";
import { IconButton } from "@mui/material";
import { requestType, schooYearType } from "../../../types/GlobalType";
import toast, { LoaderIcon } from "react-hot-toast";
import { accountService } from "../../../_services/accountServices";
import { API_CONFIG } from "../../../apiCaller/configuration.Caller";
import { useNavigate } from "react-router-dom";
import MyLoading from "../../../component/loader/MyLoading.tsx";
import Loader from "../../../component/loader/Loader.tsx";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: schooYearType | undefined;
  dispatchReload: () => void;
}

const UpdateScolarite = (props: Props) => {
  const [data, setdata] = useState({
    schoolYear: props.data?.annee_scolaire_designation,
  });

  const [isValid, setIsValid] = useState<boolean>();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const handleChange = (event) => {
    setdata({ ...data, [event.target.name]: event.target.value });
  };

  const navigate = useNavigate();

  const regex = /^\d{4}-\d{4}$/;

  const handleUpdate = () => {
    // setState({ isLoading: true, errorMessage: null, successMessage: null });

    if (data?.schoolYear === "") {
      setState({
        isLoading: false,
        errorMessage: "Veuiller complétez l'année",
      });
      return toast.error("Veuiller complétez l'année");
    }

    // Check if the format is valid
    if (data.schoolYear) {
      if (regex.test(data.schoolYear)) {
        const [firstYear, secondYear] = data.schoolYear?.split("-").map(Number);
        // Check if the second year is greater than the first
        setIsValid(secondYear > firstYear);
      } else {
        setIsValid(false);
        return toast.error("Année scolaire entrée est invalide");
      }
    }
    setState({ isLoading: true, errorMessage: null, successMessage: null });
    let token = accountService.getToken();
    API_CONFIG.ANNEE_SCOLAIRE.update(
      props.data?.annee_scolaire_id,
      { designation: data?.schoolYear },
      token
    )
      .then((response) => {
        // console.log(response);
        setState({
          isLoading: false,
        });
        props.setOpen(false);
        toast.success("Mise à jour effectuée avec succes", { duration: 3000 });
        props.dispatchReload();
      })
      .catch((err) => {
        console.log(err);
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez", {
            duration: 4000,
          });
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  return (
    <>
      <Loader isLoading={state.isLoading} />

      <div className="UpdateScolarite">
        <div className="dialog_update">
          <div className="headerUpdate">
            <div className="titleUpdate">
              <div className="left_">
                <SchoolOutlined className="ico" />
                <span>Modification</span>
              </div>
              <IconButton>
                <Close className="ico" onClick={() => props.setOpen(false)} />
              </IconButton>
            </div>
          </div>

          <div className="body_update">
            <div className="item">
              <label>Année scolaire</label>
              <input
                placeholder="Ex: 2020-2021"
                type="text"
                name="schoolYear"
                value={data.schoolYear}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="btn">
            <button onClick={() => props.setOpen(false)}>Annuler</button>
            <button onClick={handleUpdate}>Mettre à jour</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateScolarite;
