import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  Button,
  TextField,
  Typography,
  Stack,
  Alert,
  AlertTitle,
  Divider,
} from "@mui/material";

import { Key, VisibilityRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../loader/CircularBackdrop";
import { useEffect } from "react";
import { accountService } from "../../_services/accountServices";
import user_api from "../../apiCaller/user.Caller";
import toast from "react-hot-toast";
import { API_PAIEMENT } from "../../apiCaller/paiement.Caller";

const DialoguePaiementConfirmPsw = ({
  openDialog,
  setOpenDialog,
  motifFrais,
  montant,
  setMontant,
  id_card,
}) => {
  const [typeValue, setTypeValue] = useState("password");
  const [pswValue, setPswValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  const [openDialogSuccessPsw, setOpenDialogSuccessPsw] = useState(false);
  const [openDialogSuccessPaiement, setOpenDialogSuccessPaiement] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [connectedAgent, setConnectedAgent] = useState("");

  const handleShowPsw = () => {
    typeValue === "password" ? setTypeValue("text") : setTypeValue("password");
  };

  const navigate = useNavigate();

  const doPaiement = () => {
    setErrorPass(false);

    if (pswValue === "") {
      setErrorPass(true);
      return setErrorMsg("Veuillez enter le mot de passe");
    }

    setIsLoading(true);

    user_api
      .login({ email: connectedAgent, password: pswValue })
      .then((response) => {
        setIsLoading(false);
        setOpenDialog(false);

        if (response.status === 202 && response.data.token) {
          toast.success("Athentification validée", { duration: 3000 });
          setOpenDialogSuccessPsw(true);
          setSuccessMsg(response.data.message);
          validePaiement();
        } else {
          setIsLoading(false);
          setErrorMsg("Une erreur s'est produit--1");
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (error.code === "ERR_NETWORK") {
          setErrorPass(true);
          return setErrorMsg("Verifier votre connexion internet");
        } else if (err.response.status === 401) {
          setErrorPass(true);
          return setErrorMsg("Mot de passe incorrect");
        } else {
          setErrorPass(true);
          return setErrorMsg(
            "Une erreur inconnue s'est produit, réessayer plus tard"
          );
        }
      });
  };

  const validePaiement = () => {
    setIsLoading(true);
    setError(false);

    let token = accountService.getToken();
    let paiementData = {
      montant: montant,
      eleve_id: id_card,
      motif_paiement_id: "1",
      personnel_id: "1",
      annee_scolaire_id: "1",
      uid_terminal: "12345677",
    };
    API_PAIEMENT.PAIEMENT.create(paiementData, token)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setOpenDialogSuccessPaiement(true);
        setOpenDialogSuccessPsw(false);
        setSuccessMsg("Paiement effectuée");
      })
      .catch((error) => {
        console.log("catch error paiement");
        console.log(error);

        setIsLoading(false);

        if (error.response.status === 401) {
          setOpenDialogSuccessPsw(false);
          setError(true);
          return setErrorMsg("Le montant entré est superieur au reste à payer");
        }

        if (error.code === "ERR_NETWORK") {
          setOpenDialogSuccessPsw(false);
          setError(true);
          return setErrorMsg(
            error.message + ": Vérifier la connexion Internet et réessayer"
          );
        } else {
          setOpenDialogSuccessPsw(false);
          setError(true);
          return setErrorMsg("Une erreur est survenu,réessayer plus tard");
        }
      });
  };

  useEffect(() => {
    let AgentEmail = accountService.getUserEmail();
    setConnectedAgent(AgentEmail);
  }, []);

  return (
    <>
      {!isLoading ? (
        <Dialog open={openDialog} components={"div"}>
          <DialogTitle
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Key /> Confirmation de Paiement{" "}
          </DialogTitle>
          <Divider />

          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ mb: "2rem", fontSize: "14px" }}>
                Veuillez entrer votre mot de passe pour confirmer ce paiement de{" "}
                {montant} $ pour {motifFrais}
              </Typography>

              <TextField
                type={typeValue}
                label="Mot de passe"
                value={pswValue}
                onChange={(e) => setPswValue(e.target.value)}
                // helperText='Le devise accepté est le dollar (usd)'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <VisibilityRounded
                        onClick={handleShowPsw}
                        sx={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {errorPass && (
                <Typography sx={{ mt: "1rem", color: "red" }}>
                  {errorMsg}
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="error"
              onClick={() => {
                setOpenDialog(false);
                setErrorPass(false);
                setMontant("");
                setPswValue("");
              }}
            >
              Annuler
            </Button>
            <Button
              variant="text"
              size="small"
              sx={{ marginRight: "15px" }}
              onClick={doPaiement}
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <SimpleBackdrop />
      )}

      {/* dialog box of success password et verifie le depassement du montant*/}
      {error && (
        <Dialog open={error}>
          <DialogTitle> Alerte </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Stack>
                <Alert severity="warning">
                  <AlertTitle>
                    <strong>Echec de paiement de {montant} $</strong>
                  </AlertTitle>
                  {errorMsg}
                </Alert>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ marginRight: "15px" }}
              onClick={() => {
                setOpenDialogSuccessPsw(false);
                setError(false);
                setSuccessMsg("");
                setMontant("");
                setPswValue("");
                setError(false);
              }}
            >
              Retourner
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {!isLoading ? (
        <Dialog open={openDialogSuccessPsw}>
          <DialogTitle> Validation du paiement </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Stack>
                <Alert severity="info">
                  <AlertTitle>
                    <strong>{successMsg}</strong>
                  </AlertTitle>
                  Valider pour confirmer le paiement de
                  <strong> {montant} $ !</strong>
                </Alert>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() => {
                setOpenDialogSuccessPsw(false);
                setError(false);
                setSuccessMsg("");
                setMontant("");
                setPswValue("");
              }}
            >
              annuler
            </Button>

            <Button
              sx={{
                background: "#eee",
                padding: "5px 20px",
                fontWeight: "600",
                marginRight: "15px",
              }}
              variant="text"
              color="success"
              size="small"
              onClick={validePaiement}
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <SimpleBackdrop />
      )}

      {/* dialog box of success paiement */}

      <Dialog open={openDialogSuccessPaiement}>
        <DialogTitle> </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack>
              <Alert severity="success">
                <AlertTitle>
                  <strong>{successMsg}</strong>
                </AlertTitle>
                L'opération a réussie —{" "}
                <strong>vous avez effectué un paiement de {montant} $ !</strong>
              </Alert>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="success"
            size="small"
            sx={{ marginRight: "15px" }}
            onClick={() => {
              setOpenDialogSuccessPaiement(false);
              setError(false);
              setSuccessMsg("");
              setMontant("");
              setPswValue("");
              navigate("/home/paiement");
            }}
          >
            returner
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialoguePaiementConfirmPsw;
