import { CloudOff } from '@mui/icons-material'
import React from 'react'

const ConnexionError = ({ colSpan }) => {
    return (

        <tbody>
            <tr>
                <td colSpan={colSpan} style={{ padding: '50px 0px' }}>
                    <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: "2rem", gap: "10px" }}>
                        <CloudOff sx={{ color: "skyblue", fontSize: '4rem' }} />
                        <span style={{ fontSize: '13px', color: 'skyblue', fontStyle: 'oblique' }}>Connexion impossible, réessayer plus tard</span>
                    </span>
                </td>
            </tr>
        </tbody>

    )
}

export default ConnexionError
