import React from 'react';
import './headerprint.css'
import { Box, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/logoEtpm.svg';

const HeaderPrint = ({ title }) => {
    return (
        <div className='header_Print' >
            <Box sx={{ display: 'flex', justifyContent: 'flexStart', alignItems: 'center', height: 50, gap: 15, padding: 2, backgroundColor: '#EEEEFF' }}>
                {/* <img src={require('../../assets/images/logoEtpm.svg')} alt="logo_etpm" width={100} height={60} style={{ padding: 10 }} /> */}
                {/* <FaxSharp sx={{ height: 44, width: 44, color: 'blue' }} /> */}
                <Logo height={50} width={100} />
                <Typography variant='span' sx={{ fontSize: 13, fontWeight: 'bold', color: '#333' }}>{title}</Typography>
            </Box>
        </div>
    )
}

export default HeaderPrint
