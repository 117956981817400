import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import API from "../../../App.constants";
import SyntheseGlobalPresence from "../../../Tableau/personnelPonctualite/SynytheseGlobalPresence";
import ChartRetardTwo from "../../chart/personnel/ChartRetardTwo";
import moment from "moment";
import {
  PonctualitePersonnelType,
  requestType,
} from "../../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import { API_PERSONNEL } from "../../../apiCaller/personnel.Caller";
import { accountService } from "../../../_services/accountServices";
import toast from "react-hot-toast";

const RapportMensuel = () => {
  const currentDay = moment().format("YYYY-MM-DD");
  const [SelectedDay, setSelectedDay] = useState(currentDay); // default date on display (ISOFormat)

  let listPersonnel: PonctualitePersonnelType[] = [];

  const handleDateChange = (e) => {
    setSelectedDay(e.target.value);
  };
  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const [allPonctualite, setallPonctualite] = React.useState<
    PonctualitePersonnelType[]
  >([]);

  const fetchAllPonctualite = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();
    if (!token) return navigate("/");

    API_PERSONNEL.PONCTUALITE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: [] = [];

        if (response.data === "") {
          setallPonctualite(empyData);
        } else {
          setallPonctualite(response.data.data);
        }

        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 403) {
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else {
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard" + err
          );
        }
      });
  };

  let listPresence: PonctualitePersonnelType[] = [];
  let listAbsence: PonctualitePersonnelType[] = [];
  let listRetard: PonctualitePersonnelType[] = [];
  let listAbsenceJustify: PonctualitePersonnelType[] = [];
  let listRetardJustify: PonctualitePersonnelType[] = [];
  let listJourneePeda: PonctualitePersonnelType[] = [];

  if (allPonctualite.length > 0) {
    for (let item of allPonctualite) {
      if (
        moment(item.ponctualite_enseignant_created_at).format("YYYY-MM-DD") ===
        SelectedDay
      ) {
        listPersonnel.push(item);
      }
    }
  }

  if (listPersonnel.length > 0) {
    for (let item of listPersonnel) {
      if (item.cote_enseignant_id === 1) {
        listPresence.push(item);
      }
      if (item.cote_enseignant_id === 2) {
        listRetard.push(item);
      }
      if (item.cote_enseignant_id === 3) {
        listAbsence.push(item);
      }
      if (item.cote_enseignant_id === 2 && !!item.justification) {
        listRetardJustify.push(item);
      }
      if (item.cote_enseignant_id === 3 && !!item.justification) {
        listAbsenceJustify.push(item);
      }
      if (item.cote_enseignant_id === 4) {
        listJourneePeda.push(item);
      }
    }
  }

  useEffect(() => {
    fetchAllPonctualite();
  }, []);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "5fr 2fr",
        gap: "10px",
        marginTop: "20px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "0px 0px 12px 12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: ".2rem",
            backgroundColor: "#fff",
            borderRadius: "0px 8px 8px 0px ",
          }}
        >
          <div className="inputDate">
            <input
              type="date"
              style={{ height: "35px" }}
              value={SelectedDay}
              onChange={handleDateChange}
            />
          </div>
        </div>

        <div className="tableList">
          <SyntheseGlobalPresence
            nbPresence={listPresence.length}
            nbAbsence={listAbsence.length}
            nbRetard={listRetard.length}
            nbAbsenceJustif={listAbsenceJustify.length}
            nbRetardJustif={listRetardJustify.length}
          />
        </div>
      </div>

      {/* -----------------LEFT SIDE/ IT SHOWS DETAIL INFORMATION OF CLASS AND SPECICIFIC STUDENT-------------- */}
      <div className="detailClasse">
        <div className="detail">
          <div className="detailTitle">
            <span className="optionTitle">Synthèse jounalière de présence</span>
            <hr />
          </div>

          <ChartRetardTwo
            nbPresence={listPresence.length}
            nbAbsence={listAbsence.length}
            nbRetard={listRetard.length}
            nbAbsenceJustif={listAbsenceJustify.length}
            nbRetardJustif={listRetardJustify.length}
          />
        </div>
      </div>
    </div>
  );
};

export default RapportMensuel;
