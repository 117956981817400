import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { ListAltOutlined, PrintRounded } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import ConnexionErrorDiv from "../nodata/ConnexionErrorDiv";
import HeaderPrint from "../headerPrint/HeaderPrint";
import { PaiementType } from "../../types/GlobalType";
import moment from "moment";
import UpdateMontant from "./UpdateMontant.tsx";

type Props = {
  openDialogHistorique: boolean;
  setOpenDialogHistorique: React.Dispatch<React.SetStateAction<boolean>>;
  id_card: number | string | undefined;
  data: PaiementType[];
};

const DialoguePaiementHistorique = (props: Props) => {
  const orderedData = _.orderBy(props.data, ["paiement_created_at"], ["desc"]);
  const [openEdit, setopenEdit] = useState<boolean>(false);
  const [singleData, setsingleData] = useState<PaiementType>();

  const printRef = useRef<HTMLInputElement | null>(null);

  const handleClickPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: ".",
  });

  return (
    <>
      {openEdit && <UpdateMontant setOpen={setopenEdit} data={singleData} />}
      <Dialog open={props.openDialogHistorique} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItem: "center",
            fontFamily: "poppinsRegular",
            gap: "10px",
          }}
        >
          <ListAltOutlined color="primary" />
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontWeight: "500",
              fontSize: 15,
              fontFamily: "poppinsRegular",
            }}
          >
            Historique
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText>
            <table
              style={{
                borderCollapse: "collapse",
                marginLeft: "20px",
                width: "95%",
                maxHeight: "100px",
                padding: "10px 40px",
              }}
              ref={printRef}
            >
              <thead style={{ backgroundColor: "#", height: "2rem" }}>
                <tr style={{ backgroundColor: "skyblue" }}>
                  <th colSpan={6}>
                    <HeaderPrint
                      title={"RELEVE DES PAIEMENTS DES FRAIS SCOLAIRES"}
                    />
                  </th>
                </tr>

                <tr
                  style={{ fontSize: 13, backgroundColor: "#7e7e7e5f" }}
                  className="print_trimester_unpaid"
                >
                  <th
                    style={{
                      width: "50px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    Libellé
                  </th>
                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    Montant
                  </th>
                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    Heure
                  </th>
                  <th
                    style={{
                      width: "100px",
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.data.length > 0 ? (
                  orderedData.map((paiements, index) => {
                    return (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "" : "print_row_color"}
                        style={{
                          fontSize: 13,
                          height: 35,
                          borderBottom: "1px solid #999",
                        }}
                      >
                        <td
                          style={{
                            width: "50px",
                            textAlign: "center",
                          }}
                        >{`0${index + 1}`}</td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {"Frais scolaire"}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {paiements.montant}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {moment(paiements.paiement_created_at).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          {moment(paiements.paiement_created_at).format(
                            "hh:mm:ss"
                          )}
                        </td>
                        <td style={{ width: "100px", textAlign: "center" }}>
                          <span
                            style={{ color: "#1647a0e5", cursor: "pointer" }}
                            onClick={() => {
                              setsingleData(paiements);
                              setopenEdit(true);
                              props.setOpenDialogHistorique(false);
                            }}
                          >
                            Edit
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <ConnexionErrorDiv errMsg={"Aucune donnée disponible"} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="text"
            startIcon={<PrintRounded color="primary" />}
            onClick={handleClickPrint}
          >
            {" "}
            Imprimer{" "}
          </Button>
          <Button
            variant="text"
            color="warning"
            onClick={() => props.setOpenDialogHistorique(false)}
          >
            Quitter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialoguePaiementHistorique;
