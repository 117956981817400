import React from 'react';
import './pagenotfoundOne.scss';
import Pnf from '../../assets/images/pnf.gif'
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const NotFoundPageOne = () => {
    const navigate = useNavigate()
    const navigation = () => {
        localStorage.clear()
        navigate('/')
    }
    return (
        <div className='pnf'>
            <div className='pagenotfound'>
                <img src={Pnf} alt="page not found" />
                <h4>Chik soft</h4>
                <h2>404! Cette page est introuvable</h2>
                <Button size='small' variant='outlined' sx={{ mt: 5 }} onClick={navigation}>
                    <Typography>Accueil</Typography>
                </Button>
            </div>
        </div>
    )
}

export default NotFoundPageOne