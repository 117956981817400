import React, { useContext, useState } from "react";
import "./login.scss";
import LOGO from "../../assets/images/chiksoft.png";
import LEFTIMG from "../../assets/images/leftimg.png";
import LOGO_BLUE from "../../assets/images/chiksoft_blue.png";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { requestType } from "../../types/GlobalType";
import user_api from "../../apiCaller/user.Caller";
import toast from "react-hot-toast";
import Loader from "../../component/loader/Loader.tsx";
import LoaderLogin from "../../component/loader/LoaderLogin.tsx";

const Login = () => {
  const [email, setEmail] = useState("");
  let [password, setPassword] = React.useState("");
  const [checked, setchecked] = useState(false);

  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const handleSignin = async () => {
    if (password === "") {
      setState({
        isLoading: false,
        errorMessage: "Veuiller complétez votre mot de passe",
      });
      return toast.error("Veuiller complétez votre mot de passe");
    } else if (email === "") {
      setState({
        isLoading: false,
        errorMessage: "Veuiller complétez votre adresse mail",
      });
      return toast.error("Veuiller complétez votre adresse mail");
    }

    setState({ isLoading: true, errorMessage: null, successMessage: null });

    user_api
      .login({ email, password })
      .then((response) => {
        console.log(response);
        if (response.status === 202 && response.data.token) {
          toast.success("Vous etes connecté", { duration: 4000 });
          setState({
            isLoading: false,
            errorMessage: null,
            successMessage: "Vous etes connecté",
          });

          dispatch({ type: "LOGIN", payload: response.data });
          navigate("/home", { replace: true });
        } else {
          setState({
            isLoading: false,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.data.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          return toast.error("Identifiant ou mot de passe incorrect");
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  return (
    <>
      <LoaderLogin isLoading={state.isLoading} />
      <div className="login">
        <div className="left">
          {/* {!!state.errorMessage === true && toast.error(state.errorMessage)} */}
          {/* {!!state.successMessage == true && toast.success(state.successMessage)} */}
          <img src={LEFTIMG} alt="" className="image" />
          <div className="logoContainer">
            <img src={LOGO} alt="logo" />
            <div className="appName">
              <span>CHIK</span>
              <span>Soft</span>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="topText">
            <img src={LOGO_BLUE} alt="Logo" />
            <h3>Se connecter</h3>
            <span>Bienvenu! Veuillez entrer vos identifiants</span>
          </div>
          <form>
            <div className="item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="item">
              <label htmlFor="password">Mot de passe</label>
              <input
                type={checked ? "text" : "password"}
                placeholder="*************"
                autoComplete=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="remember">
              <div className="left">
                <input
                  type="checkbox"
                  name=""
                  checked={checked}
                  onChange={(e) => setchecked(e.target.checked)}
                  className="checkbox"
                />
                <span>Afficher le mot de passe</span>
              </div>
              {/* <span className="right">Mot de passe oublié</span> */}
            </div>

            <input
              type="button"
              value="Connexion"
              className="connexion"
              onClick={handleSignin}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
