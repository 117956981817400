import React, { useState } from "react";
import "./ponctualite.scss";
import Delete from "../../../component/dialog/delete/Delete.tsx";
import UpdateCotation from "./UpdateCotation.tsx";
import UpdateMotif from "./UpdateMotif.tsx";

const Ponctualite = () => {
  const [openDeleteYear, setOpenDeleteYear] = React.useState<Boolean>(false);
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  const [openUpdateCotation, setopenUpdateCotation] = useState<boolean>(false);
  const [openUpdateMotif, setopenUpdateMotif] = useState<boolean>(false);

  const [role1, setRole1] = useState("");
  const [role2, setRole2] = useState("");

  const handleChange = (event) => {
    setRole2(event.target.value);
  };
  return (
    <>
      <div className="topPonctualite">
        <div className="card_Year">
          <h2 className="card-title">Cotation ponctualité</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Cotation"
              className="input-field"
              name="cotation"
            />
            <div className="role-selector">
              <select
                value={role2}
                onChange={handleChange}
                className="input-field"
                name="role2"
              >
                <option value="">--Selectionner un hôte--</option>
                <option value="teacher">Enseignant</option>
                <option value="student">Elève</option>
              </select>
            </div>
            <button className="add-button">{"Ajouter"}</button>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Cotation</th>
                  <th>hôte</th>
                  <th className="actionHeader">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Retad</td>
                  <td>Elève</td>
                  <td className="actionSection">
                    <span
                      className="edit"
                      onClick={() => setopenUpdateCotation(true)}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => setOpenDeleteYear(true)}
                    >
                      Sup
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card_Classe">
          <h2 className="card-title">Motif ponctualité</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Désignation"
              className="input-field"
              name="motif"
            />
            <div className="role-selector">
              <select
                value={role1}
                onChange={handleChange}
                className="input-field"
                name="role1"
              >
                <option value="">--Selectionner un hôte--</option>
                <option value="teacher">Enseignant</option>
                <option value="student">Elève</option>
              </select>
            </div>
            <button className="add-button">Ajouter</button>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Motif</th>
                  <th>hôte</th>
                  <th className="actionHeader">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Malade</td>
                  <td>Elève</td>
                  <td className="actionSection">
                    <span
                      className="edit"
                      onClick={() => setopenUpdateMotif(true)}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => setopenDeleteArchive(true)}
                    >
                      Archiver
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Malade</td>
                  <td>Enseignant</td>
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span className="archive">Archiver</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openUpdateCotation && <UpdateCotation setOpen={setopenUpdateCotation} />}

      {openUpdateMotif && <UpdateMotif setOpen={setopenUpdateMotif} />}

      {openDeleteYear && (
        <Delete
          setOpen={setOpenDeleteYear}
          itemId={"id"}
          label={"Voulez-vous vraiment supprimer cette cotation?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}

      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={"id"}
          label={"Voulez-vous vraiment achiver ce motif?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default Ponctualite;
