let date = new Date();
let day = date.getDate();
let hour = date.getHours();
let minute = date.getMinutes();
let second = date.getSeconds();
let month = date.getMonth() + 1;
let year = date.getFullYear();

let fullSecondes = (second < 10) ? `0${second}` : `${second}`;
let fullMinutes = (minute < 10) ? `0${minute}` : `${minute}`;
let fullHours = (hour < 10) ? `0${hour}` : `${hour}`;
let combineHours = `${fullHours}:${fullMinutes}:${fullSecondes}`;   //ex:12:24:35
let monthFilter = (month < 10) ? `0${month}` : `${month}`;

let fullday = (day < 10) ? `0${day}-${monthFilter}-${year}` : `${day}-${monthFilter}-${year}`;  //ex:14-09-2023

let combineHoursDate = (day < 10) ? `${fullHours}${fullMinutes}${fullSecondes}0${day}${monthFilter}${year}` : `${fullHours}${fullMinutes}${fullSecondes}${day}${monthFilter}${year}`;  //ex:14-09-2023

let monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"
];
let m = monthNames[month - 1]
let fullMouth = `${m} ${year}`;             //ex: Janvier 2023


const getFullDate = () => {
    return fullday
}

const getFullMonth = () => {
    return fullMouth
}

const getFullHour = () => {
    return combineHours
}
const getDateHourCombined = () => {
    console.log(combineHoursDate)
    console.log(combineHours)
    console.log(fullday)
    return combineHoursDate
}

const getFormatedFullDay = () => {
    let day = fullday.split("-")[0];
    let fullDate = `${day} ${fullMouth}`

    return fullDate
}

const timerServices = {
    getFullDate, getFullMonth, getFullHour, getDateHourCombined, getFormatedFullDay
}

export default timerServices;