import React, { useState } from "react";
import "./SingleCommunication.scss";

import { Close, CommentOutlined } from "@mui/icons-material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import imageAvatar from "../../../assets/images/studentpp.png";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
}
const SingleCommunication = (props: Props) => {
  const handleChange = (event) => {
    // setRole(event.target.value);
  };
  const navigate = useNavigate();

  const handleNavidate1 = () => {
    navigate("enregistrement-eleve");
  };
  const handleNavidate2 = () => {
    navigate("parent-info");
  };

  const location = useLocation();
  let currentRoute: string = location.pathname.split("/")[3];

  return (
    <div className="SingleCommunication">
      <div className="dialog">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <CommentOutlined className="ico" />
              <span>Communiqué</span>
            </div>
            <div className="right_">
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </div>
          </div>
        </div>

        <div className="body">
          <div className="items_com">
            <h5>Destinateur:</h5>
            <input type="text" placeholder="Parents" disabled />
          </div>

          <div className="items_com">
            <h5>Objet:</h5>
            <input type="text" placeholder="Objet du comminiqué" disabled />
          </div>
          <div className="items_com">
            <h5>Date:</h5>
            <input type="text" placeholder="22 fev 2023" disabled />
          </div>

          <div className="items_com_text">
            <h5>Communiqué:</h5>

            <textarea rows={8} placeholder="communiqué..." disabled />
          </div>
          <div className="btns">
            <button className="send" onClick={() => props.setOpen(false)}>
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCommunication;
