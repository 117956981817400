import React, { useState } from "react";
import "./personnel.scss";
import Delete from "../../../component/dialog/delete/Delete.tsx";
import UpdateDomaine from "./UpdateDomaine.tsx";
import UpdateGrade from "./UpdateGrade.tsx";
import UpdateFonction from "./UpdateFonction.tsx";

const Grade = () => {
  const [openDeleteYear, setOpenDeleteYear] = React.useState<Boolean>(false);
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);

  const [openDomaine, setopenDomaine] = useState<boolean>(false);
  const [openGrade, setopenGrade] = useState<boolean>(false);
  const [openFonction, setopenFonction] = useState<boolean>(false);
  return (
    <>
      <div className="topPersonnel">
        <div className="card_Year">
          <h2 className="card-title">Domaines d'études</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Domaine d'étude"
              className="input-field"
            />
            <button className="add-button">{"Ajouter"}</button>
          </div>
          <ul className="item-list">
            <li>
              <span>Sciences exactes</span>
              <div className="actionSection">
                <span className="edit" onClick={() => setopenDomaine(true)}>
                  Edit
                </span>
                <span
                  className="archive"
                  onClick={() => setOpenDeleteYear(true)}
                >
                  Sup
                </span>
              </div>
            </li>
            <li>Sciences exactes</li>
            <li>Polytechniques</li>
            <li>Informatiques</li>
            <li>Sciences Infirmières</li>
            <li>Sciences siciales</li>
            <li>Sciences litéraires</li>
            <li>Psycologies</li>
          </ul>
        </div>
        <div className="card_Year">
          <h2 className="card-title">Fonction des fonctionneles</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Fonction administratif"
              className="input-field"
            />
            <button className="add-button">{"Ajouter"}</button>
          </div>
          <ul className="item-list">
            <li>
              <span>Préfet des études</span>
              <div className="actionSection">
                <span className="edit" onClick={() => setopenFonction(true)}>
                  Edit
                </span>
                <span
                  className="archive"
                  onClick={() => setOpenDeleteYear(true)}
                >
                  Sup
                </span>
              </div>
            </li>
            <li>Directeur des études</li>
            <li>Préfet de disciplines</li>
            <li>Directeur Financiers</li>
            <li>Directeur Comptable</li>
            <li>Secretaire</li>
            <li>Enseignant</li>
            <li>Huissier</li>
          </ul>
        </div>
        <div className="card_Year">
          <h2 className="card-title">Grade des fonctionneles</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Grade fonctionnel"
              className="input-field"
            />
            <button className="add-button">{"Ajouter"}</button>
          </div>
          <ul className="item-list">
            <li>
              <span>Diplome d'Etat</span>
              <div className="actionSection">
                <span className="edit" onClick={() => setopenGrade(true)}>
                  Edit
                </span>
                <span
                  className="archive"
                  onClick={() => setOpenDeleteYear(true)}
                >
                  Sup
                </span>
              </div>
            </li>
            <li>Grade</li>
            <li>Licence</li>
            <li>Master</li>
            <li>Doctorat</li>
          </ul>
        </div>
      </div>

      {openDomaine && <UpdateDomaine setOpen={setopenDomaine} />}
      {openGrade && <UpdateGrade setOpen={setopenGrade} />}
      {openFonction && <UpdateFonction setOpen={setopenFonction} />}

      {openDeleteYear && (
        <Delete
          setOpen={setOpenDeleteYear}
          itemId={"id"}
          label={"Voulez-vous vraiment supprimer cette année?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={"id"}
          label={"Voulez-vous vraiment achiver cette classe?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default Grade;
