import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ChartRetardOne from "../../chart/personnel/ChartRetardOne";
import ListPersonnelCotation from "../../../Tableau/personnelPonctualite/ListPersonnelCotationJournalier.tsx";

import { Print } from "@mui/icons-material";
import {
  PonctualitePersonnelType,
  requestType,
} from "../../../types/GlobalType";
import { accountService } from "../../../_services/accountServices";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { API_PERSONNEL } from "../../../apiCaller/personnel.Caller";
import moment from "moment";

const RapportJournalier = () => {
  const printRef = useRef();

  const currentDay = moment().format("YYYY-MM-DD");
  const [SelectedDay, setSelectedDay] = useState(currentDay); // default date on display (ISOFormat)

  const [reducerValueOnUpdate, forceUpdateOnUpdate] = React.useReducer(
    (x) => x + 1,
    0
  );
  let listPersonnel: PonctualitePersonnelType[] = [];

  const [msg, setMsg] = useState({ type: "", text: "" });

  const handleDateChange = (e) => {
    let selectedDay = e.target.value;
    setSelectedDay(selectedDay);
  };

  // const handleChange = (e) => {
  //   setEleveSearch(e.target.value);
  // };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Liste de présence des personnels  - ETPM",
  });

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const [allPonctualite, setallPonctualite] = React.useState<
    PonctualitePersonnelType[]
  >([]);

  const fetchAllPonctualite = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();
    if (!token) return navigate("/");

    API_PERSONNEL.PONCTUALITE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: [] = [];

        if (response.data === "") {
          setallPonctualite(empyData);
        } else {
          setallPonctualite(response.data.data);
        }

        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 403) {
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else {
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard" + err
          );
        }
      });
  };

  let listPresence: PonctualitePersonnelType[] = [];
  let listAbsence: PonctualitePersonnelType[] = [];
  let listRetard: PonctualitePersonnelType[] = [];
  let listAbsenceJustify: PonctualitePersonnelType[] = [];
  let listRetardJustify: PonctualitePersonnelType[] = [];
  let listJourneePeda: PonctualitePersonnelType[] = [];

  if (allPonctualite.length > 0) {
    for (let item of allPonctualite) {
      if (
        moment(item.ponctualite_enseignant_created_at).format("YYYY-MM-DD") ===
        SelectedDay
      ) {
        listPersonnel.push(item);
      }
    }
  }

  if (listPersonnel.length > 0) {
    for (let item of listPersonnel) {
      if (item.ponctualite_enseignant_id === 1) {
        listPresence.push(item);
      }
      if (item.ponctualite_enseignant_id === 2) {
        listRetard.push(item);
      }
      if (item.ponctualite_enseignant_id === 3) {
        listAbsence.push(item);
      }
      if (item.ponctualite_enseignant_id === 2 && !!item.justification) {
        listRetardJustify.push(item);
      }
      if (item.ponctualite_enseignant_id === 3 && !!item.justification) {
        listAbsenceJustify.push(item);
      }
      if (item.ponctualite_enseignant_id === 4) {
        listJourneePeda.push(item);
      }
    }
  }

  useEffect(() => {
    fetchAllPonctualite();
  }, [reducerValueOnUpdate]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "10px",
        marginTop: "20px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "0px 0px 35px 35px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: ".2rem",
            backgroundColor: "#fff",
            borderRadius: "0px 8px 8px 0px ",
          }}
        >
          {/* <div className="paiementMsg">
            <div className="iconPaiement"></div>
          </div> */}
          <div className="inputDate">
            <span
              style={{
                fontSize: "16px",
                marginLeft: "30px",
                fontWeight: "500",
                color: "#888",
              }}
            >
              Date :
            </span>

            <input
              type="date"
              style={{ height: "35px", outline: "none" }}
              value={SelectedDay}
              onChange={handleDateChange}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={handlePrint}
          >
            <Print sx={{ color: "blue", fontSize: 18 }} onClick={handlePrint} />
            <span
              style={{
                fontFamily: "poppinsRegular",
                fontSize: "12px",
                textDecoration: "underline",
              }}
            >
              Print
            </span>
          </div>

          {/* <div className="input-class-retard">
            <input
              type="text"
              value={eleveSearch}
              onChange={handleChange}
              placeholder="Rechercher par nom"
              className="search-bar-retard"
            />
          </div> */}
        </div>

        <div className="tableList" style={{ textAlign: "center" }}>
          <ListPersonnelCotation
            listPersonnel={listPersonnel}
            // searchByClasse={selectedClasse}
            // eleveSearch={eleveSearch}
            formatDate={SelectedDay}
            printRef={printRef}
            msg={msg}
            forceUpdateOnUpdate={forceUpdateOnUpdate}
          />
        </div>
      </div>

      {/* -----------------LEFT SIDE/ IT SHOWS DETAIL INFORMATION OF CLASS AND SPECICIFIC STUDENT-------------- */}
      {/* <div className="detailClasse">
                <div className="detail">

                    <div className='detailTitle'>
                        <span className="optionTitle">Information Générale</span><hr />
                    </div>

                    <ChartRetardOne nbPresence={nbPresence} nbRetard={nbRetard} nbAbsence={nbAbsence} />

                </div>
            </div> */}
    </div>
  );
};

export default RapportJournalier;
