import React from "react";
import "./terminal.scss";
import { Add, CancelRounded, CheckCircle, Print } from "@mui/icons-material";
import SearchBar from "../../../component/searchBar/Search.tsx";
import { Tooltip } from "@mui/material";
import Delete from "../../../component/dialog/delete/Delete.tsx";
import NewTerminal from "./NewTerminal.tsx";
import UpdateTerminal from "./UpdateTerminal.tsx";

const Terminal = () => {
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  const [openAddTerminal, setopenAddTerminal] = React.useState<boolean>(false);
  const [openUpdateTerminal, setopenUpdateTerminal] =
    React.useState<boolean>(false);
  return (
    <>
      <div className="topTerminal">
        <div className="List_personnel">
          <div className="headerPers">
            <h2 className="card-title">Liste</h2>
            <div className="input-container">
              <SearchBar />
              <button className="add_button_pers">
                <Tooltip title="Imprimer">
                  <Print className="ico" />
                </Tooltip>
              </button>
              <button className="add_button_pers">
                <Tooltip title="Ajouter">
                  <Add
                    className="ico"
                    onClick={() => setopenAddTerminal(true)}
                  />
                </Tooltip>
              </button>
            </div>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Terminal</th>
                  <th className="headerSection">Status</th>
                  <th className="actionHeader">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Parents</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Activé" placement="left">
                        <CheckCircle
                          style={{ color: "#28a745", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>{" "}
                  <td className="actionSection">
                    <span
                      className="edit"
                      onClick={() => setopenUpdateTerminal(true)}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => setopenDeleteArchive(true)}
                    >
                      Archiver
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Enseignants</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Archivé" placement="left">
                        <CancelRounded
                          style={{ color: "#7f1d18", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>{" "}
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span className="archive">Archiver</span>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Enseignants-Parents</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Archivé" placement="left">
                        <CancelRounded
                          style={{ color: "#7f1d18", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>{" "}
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span className="archive">Archiver</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openAddTerminal && <NewTerminal setOpen={setopenAddTerminal} />}
      {openUpdateTerminal && <UpdateTerminal setOpen={setopenUpdateTerminal} />}
      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={"id"}
          label={"Voulez-vous vraiment achiver cet terminal?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default Terminal;
