import React, { useState } from "react";
import "./updateEleve.scss";

import { Close, Person2Sharp } from "@mui/icons-material";

import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import { IconButton } from "@mui/material";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
}
const UpdateEleve = (props: Props) => {
  const [role, setRole] = useState("");

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneNumberPattern =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  //phone number state
  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedCountry, setSelectedCountry] = useState<string>("CD");
  return (
    <div className="UpdateEleve">
      <div className="dialog_update">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <Person2Sharp className="ico" />
              <span>Modification</span>
            </div>
            <IconButton>
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </IconButton>
          </div>
        </div>

        <div className="body_update">
          <div className="item">
            <label>Nom *</label>
            <input
              placeholder="Nom..."
              type="text"
              name="nom_Nom"
              // value={data.nom_Nom}
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Post-nom *</label>
            <input
              placeholder="Post-nom..."
              type="text"
              name="nom_Post-nom"
              // value={data.nom_Post-nom}
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Prénom *</label>
            <input
              placeholder="Prénom..."
              type="text"
              name="nom_Prénom"
              // value={data.nom_Prénom}
              onChange={handleChange}
            />
          </div>

          <div className="item">
            <label>Genre</label>
            <select
              onChange={handleChange}
              name="annee_existence"
              // value={data.annee_existence}
            >
              <option value="hhhh">Masculin</option>
              <option value="hhhh">Feminin</option>
            </select>
          </div>

          <div className="item">
            <label>Date naissance *</label>
            <input
              placeholder="Ville..."
              type="date"
              name="ville"
              // value={data.ville}
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Classe</label>
            <select
              onChange={handleChange}
              name="annee_existence"
              // value={data.annee_existence}
            >
              <option value="hhhh">1er</option>
              <option value="hhhh">2em</option>
            </select>
          </div>

          <div className="item country">
            <label>Pays</label>
            <ReactFlagsSelect
              selected={selectedCountry}
              onSelect={(code) => setSelectedCountry(code)}
              optionsSize={13}
              selectedSize={13}
              searchPlaceholder="Chercher pays"
              placeholder="Choix du pays"
              searchable
              className="menu-flags"
              selectButtonClassName="menu-flags-button"
            />
          </div>

          <div className="item">
            <label>Ville *</label>
            <input
              placeholder="Ville..."
              type="text"
              name="ville"
              // value={data.ville}
              onChange={handleChange}
            />
          </div>

          <div className="item">
            <label>Commune *</label>
            <input
              placeholder="Commune..."
              type="text"
              name="commune"
              // value={data.commune}
              onChange={handleChange}
            />
          </div>

          <div className="item">
            <label>Quartier *</label>
            <input
              placeholder="Quartier..."
              type="text"
              name="quartier"
              // value={data.quartier}
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Nom du tuteur*</label>
            <input
              placeholder="Nom..."
              type="text"
              name="nom_Nom"
              // value={data.nom_Nom}
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Post-nom du tuteur *</label>
            <input
              placeholder="Post-nom..."
              type="text"
              name="nom_Post-nom"
              // value={data.nom_Post-nom}
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Prénom du tuteur*</label>
            <input
              placeholder="Prénom..."
              type="text"
              name="nom_Prénom"
              // value={data.nom_Prénom}
              onChange={handleChange}
            />
          </div>

          <div className="item">
            <label>Genre</label>
            <select
              onChange={handleChange}
              name="annee_existence"
              // value={data.annee_existence}
            >
              <option value="hhhh">Masculin</option>
              <option value="hhhh">Feminin</option>
            </select>
          </div>
          <div className="item">
            <label>Téléphone*</label>
            <input
              placeholder="Prénom..."
              type="text"
              name="nom_Prénom"
              // value={data.nom_Prénom}
              onChange={handleChange}
            />
          </div>

          {/* <div className="item phone">
            <label>Téléphone *</label>
            <PhoneInput
              country={"cd"}
              value={phoneNumber}
              onChange={handleChangePhone}
            />
            {!validdNumber && (
              <span style={{ color: "red", fontSize: "12px" }}>
                Entrer un numéro valide
              </span>
            )}
          </div> */}
        </div>
        <div className="btn">
          <button onClick={() => props.setOpen(false)}>Annuler</button>
          <button>Mettre à jour</button>
        </div>
      </div>
    </div>
  );
};

export default UpdateEleve;
