import React from "react";
import "./paiement.scss";
import { BookOnline } from "@mui/icons-material";
import { Fab } from "@mui/material";

const Header = ({ montant, onClickHistorique, totalEleve }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "10px",
        marginTop: "8px",
      }}
    >
      <div className="dashbordItem">
        <div className="dashbordItemUp">
          <div className="dashDesc">
            <div className="descTitle1">Total Paiement</div>
            <div className="descTitle2">Elèves reçus ce mois</div>
          </div>
          <div className="dashIcon">
            <div
              color=""
              aria-label="add"
              style={{
                width: "80px",
                height: "50px",
                backgroundColor: "skyblue",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                {totalEleve}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="dashbordItem">
        <div className="dashbordItemUp">
          <div className="dashDesc">
            <div className="descTitle1">Total montant</div>
            <div className="descTitle2">Somme totale perçue ce mois</div>
          </div>
          <div className="dashIcon">
            <div
              color="success"
              aria-label="add"
              style={{
                width: "80px",
                height: "50px",
                backgroundColor: "skyblue",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                {montant} $
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="dashbordItem">
        <div className="dashbordItemUp">
          <div className="dashDesc">
            <div className="descTitle1">Historique de paiement</div>
            <div className="descTitle2">Catalogue des paiements</div>
          </div>
          <div className="dashIcon">
            <Fab color="primary" aria-label="add">
              <BookOnline />
            </Fab>
          </div>
        </div>

        <div className="dashbordItemDown" onClick={onClickHistorique}>
          Afficher l'historique{" "}
        </div>
      </div>
    </div>
  );
};

export default Header;
