import React, { useState, useReducer, useEffect } from 'react';
import { ArrowBackRounded, ContentCopyOutlined } from '@mui/icons-material';
import { Stack, Box, Fab, Divider, Button, LinearProgress, TextField, MenuItem, Typography, Skeleton, Dialog, DialogContent, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API from '../App.constants';
import { INITIAL_STATE, ressourceReducer } from '../_reducers/ressourceReducer';
import timerServices from '../_services/timerServices';
// import SimpleBackdrop from '../component/loader/CircularBackdrop';
import SnackbarNotification from '../component/dialog/confirmation/SnackbarNotification';
import ConnexionErrorDiv from '../component/nodata/ConnexionErrorDiv';
import { accountService } from '../_services/accountServices';

const Ressource = () => {

    const [file, setFile] = useState();

    const classes = accountService.getClasseList();

    const [allData, setallData] = useState([])
    const [nonPublished, setNonpublished] = useState(0)
    const [progress, setProgess] = useState({ start: false, pc: 0 })
    const [msg, setMsg] = useState({ type: "", text: "" });
    const [isLoading, setisLoading] = useState(false);
    const [isLoading2, setisLoading2] = useState(true);
    const [reload, setReload] = useState(false);
    const [inputError, setinputError] = useState({ title: false, type: false, class: false });

    const [state, dispatch] = useReducer(ressourceReducer, INITIAL_STATE);

    const handleChange = (e) => {
        dispatch({
            type: 'CHANGE_INPUT',
            payload: { name: e.target.name, value: e.target.value }
        })
    }

    const saveRessource = (__status) => {

        setinputError({ title: false, type: false, class: false });
        setMsg({ type: "", text: "" })

        if (!!state.titre === false) {
            setinputError(prevState => ({ ...prevState, title: true }));
            return setMsg(prevState => ({ ...prevState, type: "EMPTY_FIELD", text: "Veuillez compléter le titre de la ressource" }))
        }
        if (!!state.type === false) {
            setinputError(prevState => ({ ...prevState, type: true }));
            return setMsg(prevState => ({ ...prevState, type: "EMPTY_FIELD", text: "Veuillez compléter le type de la ressource" }))
        }
        if (!!state.classe === false) {
            setinputError(prevState => ({ ...prevState, class: true }));
            return setMsg(prevState => ({ ...prevState, type: "EMPTY_FIELD", text: "Veuillez compléter la classe" }))
        }


        if (!!(state.titre || state.type || state.classe) === false) {
            setinputError(true)
            return setMsg(prevState => ({ ...prevState, type: "EMPTY_FIELD", text: "Veuillez compléter tous les champs" }))

        }
        setisLoading(true)

        const { titre, type, classe, contenu } = state;

        const dateCreation = timerServices.getFormatedFullDay()

        if (file !== undefined) {
            setMsg(prevState => ({ ...prevState, text: "Loading..." }))
            setProgess(prevState => {
                return { ...prevState, start: true }
            })

            const filename = new FormData();

            if (__status === 1) {
                filename.append('file', file);
                filename.append('titre', titre);
                filename.append('type', type);
                filename.append('classe', classe);
                filename.append('contenu', contenu);
                filename.append('dateCreation', dateCreation);
                filename.append('datePublication', dateCreation);
                filename.append('status', 1);
            } else if (__status === 0) {
                filename.append('file', file);
                filename.append('titre', titre);
                filename.append('type', type);
                filename.append('classe', classe);
                filename.append('contenu', contenu);
                filename.append('dateCreation', dateCreation);
                filename.append('status', 0);
            }

            const config = {
                "Content-Type": "multipart/from-data"
            }

            axios.post(`${API}/api/ressource/create`, filename, {
                onUploadProgress: (progressEvent) => {
                    setProgess(prevState => {
                        return { ...prevState, pc: progressEvent.progress * 100 }
                    })
                },
                headers: config
            })
                .then(res => {
                    console.log(res.data);
                    setisLoading(false)
                    setReload(prevState => (!prevState))

                    if (res.data.message === 'Success') {
                        setMsg(prevState => ({ ...prevState, type: "success", text: "Ressource enregistrée" }))

                        setTimeout(() => {
                            setProgess({ start: false, pc: 0 });
                            setMsg({ type: "", text: "" })
                        }, 3000)
                    }

                    dispatch({ type: "INIT_INPUT" })

                })
                .catch(err => {
                    console.log("CATCH")
                    console.log(err)
                    setisLoading(false)

                    if (err.code === "ERR_NETWORK") {
                        setMsg(prevState => ({ ...prevState, type: "ERR_NETWORK", text: "Vérifier votre connection Internet" }))

                        setTimeout(() => {
                        }, 3000);
                        setMsg({ type: "", text: "" });
                        return;
                    } else {
                        setMsg(prevState => ({ ...prevState, type: "ERR_UNKNOWN", text: "Une erreur inconnue est survenue, réessayer plus tard" }))

                        setTimeout(() => {
                            setMsg({ type: "", text: "" });
                            return;
                        }, 3000);
                    }
                })

        } else if (file === undefined) {

            var fileData = {}
            if (__status === 1) {
                fileData = { titre, type, classe, contenu, status: 1, dateCreation, datePublication: dateCreation }
            } else if (__status === 0) {
                fileData = { titre, type, classe, contenu, status: 0, dateCreation, datePublication: null }
            }

            const config = {
                "Content-Type": "application/json"
            }
            axios.post(`${API}/api/ressource/createwithoutfile`, fileData, config)

                .then(res => {

                    console.log(res.data);
                    setisLoading(false)
                    setReload(prevState => (!prevState))

                    if (res.data.message === 'Success') {
                        setMsg(prevState => ({ ...prevState, type: "success", text: "Ressource enregistrée avec succes" }))

                        setTimeout(() => {
                            setProgess({ start: false, pc: 0 });
                            setMsg({ type: "", text: "" })
                        }, 3000)
                    }

                    dispatch({ type: "INIT_INPUT" })
                })
                .catch(err => {
                    console.log("CATCH")
                    console.log(err)
                    setisLoading(false)

                    if (err.code === "ERR_NETWORK") {
                        setMsg(prevState => ({ ...prevState, type: "ERR_NETWORK", text: "Vérifier votre connection Internet" }))
                        setTimeout(() => {
                            setMsg({ type: "", text: "" });
                        }, 3000);
                        return;
                    } else {
                        setTimeout(() => {
                            setMsg(prevState => ({ ...prevState, type: "ERR_UNKNOWN", text: "Une erreur inconnue est survenue, réesayer plus tard" }))
                        }, 3000);
                        setMsg({ type: "", text: "" });
                        return;
                    }
                })
        }
    }

    useEffect(() => {
        setisLoading2(true)
        let isCanceled = false;
        const config = {
            "Content-Type": "application/json"
        }

        axios.all([
            axios.get(`${API}/api/ressource/getall`, config),
            axios.get(`${API}/api/ressource/getallunpublished`, config)
        ])
            .then(axios.spread((allDataResponse, unpublisheResponse) => {
                if (!isCanceled) {
                    setisLoading2(false)
                    setallData(allDataResponse.data.data);
                    setNonpublished(unpublisheResponse.data.length)
                }
            }))
            .catch(err => {
                console.log(err)
                setisLoading2(false)
                if (err.code === "ERR_NETWORK") {
                    setMsg(prevState => ({ ...prevState, type: "ERR_NETWORK_FETCH", text: "Connexion impossible, réessayer plus tard" }))
                } else {
                    setMsg(prevState => ({ ...prevState, type: "ERR_UNKNOWN_FETCH", text: "Une erreur inconnue, réesayer plus tard" }))

                    return;
                }
            })


        return () => {
            isCanceled = true;
        }

    }, [reload]);


    const recentRessource = (
        allData.slice(-5).reverse().map((value, index) => {
            const { titre, classe, date_creation } = value;

            return (
                <div className="ressourceItem" key={index}>

                    <div className="dot"></div>

                    <div className="ressourceContent">
                        <div className="res_top">
                            <div className="ressourceName">{(titre.length < 20) ? (titre) : `${titre.slice(0, 20)}...`}</div>
                            <div className="res_class">
                                <span className="class_item">classe: </span>
                                <span className="calss_level">{classe}</span>
                            </div>
                        </div>
                        <div className="ressoureSubcontent">
                            <div className="res_status">Ressources</div>
                            <div className="ressourceName">{date_creation}</div>

                        </div>
                    </div>

                </div>
            )
        })
    )

    const skeleton = (
        <div className="ressourceItem">

            <div className="dot"></div>

            <div className="ressourceContent">
                <div className="res_top">
                    <div><Skeleton variant="rounded" width={150} height={20} sx={{ ml: '10px' }} /></div>
                    <div className="res_class">
                        <span className="class_item"><Skeleton variant="rounded" width={30} height={10} sx={{ ml: '10px', mt: "5px" }} /></span>
                        <span className="calss_level"><Skeleton variant="rounded" width={30} height={10} sx={{ ml: '10px', mt: "5px" }} /></span>
                    </div>
                </div>
                <div className="ressoureSubcontent">
                    <div className="res_status"><Skeleton variant="rounded" width={50} height={20} sx={{ ml: '10px', }} /></div>
                    <div className="res_status"><Skeleton variant="rounded" width={50} height={10} sx={{ ml: '10px', }} /></div>
                </div>
            </div>

        </div>
    )

    return (

        <Box bgcolor="#eee" flex={5} p={1} sx={{ marginLeft: "200px" }}>

            {/* {allData && <SimpleBackdrop />} */}

            {/* ------------------PAGE TITLE-------------------- */}
            <Stack direction="row" justifyContent="space-between" >
                <div className='homeMenu'>
                    <Link to='/home' style={{ marginTop: '5px' }}>
                        <ArrowBackRounded sx={{ color: "#0091EA", backgroundColor: "#fff", borderRadius: "50%" }} />
                    </Link>
                    <div className='homeTitleItem'>
                        <span className='homeTitle'>Ressources</span>
                    </div>
                    <div className='homeTitleItem2'>
                        <ContentCopyOutlined sx={{ color: "#1565C0" }} />
                        <span className='homeTitleSmall' >Supports de cours</span>
                    </div>
                </div>

            </Stack>
            <Divider sx={{ marginTop: "8px" }} />
            <div className="dashboarContainer">
                <div className="dashbordItem">
                    <div className="dashbordItemUp">
                        <div className='dashDesc'>
                            <div className="descTitle1">Ressources</div>
                            <div className="descTitle2">Nombre des ressources</div>
                        </div>
                        <div className='dashIcon'><Fab color="default" aria-label="add">
                            <span style={{ fontSize: "1rem", fontWeight: "600", color: "#1565C0" }}>{allData.length}</span>
                        </Fab></div>
                    </div>
                    <Link to="/home/all-ressources">
                        <div className="dashbordItemDown">Ouvrir</div>
                    </Link>
                </div>

                <div className="dashbordItem">
                    <div className="dashbordItemUp">
                        <div className='dashDesc'>
                            <div className="descTitle1">Partagée</div>
                            <div className="descTitle2">Nombre total</div>
                        </div>
                        <div className='dashIcon'>
                            <Fab color="success" aria-label="add">
                                <span style={{ fontSize: "1rem", fontWeight: "600" }}>{allData.length - nonPublished}</span>
                            </Fab></div>
                    </div>
                    <Link to="/home/published-ressources" state={""}>
                        <div className="dashbordItemDown" >Ouvrir</div>
                    </Link>
                </div>

                <div className="dashbordItem">
                    <div className="dashbordItemUp">
                        <div className='dashDesc'>
                            <div className="descTitle1">Non Partagée</div>
                            <div className="descTitle2">Nombre total</div>
                        </div>
                        <div className='dashIcon'>
                            <Fab color="error" aria-label="add">
                                <span style={{ fontSize: "1rem", fontWeight: "600" }}>{nonPublished}</span>
                            </Fab></div>
                    </div>
                    <Link to="/home/no-puplished-ressources">
                        <div className="dashbordItemDown" >Ouvrir</div>
                    </Link>
                </div>
            </div>

            {/* loader */}

            {isLoading && (
                <Dialog open={isLoading}>
                    <DialogContent sx={{ width: "200px", height: "130px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress sx={{ color: "skyblue" }} />
                    </DialogContent>
                </Dialog>
            )}

            <div className="ressource">
                <div className="ressourceAdd">
                    <div className='ressource_header'>
                        <h6 >Nouvelle ressource</h6>
                        {msg.type === "EMPTY_FIELD" && <Typography color={"error"} sx={{ fontSize: 13 }}>{msg.text}</Typography>}
                    </div>
                    <div className="form">
                        <TextField
                            label='Titre de la ressource'
                            fullWidth
                            size='small'
                            name='titre'
                            onChange={handleChange}
                            value={state.titre}
                            error={inputError.title}
                        />

                        {/* <input type="text" className='title' name='titre' placeholder='Titre de la ressource' value={state.titre} onChange={handleChange} /> */}
                        <div className="level_2">
                            <TextField
                                select
                                label='Type'
                                fullWidth
                                size='small'
                                name='type'
                                onChange={handleChange}
                                value={state.type}
                                error={inputError.type}
                            >
                                <MenuItem value='Exercice' sx={{ p: "4px 10px", fontSize: "14px" }}>Exercice</MenuItem>
                                <MenuItem value='Exercice dirigé' sx={{ p: "4px 10px", fontSize: "14px" }}>Exercice dirigé</MenuItem>
                                <MenuItem value='Devoir à domicile' sx={{ p: "4px 10px", fontSize: "14px" }}>Devoir à domicile</MenuItem>
                                <MenuItem value='Examen' sx={{ p: "4px 10px", fontSize: "14px" }}>Examen</MenuItem>
                                <MenuItem value='Devoir dirigé' sx={{ p: "4px 10px", fontSize: "14px" }}>Devoir dirigé</MenuItem>
                                <MenuItem value='Support du cours' sx={{ p: "4px 10px", fontSize: "14px" }}>Support du cours</MenuItem>
                                <MenuItem value='Aide mémoire' sx={{ p: "4px 10px", fontSize: "14px" }}>Aide mémoire</MenuItem>
                            </TextField>

                            <TextField
                                select
                                label='Classe'
                                // fullWidth
                                sx={{ width: '300px' }}
                                size='small'
                                name='classe'
                                onChange={handleChange}
                                value={state.classe}
                                error={inputError.class}
                            >

                                {classes.map((val, index) => {
                                    return (
                                        <MenuItem key={index} value={val.designation} sx={{ p: "4px 10px", fontSize: "14px" }}>{val.designation}</MenuItem>
                                    )
                                })}

                                {/* <MenuItem value='2ERE B' sx={{ p: "4px 10px", fontSize: "14px" }}>2ERE B</MenuItem>
                                <MenuItem value='3ERE A' sx={{ p: "4px 10px", fontSize: "14px" }}>3ERE A</MenuItem>
                                <MenuItem value='4ERE A' sx={{ p: "4px 10px", fontSize: "14px" }}>4ERE A</MenuItem> */}

                            </TextField>
                        </div>

                        <textarea type="text" className='content' name='contenu' placeholder='Contenu...' value={state.contenu} onChange={handleChange} />

                        <div className="ressource_btn">
                            <Button variant='contained' onClick={() => saveRessource(0)}>Ajouter</Button>
                            <Button variant='outlined' onClick={() => saveRessource(1)}>Publier</Button>
                            <div>
                                <label htmlFor="type_file">
                                </label>
                                <input type="file" id='type_file' onChange={(e) => setFile(e.target.files[0])} />
                            </div>

                            {progress.start && <LinearProgress variant='determinate' color='primary' sx={{ height: 7, borderRadius: 2, width: "160px" }} value={progress.pc} />}
                            {msg.type === "success" && <span style={{ fontSize: 12 }}>{msg.text}</span>}
                        </div>
                    </div>

                </div>
                <div className="ressourceRecent">
                    <div className="ressTitle">
                        <h6 className='recent__title'>RECENTS</h6>
                        <Link to="/home/all-ressources">
                            <h6 className='res_all'>Voir plus</h6>
                        </Link>

                    </div>

                    {isLoading2 ? (Array(6).fill(skeleton)) :
                        (msg.type === "ERR_NETWORK_FETCH" ? <ConnexionErrorDiv errMsg={msg.text} /> : (recentRessource))
                    }


                </div>
            </div>

            {(msg.type === "success") && <SnackbarNotification text={msg.text} severty={"success"} />}
            {(msg.type === "ERR_NETWORK") && <SnackbarNotification text={msg.text} severty={"error"} />}
            {(msg.type === "ERR_UNKNOWN") && <SnackbarNotification text={msg.text} severty={"warning"} />}

        </Box >
    )
}

export default Ressource
