import React, { useState } from "react";
import "./updateMontant.scss";

import { Close, SchoolOutlined } from "@mui/icons-material";

import "react-phone-input-2/lib/style.css";
import { CircularProgress, IconButton } from "@mui/material";
import { PaiementType, requestType } from "../../types/GlobalType";
import Loader from "../loader/Loader.tsx";
import { accountService } from "../../_services/accountServices";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API_PAIEMENT } from "../../apiCaller/paiement.Caller";
import ErrorBar from "../errorComp/ErrorBar.tsx";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: PaiementType | undefined;
}
const UpdateMontant = (props: Props) => {
  const [data, setdata] = useState({
    montant: props.data?.montant,
  });

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const navigate = useNavigate();

  const handleUpdate = () => {
    // setState({ isLoading: true, errorMessage: null, successMessage: null });

    if (!data?.montant) {
      setState({
        isLoading: false,
        errorMessage: "Veuiller complétez le montant",
      });

      return setTimeout(() => {
        setState((prev) => ({ ...prev, errorMessage: null }));
      }, 4000);
    }

    // Check if the montant is valid
    function isPositiveNumber(input) {
      const positiveNumberPattern = /^(0|[1-9]\d*)(\.\d+)?$/;
      return positiveNumberPattern.test(input);
    }

    if (!isPositiveNumber(data.montant)) {
      setState({
        isLoading: false,
        errorMessage: "Veuillez enter un montant entier et positif",
      });

      return setTimeout(() => {
        setState((prev) => ({ ...prev, errorMessage: null }));
      }, 4000);
    }

    setState({ isLoading: true, errorMessage: null, successMessage: null });
    let token = accountService.getToken();
    API_PAIEMENT.PAIEMENT.update(
      props.data?.paiement_id,
      { montant: data.montant },
      token
    )
      .then((response) => {
        console.log(response);
        setState({
          isLoading: false,
        });
        props.setOpen(false);
        toast.success("Mise à jour effectuée avec succes", { duration: 3000 });
        navigate("/home/paiement");
      })
      .catch((err) => {
        console.log(err);
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "CONNEXION IMPOSSIBLE, Réessayer plus tard",
          });
          return setTimeout(() => {
            setState((prev) => ({ ...prev, errorMessage: null }));
          }, 4000);
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });

          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return setTimeout(() => {
            setState((prev) => ({ ...prev, errorMessage: null }));
          }, 4000);
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return setTimeout(() => {
            setState((prev) => ({ ...prev, errorMessage: null }));
          }, 4000);
        }
      });
  };
  return (
    <>
      {/* <Loader isLoading={state.isLoading} /> */}
      <div className="updateMontant">
        <div className="dialog_update">
          <div className="headerUpdate">
            <div className="titleUpdate">
              <div className="left_">
                <SchoolOutlined className="ico" />
                <span>Modification - Paiement</span>
              </div>
              <IconButton>
                <Close className="ico" onClick={() => props.setOpen(false)} />
              </IconButton>
            </div>
          </div>

          <div className="body_update">
            {state.errorMessage && <ErrorBar title={state.errorMessage} />}
            <div className="item">
              <label>Montant</label>
              <input
                placeholder="Montant"
                type="number"
                onChange={(e) =>
                  setdata((prev) => ({
                    ...prev,
                    montant: e.target.valueAsNumber,
                  }))
                }
                value={data.montant}
              />
            </div>
          </div>
          <div className="btn">
            <button onClick={() => props.setOpen(false)}>Annuler</button>
            {!state.isLoading && (
              <button onClick={handleUpdate}>Modifier</button>
            )}
            {state.isLoading && (
              <button>
                <CircularProgress disableShrink size={15} />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMontant;
