import { CloudOff } from '@mui/icons-material'
import React from 'react'

const NoData = () => {
    return (
        <div style={{ padding: '30px 30px' }}>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: "2rem" }}>
                <CloudOff sx={{ color: "#3c72d5ed", fontSize: '4rem' }} />
                <span style={{ fontSize: '13px', color: '#3c72d5ed' }}>Aucune donnée disponible</span>
            </span>
        </div>
    )
}

export default NoData;
