import React from 'react'

import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button, Stack, Alert, AlertTitle
} from '@mui/material'

const ConfirmDialog = ({ openDialog, setOpenDialog, titleDialog, text1Dialog, text2Dialog, cancelBtn, actionBtn, setActionBtn }) => {

    return (
        <Dialog open={openDialog}>

            <DialogTitle sx={{ fontSize: 15 }}> {titleDialog} </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Stack component={'div'}>
                        <Alert severity="info">
                            <AlertTitle><strong>{""}</strong></AlertTitle>
                            {text1Dialog}<strong> {text2Dialog}</strong>
                        </Alert>
                    </Stack>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='warning' size='small' onClick={
                    () => { setOpenDialog(false) }
                }>{cancelBtn}</Button>

                <Button sx={{ background: "#eee", padding: "5px 20px", fontWeight: "600", marginRight: "15px" }} variant='text' color='success' size='small' onClick={() => setActionBtn()}>{actionBtn}</Button>

            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog