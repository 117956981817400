import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import "./presenceDetail.scss";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Divider,
  Stack,
  Box,
  Alert,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowDropUp,
  ChromeReaderModeRounded,
  Close,
  PrintRounded,
} from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import API from "../../../App.constants.js";
// import TableauRetardDetailPlus from '../../../Tableau/TableauRetardDetailPlus'
import TableauRetardDetailPlus from "../../../Tableau/personnelPonctualite/TableauRetardDetailPlus.js";
import avatarPersonnel from "../../../assets/images/avatar2.png";
import NotificationDialog from "../../../assets/constant/NotificationDialog.tsx";
import { useReactToPrint } from "react-to-print";
import {
  PonctualitePersonnelType,
  PonctualiteType,
} from "../../../types/GlobalType.ts";
import { accountService } from "../../../_services/accountServices.js";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { API_PERSONNEL } from "../../../apiCaller/personnel.Caller.js";
import MyLoading from "../../loader/MyLoading.tsx";
import moment from "moment";
// import { useNavigate } from 'react-router-dom'

const PresenceDetail = ({
  isOpen,
  setIsOpen,
  personnelData,
  formatDate,
  forceUpdateOnUpdate,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  personnelData: PonctualitePersonnelType | undefined;
  formatDate: string;
  forceUpdateOnUpdate: () => void;
}) => {
  // ---------------------------------
  //     TABLEAU DETAIL PLUS PERSONNEL
  // ---------------------------------

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [dataDetailPlus, setDataDetailPlus] = useState([]);

  //handle motif section
  const [motifInput, setMotifInput] = useState(false);
  const [motifText, setMotifText] = useState(false);

  //input state
  const [textInput, setTextInput] = useState("");

  //handle error states
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  //succes dialog
  const [openSuccess, setOpenSuccess] = useState(false);
  const [succesMsg, setSuccesMsg] = useState("");
  const [textStyle, setTextStyle] = useState("");

  const [imgLoaded, setImgLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let cote_designation: string = "";
  if (personnelData?.cote_enseignant_id === 1) {
    cote_designation = "Présence";
  } else if (
    personnelData?.cote_enseignant_id === 2 &&
    personnelData.status_justif
  ) {
    cote_designation = "Retard justifié";
  } else if (
    personnelData?.cote_enseignant_id === 2 &&
    !personnelData.status_justif
  ) {
    cote_designation = "Retard non justifié";
  } else if (
    personnelData?.cote_enseignant_id === 3 &&
    personnelData.status_justif
  ) {
    cote_designation = "Absence justifiée";
  } else if (
    personnelData?.cote_enseignant_id === 3 &&
    !personnelData.status_justif
  ) {
    cote_designation = "Absence non justifiée";
  }

  // const hanlePlusDetail = () => {
  //   setOpen(true);
  //   setOpen2(false);
  //   setErrorMsg("");
  //   axios
  //     .get(`${API}/api/ponctualite-personnel/${personnelData.eleve_id}`)
  //     .then((response) => {
  //       setDataDetailPlus(response.data.data);
  //     })
  //     .catch((error) => {
  //       if (error.code === "ERR_NETWORK") {
  //         console.log(error.code);
  //         setOpen2(true);
  //         return setErrorMsg(
  //           "Echec de connexion, vérifier votre connexion internet"
  //         );
  //       }
  //       setOpen2(true);
  //       setErrorMsg("Une erreur inconnue est survenue, réessayer plus tard");
  //     });
  // };

  const StyledIconTitle = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  });

  const printRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const handleClickPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Catalogue des présences",
  });

  const handleValidate = () => {
    setError(false);
    setErrorMsg("");
    setMotifInput(false);

    if (textInput === "") {
      setError(true);
      return setErrorMsg("Veuiller compléter le motif de justification");
    }

    setIsLoading(true);

    let token = accountService.getToken();
    if (!token) return navigate("/");

    API_PERSONNEL.PONCTUALITE.update(
      personnelData?.ponctualite_enseignant_id,
      { justification: textInput },
      token
    )
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setError(false);
        setIsOpen(false);
        setTextInput("");

        //---------handle succed dialog-----------
        // setOpenSuccess(true);
        // setSuccesMsg(response.data.message);
        // setTextStyle("success");
        toast.success("Justification validée avec succès", { duration: 4000 });
        forceUpdateOnUpdate();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);

        if (err.code === "ERR_NETWORK") {
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 403 || err.response.status === 401) {
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else {
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard" + err
          );
        }
      });
  };

  const handleJustify = () => {
    setMotifInput((curr) => !curr);
    if (error) return setError(false);
  };

  const handleShowMotif = () => {
    setMotifText((curr) => !curr);
    if (error) return setError(false);
  };

  {
    /* Session de motif & status_justif */
  }

  const addJustif = (
    <div className="motifInput">
      <input
        type="text"
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        className="input"
        placeholder="Justification..."
      />
      <div className="button" onClick={handleValidate}>
        <span>valider motif</span>
      </div>
    </div>
  );

  const displayJustif = (
    <div className="motifShow">
      <span className="motifText">
        Justification: {personnelData?.justification}{" "}
      </span>
    </div>
  );
  const displayErrorMsg = (
    <Alert severity="error" sx={{ marginY: "5px" }}>
      <strong>{errorMsg}</strong>
    </Alert>
  );

  // useEffect(() => {
  //   const personnelImage = new Image();
  //   personnelImage.onload = () => {
  //     setImgLoaded(true);
  //   };
  //   personnelImage.src = personnelData.photo;
  // }, [personnelData.photo]);

  return (
    <>
      {/* loader */}
      <MyLoading isLoading={isLoading} />

      <Dialog
        maxWidth="md"
        sx={{ height: "550px", top: ".5rem" }}
        open={isOpen}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItem: "center",
            padding: "10px",
            gap: "150px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <ChromeReaderModeRounded color="primary" />
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontWeight: "200", fontSize: "15px" }}
            >
              Ponctualité
            </Typography>
          </Stack>

          <Typography
            variant="h6"
            color="primary"
            sx={{ fontWeight: "200", fontSize: "15px", marginTop: "5px" }}
          >
            Date: {moment(formatDate).format("DD-MM-YYYY")}
          </Typography>
          <div className="inputDate" style={{ marginTop: "5px" }}>
            <span
              style={{ fontSize: "14px", color: "#65ADFE", cursor: "pointer" }}
              onClick={() => {
                setIsOpen(false);
                setTextInput("");
                setError(false);
                setMotifInput(false);
                setMotifText(false);
              }}
            >
              <Close />
            </span>
          </div>
        </Box>
        <Divider />
        <DialogContent>
          <DialogContentText sx={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* {!imgLoaded && (<img src={elevePhoto} style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '50%', border: '7px solid #9db2d857' }} alt='profile' />)} */}
                {!imgLoaded && (
                  <img
                    src={avatarPersonnel}
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "7px solid #9db2d857",
                    }}
                    alt="imgprofil"
                  />
                )}

                {imgLoaded && (
                  <img
                    src={""}
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      border: "7px solid #9db2d857",
                      marginTop: "-20px",
                    }}
                    alt="profile"
                  />
                )}
              </div>
              <div style={{ flex: 3 }}>
                <ul className="presenceEleve">
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px 0px",
                    }}
                  >
                    <span
                      style={{
                        color: "#7F7F7F",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      NOM :
                    </span>
                    <span
                      style={{
                        color: "#888",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      {personnelData?.nom?.toUpperCase()}{" "}
                      {personnelData?.postnom?.toUpperCase()}
                    </span>
                  </li>
                  <hr />
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px 0px",
                    }}
                  >
                    <span
                      style={{
                        color: "#7F7F7F",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      JOURNEE PEDAGOGIQUE :
                    </span>
                    <span
                      style={{
                        color: "#888",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      {personnelData?.jour_peda_0}
                      {"-"}
                      {personnelData?.jour_peda_1}
                    </span>
                  </li>
                  <hr />
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px 0px",
                    }}
                  >
                    <span
                      style={{
                        color: "#7F7F7F",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      PONCTUALITE :
                    </span>
                    <span
                      style={{
                        color: "#888",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      {cote_designation.toUpperCase()}

                      {/* {personnelData?.cote_eleve_designation === "Absence" &&
                        (personnelData?.status_justif === 0
                          ? " NON JUSTIFIE"
                          : " JUSTIFIE")}

                      {personnelData?.cote_eleve_designation === "Retard" &&
                        (personnelData?.status_justif === 0
                          ? " NON JUSTIFIE"
                          : " JUSTIFIE")} */}
                    </span>
                  </li>
                  <hr />
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px 0px",
                    }}
                  >
                    <span
                      style={{
                        color: "#7F7F7F",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      HEURE D'ARRIVEE :
                    </span>
                    <span
                      style={{
                        color: "#888",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      {!!personnelData?.heure_arrivee
                        ? moment(personnelData?.heure_arrivee).format("llll")
                        : "-----------"}
                    </span>
                  </li>
                  <hr />
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px 0px",
                    }}
                  >
                    <span
                      style={{
                        color: "#7F7F7F",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      HEURE DE SORTIE :
                    </span>
                    <span
                      style={{
                        color: "#888",
                        padding: "3px 6px",
                        fontSize: "12px",
                        backgroundColor: "#9db2d857",
                      }}
                    >
                      {!!personnelData?.heure_sortie
                        ? moment(personnelData?.heure_sortie).format("llll")
                        : "-----------"}
                    </span>
                  </li>
                  <hr />
                  <li
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      marginTop: "30px",
                      marginBottom: "5px",
                    }}
                  >
                    {/* //DISPLAY ONLY IF USER IS DDISCIPLINE OR ADMIN */}

                    {personnelData?.cote_enseignant_id === 3 &&
                    personnelData?.status_justif === 0 ? (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "#0091EA",
                          padding: "5px 0px",
                          justifyContent: "center",
                          width: "150px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={handleJustify}
                      >
                        Justifier
                        {motifInput ? <ArrowDropUp /> : <ArrowDropDown />}
                      </span>
                    ) : (
                      <span></span>
                    )}

                    {personnelData?.cote_enseignant_id === 2 &&
                    personnelData?.status_justif === 0 ? (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "#0091EA",
                          padding: "5px 0px",
                          justifyContent: "center",
                          width: "150px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={handleJustify}
                      >
                        Justifier
                        {motifInput ? <ArrowDropUp /> : <ArrowDropDown />}
                      </span>
                    ) : (
                      <span></span>
                    )}

                    {personnelData?.cote_enseignant_id === 3 &&
                    personnelData?.status_justif === 1 ? (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "#0091EA",
                          padding: "5px 0px",
                          justifyContent: "center",
                          width: "150px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={handleShowMotif}
                      >
                        Voir motif
                        {motifText ? <ArrowDropUp /> : <ArrowDropDown />}
                      </span>
                    ) : (
                      ""
                    )}

                    {personnelData?.cote_enseignant_id === 2 &&
                    personnelData?.status_justif === 1 ? (
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: "#0091EA",
                          padding: "3px 10px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={handleShowMotif}
                      >
                        Voir motif
                        {motifText ? <ArrowDropUp /> : <ArrowDropDown />}
                      </span>
                    ) : (
                      ""
                    )}

                    {/* <span
                    style={{
                      color: "#fff",
                      backgroundColor: "#0091EA",
                      padding: "3px 10px",
                      fontSize: "12px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={hanlePlusDetail}
                  >
                    Détail{" "}
                    <ArrowCircleRightRounded style={{ marginLeft: "5px" }} />
                  </span> */}
                  </li>
                </ul>
              </div>
            </div>

            {/* Session de motif & justification */}
            <div className="motifSection">
              {error && displayErrorMsg}

              {motifInput && addJustif}

              {motifText && displayJustif}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <NotificationDialog
        openMsg={openSuccess}
        setOpenMsg={setOpenSuccess}
        message={succesMsg}
        textBtn="Ok"
        textStyle={textStyle}
        title=""
        setOpen=""
        statusText=""
      />

      {/* ------------------------------------------------------------- */}

      {/* DIALOG POUR VOIR PLUS DES POCTUALITE JOURNALIERE */}

      {/* ------------------------------------------------------------- */}

      <Dialog
        fullWidth
        maxWidth="md"
        sx={{ height: "500px", top: ".5rem" }}
        open={open}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItem: "center",
          }}
        >
          <StyledIconTitle>
            <ChromeReaderModeRounded color="primary" />
            <span
              style={{ fontSize: "15px", color: "#0091EA", fontWeight: "400" }}
            >
              Catalogue de Ponctualité - Elève
            </span>
          </StyledIconTitle>

          {/* <div style={{ fontSize: "14px", fontWeight: 600 }}>NOM: {dataDetailPlus[0].noms}</div> */}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0, minHeight: "320px", maxHeight: "320px" }}>
          <DialogContentText sx={{ display: "flex", flexDirection: "column" }}>
            <TableauRetardDetailPlus
              dataDetailPlus={dataDetailPlus}
              printRef={printRef}
            />
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="text"
            startIcon={<PrintRounded color="primary" />}
            onClick={handleClickPrint}
            color="primary"
          >
            imprimer
          </Button>
          <Button variant="text" onClick={() => setOpen(false)} color="warning">
            Quitter
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogContent>
          <DialogContentText>
            <Stack>
              <Alert severity="warning">
                <strong>{errorMsg}</strong>
              </Alert>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="warning"
            size="small"
            sx={{ marginRight: "15px" }}
            onClick={() => {
              setOpen2(false);
              setError(false);
              setErrorMsg("");
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PresenceDetail;
