import { Circles, RotatingLines } from "react-loader-spinner";
import "./loaderLogin.scss";
import React from "react";

type Props = {
  isLoading: boolean;
};
const LoaderLogin = ({ isLoading }: Props) => {
  return (
    <>
      {isLoading && (
        <div className="MyloaderLogin">
          <div className="loader">
            <RotatingLines
              visible={true}
              width="30"
              strokeColor="#4fa94d"
              strokeWidth="3"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
            {/* <span>Chargement...</span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default LoaderLogin;
