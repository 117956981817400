import styled from "@emotion/styled";
import "./edit.scss";
import { AttachFile, Close, LibraryBooks } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import timerServices from "../../../_services/timerServices";
import API from "../../../App.constants";
import axios from "axios";
import SimpleBackdrop from "../../loader/CircularBackdrop";

const Edit = ({
  open,
  setOpen,
  resId,
  valueData,
  reloadPage,
  setReloadPage,
}) => {
  const [classe, setClasse] = useState("");
  const [type, setType] = useState("");
  const [titre, setTitre] = useState("");
  const [contenu, setContenu] = useState("");
  const [status, setStatus] = useState(true);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const [file, setFile] = useState();

  useEffect(() => {
    if (valueData !== {}) {
      setClasse(valueData.classe === null ? "" : valueData.classe);
      setTitre(valueData.titre === null ? "" : valueData.titre);
      setType(valueData.type === null ? "" : valueData.type);
      setContenu(valueData.contenu === null ? "" : valueData.contenu);
      setStatus(!!valueData.status ? true : false);
    }
  }, [resId, valueData]);

  const handleCheckbox = (e) => {
    setStatus(e.target.checked);
  };

  const StyledIconTitle = styled("span")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  });

  const updateRessource = (__status) => {
    console.log("Update");
    console.log(__status);
    setLoading(true);
    setMsg("");

    const date = timerServices.getFormatedFullDay();

    if (file !== undefined) {
      const filename = new FormData();

      if (__status === true) {
        filename.append("file", file);
        filename.append("titre", titre);
        filename.append("type", type);
        filename.append("classe", classe);
        filename.append("contenu", contenu);
        filename.append("date_publication", date);
        filename.append("status", 1);
      } else if (__status === false) {
        filename.append("file", file);
        filename.append("titre", titre);
        filename.append("type", type);
        filename.append("classe", classe);
        filename.append("contenu", contenu);
        filename.append("status", 0);
      }

      const config = {
        "Content-Type": "multipart/from-data",
      };

      axios
        .patch(`${API}/api/ressource/update/${resId}`, filename, config)
        .then((res) => {
          setLoading(false);

          if (res.statusText === "OK") {
            setMsg("success");
            setReloadPage(true);
          }

          setTimeout(() => {
            setMsg("");
            setOpen(false);
            setReloadPage(false);
          }, 3000);

          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (file === undefined) {
      var fileData = {};
      if (__status === true) {
        fileData = {
          titre: titre,
          type: type,
          classe: classe,
          contenu: contenu,
          status: 1,
          date_publication: date,
        };
      } else if (__status === false) {
        fileData = {
          titre: titre,
          type: type,
          classe: classe,
          contenu: contenu,
          status: 0,
          date_publication: null,
        };
      }

      const config = {
        "Content-Type": "application/json",
      };
      axios
        .patch(
          `${API}/api/ressource/update/updatewithoutfile/${resId}`,
          fileData,
          config
        )
        .then((res) => {
          if (res.statusText === "OK") {
            setMsg("success");
            setReloadPage(true);
          }
          setLoading(false);

          setTimeout(() => {
            setMsg("");
            setOpen(false);
            setReloadPage(false);
          }, 3000);

          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      {console.log("valueData")}
      {console.log(valueData)}
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{ height: "500px", top: "1rem" }}
        open={open}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItem: "center",
          }}
        >
          <StyledIconTitle>
            <LibraryBooks color="primary" />
            <Typography
              color="primary"
              sx={{ fontWeight: "400", fontSize: "14px" }}
            >
              Editer - Devoir de mathématique{status ? 1 : 0}
            </Typography>
          </StyledIconTitle>

          <Close
            onClick={() => setOpen(false)}
            color="primary"
            size="small"
            cursor="pointer"
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 0, pb: 0 }}>
          <DialogContentText component={"div"} sx={{ paddingBottom: 1 }}>
            <div className="container">
              <div className="left">
                <input
                  type="text"
                  className="title"
                  name="titre"
                  placeholder="Titre de la ressource"
                  value={titre}
                  onChange={(e) => setTitre(e.target.value)}
                />
                <div className="level_2">
                  <input
                    type="text"
                    className="type"
                    name="type"
                    placeholder="Type de la ressource"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                  <input
                    type="text"
                    className="classe"
                    name="classe"
                    placeholder="classe"
                    value={classe}
                    onChange={(e) => setClasse(e.target.value)}
                  />
                </div>

                <textarea
                  type="text"
                  className="content"
                  name="content"
                  placeholder="Contenu..."
                  value={contenu}
                  onChange={(e) => setContenu(e.target.value)}
                />
                <div className="publish_btn">
                  <div className="check_btn">
                    <span className="Pub_title">Pulié?</span>
                    <Checkbox
                      checked={status}
                      onChange={handleCheckbox}
                      color="success"
                    />
                  </div>
                </div>
              </div>

              {loading && <SimpleBackdrop />}
              <div className="right">
                <Typography component={"h5"}>Joindre un fichier</Typography>
                <label className="file" htmlFor="fileInput">
                  <AttachFile
                    sx={{ fontSize: 55, color: "skyblue", cursor: "pointer" }}
                  />
                  <Typography component={"h5"}>Upload</Typography>
                  <input
                    type="file"
                    hidden
                    id="fileInput"
                    name="fileInput"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </label>
                <div className="ressource_btn">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: "12px" }}
                    onClick={() => updateRessource(status)}
                  >
                    Valider
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setOpen(false)}
                    color="warning"
                  >
                    annuler
                  </Button>
                </div>

                {msg === "success" && (
                  <Alert severity="success" sx={{ padding: "0px 8px", mt: 2 }}>
                    <AlertTitle> Mis à jour effectuée</AlertTitle>
                  </Alert>
                )}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Edit;
