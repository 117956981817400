export const INITIAL_STATE = {
    titre: '',
    type: '',
    classe: '',
    contenu: '',
    list: [],
    error: false
}

export const ressourceReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_INPUT":
            return {
                ...state,
                error: false,
                [action.payload.name]: action.payload.value,
            };

        case "INIT_INPUT":
            return INITIAL_STATE

        default:
            return state;
    }
}

