import { Add, ListAltRounded } from '@mui/icons-material'
import { ListItemButton, ListItemIcon, List, ListItemText, Divider } from '@mui/material'
import React from 'react'

const DepenseHeader = ({ showMenuOne, setShowMenuOne }) => {

    const user = JSON.parse(localStorage.getItem("user"))

    const handleClickList = () => {
        setShowMenuOne(true)
    }
    const handleClickAdd = () => {
        setShowMenuOne(false)
    }
    return (


        <List component={'div'} sx={{ display: 'flex' }} >

            <ListItemButton onClick={handleClickList} sx={showMenuOne ? { backgroundColor: "#EEEEFF" } : ""}>
                <ListItemIcon>
                    <ListAltRounded sx={{ color: "#0080FF" }} />
                </ListItemIcon>
                <ListItemText primary="Toutes les dépenses" sx={{ color: "#0080FF" }} />
                <Divider />
            </ListItemButton>


            {
                (!!user.iscomptable || !!user.isadmin) &&
                (
                    <ListItemButton onClick={handleClickAdd} sx={!showMenuOne ? { backgroundColor: "#EEEEFF" } : ""}>
                        <ListItemIcon>
                            <Add sx={{ color: "#0080FF" }} />
                        </ListItemIcon>
                        <ListItemText primary="Nouvelle dépense" sx={{ color: "#0080FF" }} />
                    </ListItemButton>
                )

            }


        </List>
    )
}

export default DepenseHeader