import { GroupOutlined } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import "./boxone.scss";
import { EleveType } from "../../../types/GlobalType";

interface Props {
  data: EleveType[] | [];
}
const BoxOne = (props: Props) => {
  let eleveAll = props.data;
  console.log(eleveAll);

  return (
    <div className="box_one">
      <div className="card_info">
        {/* <span>Elèves</span> */}
        <div className="card_icon">
          <div className="wraper">
            <GroupOutlined className="ico" />
          </div>
        </div>
        <h1>{eleveAll.length}</h1>
        <span>Total élèves</span>
      </div>
    </div>
  );
};

export default BoxOne;
