import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { PrintRounded } from "@mui/icons-material";
import DepenseHeader from "../finance/Depense_Header";
import DepenseList from "../finance/Depense_List";
import DepenseAddNew from "../finance/Depense_AddNew";
// import SimpleBackdrop from '../loader/CircularBackdrop'
import API from "../../App.constants";
import axios from "axios";
import NotificationDialog from "../../assets/constant/NotificationDialog.tsx";
import { useReactToPrint } from "react-to-print";

const Depense = ({ open, setOpen }) => {
  const [showMenuOne, setShowMenuOne] = useState(true);

  const StyledIconTitle = styled("span")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    marginBottom: "1rem",
  });

  const printRef = useRef();

  const handleClickPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: ".",
  });

  const [openMsg, setOpenMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [textBtn, setTextBtn] = useState("");
  const [textStyle, setTextStyle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API}/api/finance/all-depenses`, config)
      .then((response) => {
        // console.log("Depensessssssss")
        // console.log(response)
        setIsLoading(false);
        setData(response.data.data);
      })
      .catch((error) => {
        // console.log("Depenses error")
        // console.log(error)
        setIsLoading(false);
        if (error.code === "ERR_NETWORK") {
          setTitle("Echec de connexion");
          setMessage(error.message + ":: Vérifier votre connexion internet");
          setTextBtn("OK");
          setTextStyle("warning");
          setOpenMsg(true);
        } else {
          setTitle("Alerte");
          setMessage("Une erreur inconnue est survenue, réessayer plus tard");
          setTextStyle("warning");
          setTextBtn("OK");
          setOpenMsg(true);
        }
      });
  }, [showMenuOne]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{ height: "550px", mt: 4 }}
        open={open}
        component={"div"}
        // onClose={() => setOpenDialog(false)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItem: "center",
          }}
        >
          <StyledIconTitle>
            <Typography
              variant="h6"
              color="default"
              sx={{ fontWeight: "500", fontSize: "15px" }}
            >
              Dépenses
            </Typography>
          </StyledIconTitle>

          <Divider />

          <DepenseHeader
            showMenuOne={showMenuOne}
            setShowMenuOne={setShowMenuOne}
          />

          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {showMenuOne && (
              <DepenseList
                data={data}
                printRef={printRef}
                isLoading={isLoading}
              />
            )}
            {!showMenuOne && <DepenseAddNew />}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {showMenuOne && (
            <Button
              variant="text"
              startIcon={<PrintRounded color="primary" />}
              onClick={handleClickPrint}
              color="primary"
            >
              {" "}
              Imprimer{" "}
            </Button>
          )}

          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
              setShowMenuOne(true);
            }}
            color="warning"
          >
            Quitter
          </Button>
        </DialogActions>
      </Dialog>

      {openMsg && (
        <NotificationDialog
          openMsg={openMsg}
          setOpenMsg={setOpenMsg}
          title={title}
          message={message}
          textBtn={textBtn}
          textStyle={textStyle}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default Depense;
