import axios from "axios";
import API, { URL_BACKEND_APP } from "../App.constants";

const getToken = () => {
  return JSON.parse(localStorage.getItem("user")).token;
};

const getUserImei = () => {
  return JSON.parse(localStorage.getItem("user")).id;
};
const getUserEmail = () => {
  return JSON.parse(localStorage.getItem("user")).user.email;
};

const getUserName = () => {
  return JSON.parse(localStorage.getItem("user")).username;
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("classes");
  localStorage.removeItem("cours");
  localStorage.removeItem("personnels");
};

const isLogged = () => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  if (token) return true;
  return false;
};

const getClasseList = () => {
  const classesList = JSON.parse(localStorage.getItem("classes"));

  var classes = ["TOUTES"];

  if (!!classesList) {
    for (let cl of classesList) {
      classes.push({ id_classe: cl.id_classe, designation: cl.designation });
    }
    return classes;
  }

  return;
};

const getCoursList = () => {
  const coursList = JSON.parse(localStorage.getItem("cours"));

  var cours = ["Ajouter un nouveau cours"];

  if (!!coursList) {
    for (let crs of coursList) {
      cours.push(crs.designation);
    }
    return cours;
  }

  return;
};

const getPersonnelList = () => {
  const personnelList = JSON.parse(localStorage.getItem("personnels"));

  var personnel = [];

  if (!!personnelList) {
    for (let pers of personnelList) {
      personnel.push({
        id: pers.id,
        nom: pers.enseignant,
      });
    }
    return personnel;
  }

  return;
};

const saveClasseIntoLocalStorage = () => {
  axios
    .get(`${URL_BACKEND_APP}/classe/read.php`)
    .then((res) => {
      localStorage.setItem("classes", JSON.stringify(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

const saveCoursIntoLocalStorage = () => {
  axios
    .get(`${API}/api/cours/getall`)
    .then((res) => {
      localStorage.setItem("cours", JSON.stringify(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

const saveTeachersIntoLocalStorage = () => {
  axios
    .get(`${API}/api/personnel/getall`)
    .then((res) => {
      localStorage.setItem("personnels", JSON.stringify(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const accountService = {
  logout,
  isLogged,
  getToken,
  getUserEmail,
  getUserImei,
  getUserName,
  getClasseList,
  getCoursList,
  getPersonnelList,
  saveClasseIntoLocalStorage,
  saveTeachersIntoLocalStorage,
  saveCoursIntoLocalStorage,
};
