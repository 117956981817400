import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  ArrowRight,
  PaidSharp,
  ArrowBackRounded,
  MoreVertOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./PaiementDetail.css";
import ChartPaiementSynthese from "../component/chart/ChartPaiementSynthese";
import DialoguePaiementConfirmPsw from "../component/dialog/Paiement_Confirmation";
import DialoguePaiementHistorique from "../component/dialog/Paiement_Historique.tsx";
import studentpp from "../assets/images/avatar_2.png";
import {
  EleveType,
  MontantType,
  PaiementType,
  requestType,
} from "../types/GlobalType";
import toast from "react-hot-toast";
import { accountService } from "../_services/accountServices";
import { API_PAIEMENT } from "../apiCaller/paiement.Caller";
import moment from "moment";

export const PaiementDetail = () => {
  const location = useLocation();
  const eleveData: EleveType = location.state;

  //   const { compte, montant_annuel } = location.state;

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const calculPourcentage = (nbr, tot) => {
    const reste = tot - nbr;
    const num = (nbr / tot) * 100;
    const pourcentage = num.toFixed(2);
    return {
      reste,
      pourcentage: Number(pourcentage),
    };
  };

  //   const { reste, pourcentage } = calculPourcentage(compte, montant_annuel);

  const [motifFrais, setMotifFrais] = useState("");
  const [montant, setMontant] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [openHistorique, setOpenHistorique] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogHistorique, setOpenDialogHistorique] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  //New settings
  const [paiementData, setpaiementData] = React.useState<PaiementType[]>([]);

  const [amountTotalSet, setamountTotalSet] = React.useState<MontantType[]>([]);
  const [CurrentSY, setCurrentSY] = React.useState("2024-2025");

  const amountSortedByClasses: MontantType[] = [];
  let currentAmountSY: number = 0;
  //   const amountSortedByCurrent_Cl_SY: MontantType = null;

  if (amountTotalSet.length > 0) {
    for (let item of amountTotalSet) {
      if (eleveData.classe_id === item.classe_id)
        amountSortedByClasses.push(item);
    }
  }

  //affecter le montant de la classe courante
  amountTotalSet[0]?.montant
    ? (currentAmountSY = amountTotalSet[0].montant)
    : (currentAmountSY = 0);

  let totalPaidArray: number[] = [];
  let totalPaidSum: number = 0;
  let restePaidSum: number = currentAmountSY;

  let currentElevePaiement: PaiementType[] = [];
  if (paiementData.length > 0) {
    for (let item of paiementData) {
      if (item.eleve_id === eleveData.eleve_id) {
        currentElevePaiement.push(item);
      }
    }
  }

  if (currentElevePaiement.length > 0) {
    for (let item of currentElevePaiement) {
      totalPaidArray.push(item.montant);
    }

    const reducer = (acc, curvalue) => acc + curvalue;
    totalPaidSum = totalPaidArray.reduce(reducer, 0);
    restePaidSum = currentAmountSY - totalPaidSum;
  }
  const fetchAllAmountPerClassAndSY = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_PAIEMENT.MONTANT.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Data: {
        //   config: null;
        //   data: { data: PaiementType[]; message: string };
        //   status: number;
        //   statusText: string;
        // } = {
        //   config: null,
        //   data: { data: [], message: "Empty data" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData = [];

        if (response.data === "") {
          setamountTotalSet(empyData);
        } else {
          setamountTotalSet(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  const fetchAllPaiement = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_PAIEMENT.PAIEMENT.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Data: {
        //   config: null;
        //   data: { data: PaiementType[]; message: string };
        //   status: number;
        //   statusText: string;
        // } = {
        //   config: null,
        //   data: { data: [], message: "Empty data" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: PaiementType[] = [];

        if (response.data === "") {
          setpaiementData(empyData);
        } else {
          setpaiementData(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenHistorique(false);
  };

  const handleClickOption = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenHistorique(true);
  };

  const handleClickHistorique = () => {
    setError(false);
    setOpenHistorique(false);
    setOpenDialogHistorique(true);
  };

  const handleClick = () => {
    setError(false);
    setError2(false);

    if (motifFrais === "") {
      setError2(true);
      return setErrorMsg("Entrer le motif de paiement");
    }

    if (montant !== "") {
      if (isValidNumber(montant)) {
        setError(false);
        setOpenDialog(true);
      } else {
        setError(true);
        setErrorMsg("Le montant entré n'est pas valide");
      }
    } else {
      // console.log("Le montant est nul")
      setError(true);
      setErrorMsg(" Le montant est nul");
    }
  };

  // Montant Validation function
  const isValidNumber = (num) => {
    const NumFormat = /^[1-9]\d*$/;

    if (num.match(NumFormat)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const studentImag: any = new Image();
    studentImag.onload = () => {
      setImgLoaded(true);
    };
    studentImag.src = eleveData.path_img;
  }, [eleveData.path_img]);

  useEffect(() => {
    fetchAllAmountPerClassAndSY();
    fetchAllPaiement();
  }, []);

  return (
    <Box
      bgcolor="#eee"
      flex={5}
      p={1}
      sx={{ marginLeft: "200px", marginTop: "66px" }}
    >
      {/* ------------------PAGE TITLE-------------------- */}

      <Stack direction="row" mt={1}>
        <div className="paymentContenair">
          <div className="paiementMsg">
            <div className="iconPaiement">
              <Link to="/home/paiement" style={{ marginTop: "5px" }}>
                <ArrowBackRounded
                  sx={{
                    color: "#0091EA",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              </Link>
              <div className="homeMenu">
                <div className="homeTitleItem">
                  <span className="homeTitle"> Etats financiers</span>
                </div>

                <div className="homeTitleItem2">
                  <PaidSharp sx={{ color: "#1565C0" }} />
                  <span className="homeTitleSmall">
                    {eleveData.nom} {eleveData.postnom}
                  </span>
                  <ArrowRight sx={{ color: "#1976D2" }} />
                  <span className="homeTitleSmall">
                    {eleveData.classe_designation}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="paiementBtn">
                        <Button variant="contained" color="info" onClick={handleClickHistorique}>Historique de paiement</Button>
                    </div> */}
        </div>
      </Stack>

      <div className="PaiementContainer">
        <div className="studentCard">
          <div className="studentImg">
            {!imgLoaded && (
              <img src={studentpp} alt={eleveData.nom} className="studentPP" />
            )}

            {imgLoaded && (
              <img
                src={eleveData.path_img}
                alt={eleveData.nom}
                className="studentPP"
              />
            )}
          </div>

          <div className="studentDetail">
            <div className="studentItem">
              <div className="keyItem">Nom:</div>
              <div className="valueItem">
                {eleveData.nom} {eleveData.postnom}
              </div>
            </div>
            <div className="studentItem">
              <div className="keyItem">Matricule:</div>
              <div className="valueItem">{eleveData.eleve_uid}</div>
            </div>
            <div className="studentItem">
              <div className="keyItem">Sexe:</div>
              <div className="valueItem">{eleveData.sexe}</div>
            </div>
            <div className="studentItem">
              <div className="keyItem">Classe:</div>
              <div className="valueItem">{eleveData.classe_designation}</div>
            </div>
            <div className="studentItem">
              <div className="keyItem">Date de naissance:</div>
              <div className="valueItem">
                {moment(eleveData.date_naissance).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="studentItem">
              <div className="keyItem">Contact Tuteur:</div>
              <div className="valueItem">
                {eleveData.telephone === null
                  ? "---------"
                  : eleveData.telephone}
              </div>
            </div>
          </div>
        </div>

        <div className="hospitalEditDetail">
          <div className="titlePaimentIcon">
            <div className="upadateTitle">
              Paiement des frais scolaires
              {(error || error2) && (
                <span
                  style={{
                    marginLeft: "2rem",
                    padding: "2px 10px",
                    backgroundColor: "#EEEEEE",
                    color: "#FC0000",
                    fontSize: "12px",
                    borderRadius: "5px",
                  }}
                >
                  {errorMsg}
                </span>
              )}
            </div>
            <div className="paiementDetLeft">
              <select
                name=""
                // value={selectedYear}
                // onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>2024-2025</option>
              </select>
              <span className="iconOption" onClick={handleClickOption}>
                <MoreVertOutlined />
              </span>
            </div>
          </div>
          <div className="formChart">
            {/* {!!(user.isadmin || user.iscomptable) && ( */}
            <form className="updateForm">
              <div className="updateLeft">
                <div className="itemRowUpdate">
                  <TextField
                    select
                    label="Type de frais "
                    error={error2}
                    helperText="Selectionner le type de frais à payer"
                    value={motifFrais}
                    onChange={(e) => setMotifFrais(e.target.value)}
                    size="small"
                  >
                    <MenuItem value="minerval">Minerval</MenuItem>
                    <MenuItem value="contruction">Construction</MenuItem>
                    <MenuItem value="autres frais">Autres Frais</MenuItem>
                  </TextField>
                </div>

                <div className="itemRowUpdate">
                  <TextField
                    type="text"
                    value={montant}
                    onChange={(e) => {
                      setMontant(e.target.value);
                    }}
                    label="Montant à payer"
                    required
                    error={error}
                    helperText="La devise acceptée est le dollar (usd)"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                </div>

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleClick}
                  sx={{ mt: "-4%" }}
                >
                  <Typography sx={{ fontWeight: "600" }}> Valider</Typography>
                </Button>
              </div>
            </form>
            {/* )} */}

            <div className="updateRight">
              <span className="hospitalShowTitle">Situation financière</span>
              <div className="chart">
                <div className="ChartDetail">
                  {/* <ChartPaiement /> */}
                  <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
                    Montant payé
                  </span>
                  <ChartPaiementSynthese
                    title="Payé"
                    // pourcentage={`${50} %`}
                    // montant={77}
                    pourcentage={
                      currentAmountSY > 0
                        ? `${((totalPaidSum * 100) / currentAmountSY).toFixed(
                            1
                          )} %`
                        : "0%"
                    }
                    montant={`${totalPaidSum.toFixed(2)} $`}
                    color="#green"
                  />
                </div>
                <div className="ChartDetail">
                  <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
                    Montant restant
                  </span>
                  <ChartPaiementSynthese
                    title="Restant"
                    // pourcentage={`${80} %`}
                    // montant={20}
                    pourcentage={
                      currentAmountSY > 0
                        ? `${((restePaidSum * 100) / currentAmountSY).toFixed(
                            1
                          )} %`
                        : "0%"
                    }
                    montant={`${restePaidSum.toFixed(2)} $`}
                    color="#red"
                  />
                </div>
                <div className="ChartDetail">
                  <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
                    Montant total
                  </span>
                  <ChartPaiementSynthese
                    title="Annuel"
                    pourcentage={`${100} %`}
                    montant={`${currentAmountSY} $`}
                    color="#blue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MENU POUR HISTORIQUE DE PAYEMENT */}
      <Menu open={openHistorique} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleClickHistorique}>Historique</MenuItem>
      </Menu>

      {/* DIALOG BOX  ENUMERATING PAYEMENT LOG */}
      <DialoguePaiementHistorique
        openDialogHistorique={openDialogHistorique}
        setOpenDialogHistorique={setOpenDialogHistorique}
        id_card={eleveData.eleve_id}
        data={currentElevePaiement}
      />

      {/* DIALOG BOX CONFIRMING PAYEMENT */}
      <DialoguePaiementConfirmPsw
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        motifFrais={motifFrais}
        montant={montant}
        setMontant={setMontant}
        id_card={eleveData.eleve_id}
      />
    </Box>
  );
};
