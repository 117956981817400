import React, { useState } from "react";
import { CloudOff, VerifiedOutlined } from "@mui/icons-material";
import PresenceDetail from "../../component/dialog/personnel/PresenceDetail.tsx";
import "../../assets/styles/printpage.css";
import HeaderPrint from "../../component/headerPrint/HeaderPrint.js";
import { API_PERSONNEL } from "../../apiCaller/personnel.Caller.js";
import { accountService } from "../../_services/accountServices.js";
import {
  PersonnelType,
  PonctualitePersonnelType,
  requestType,
} from "../../types/GlobalType.ts";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ListElevesCotationJournalier = ({
  listPersonnel,
  // searchByClasse,
  // eleveSearch,
  formatDate,
  printRef,
  msg,
  forceUpdateOnUpdate,
}: {
  listPersonnel: PonctualitePersonnelType[];
  formatDate: string;
  printRef: any;
  msg: any;
  forceUpdateOnUpdate: () => void;
}) => {
  const [name, setName] = useState("");
  const [matricule, setMatricule] = useState("");
  const [photo, setPhoto] = useState("");
  const [classe, setClasse] = useState("");
  const [cote, setCote] = useState("");
  const [justification, setJustification] = useState("");
  const [motif, setMotif] = useState("");
  const [heureArrive, setHeureArrive] = useState("");
  const [heureSorti, setheureSorti] = useState("");
  const [jour, setJour] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [allPersonnel, setallPersonnel] = useState<PersonnelType[]>([]);

  const [singleData, setsingleData] =
    React.useState<PonctualitePersonnelType>();

  const handleDetailPresence = (event, item) => {
    // console.log(event)
    // console.log(item)

    setName(item.noms);
    setMatricule(item.id_card);
    setClasse(item.designation);
    setCote(item.cote);
    setHeureArrive(item.heure_arrive);
    setheureSorti(item.heure_depart);
    setJustification(item.justification);
    setMotif(item.motif);
    setJour(item.jour);
    setPhoto(item.photo);
    setIsOpen(true);
  };

  const eleveData = {
    name,
    matricule,
    photo,
    classe,
    cote,
    heureArrive,
    heureSorti,
    justification,
    motif,
    jour,
  };
  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const tableBody = (
    <tbody>
      {listPersonnel &&
        listPersonnel
          // .filter((item) => {
          //   return searchByClasse === "TOUTES"
          //     ? item && eleveSearch.toLowerCase() === ""
          //       ? item
          //       : item.noms.toLowerCase().includes(eleveSearch)
          //     :
          //       item.designation.includes(searchByClasse) &&
          //         item.noms.toLowerCase().includes(eleveSearch);
          // })
          .map((item: PonctualitePersonnelType, index: number) => {
            return (
              <tr
                className={index % 2 === 0 ? "" : "print_row_color"}
                onClick={() => {
                  setsingleData(item);
                  setIsOpen(true);
                }}
                key={index}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #9db2d857",
                  padding: "20px 0px",
                }}
              >
                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    color: "#777",
                    width: "15%",
                  }}
                >
                  {`00${index + 1}`}
                </td>

                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    textAlign: "left",
                    color: "#777",
                  }}
                >
                  {item.nom}
                </td>

                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    textAlign: "left",
                    color: "#777",
                  }}
                >
                  {item.postnom}
                </td>
                <td
                  style={{
                    fontSize: "13px",
                    padding: "10px 0px",
                    fontFamily: "poppinsRegular",
                    textAlign: "left",
                    color: "#777",
                  }}
                >
                  {item.prenom}
                </td>

                <td style={{ textAlign: "right" }}>
                  {item.cote_enseignant_id === 1 ? (
                    <div
                      style={{
                        color: "#fff",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "skyblue",
                        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      P
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#555",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      P
                    </div>
                  )}
                </td>

                <td style={{ textAlign: "right" }}>
                  {item.cote_enseignant_id === 2 ? (
                    <div
                      style={{
                        color: "#fff",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "skyblue",
                        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      R
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#555",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      R
                    </div>
                  )}
                </td>

                <td style={{ textAlign: "right" }}>
                  {item.cote_enseignant_id === 3 ? (
                    <div
                      style={{
                        color: "#fff",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "skyblue",
                        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      A
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#555",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      A
                    </div>
                  )}
                </td>

                <td style={{ textAlign: "right" }}>
                  {!!item.status_justif ? (
                    <div
                      style={{
                        color: "#fff",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#16841d7e",
                        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VerifiedOutlined />
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#555",
                        fontWeight: "700",
                        padding: " 5px",
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        marginTop: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      J
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
    </tbody>
  );

  const tableBodyNoData = (
    <tbody>
      <tr>
        <td colSpan={7}>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "2rem",
            }}
          >
            <CloudOff sx={{ color: "#3c72d5ed", fontSize: "4rem" }} />
            <span style={{ fontSize: "13px", color: "#3c72d5ed" }}>
              Aucune donnée disponible pour le jour selectionné
            </span>
          </span>
        </td>
      </tr>
    </tbody>
  );

  const tableBodyNoConnexion = (
    <div style={{ marginTop: "30px" }}>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
        }}
      >
        <CloudOff sx={{ color: "skyblue", fontSize: "4rem" }} />
        <span
          style={{ fontSize: "13px", color: "skyblue", fontStyle: "italic" }}
        >
          Connexion impossible, réessayer plus tard
        </span>
      </span>
    </div>
  );
  return (
    <>
      {listPersonnel ? (
        <>
          <div ref={printRef} className="PersonelTableAttendance">
            <HeaderPrint title={"ETPM - Liste de présence des personnels"} />

            <table
              className={"toPrint tableO"}
              style={{
                borderCollapse: "collapse",
                margin: "5px",
                padding: "0px 10px",
                width: "99%",
              }}
            >
              <thead style={{ backgroundColor: "#EEEEFF", height: "2.0rem" }}>
                <tr
                  className="headerLibelle"
                  style={{ fontSize: "13px", color: "#3c72d5ed" }}
                >
                  <th style={{ textAlign: "center", width: "15%" }}>Num</th>
                  <th style={{ textAlign: "left" }}>Nom</th>
                  <th style={{ textAlign: "left" }}>Post-nom</th>
                  <th style={{ textAlign: "left" }}>Prénom</th>
                  {/* <th>Classe</th> */}
                  <th
                    style={{ textAlign: "left", padding: "20px" }}
                    className="p"
                  >
                    P
                  </th>
                  <th
                    style={{ textAlign: "left", padding: "20px" }}
                    className="r"
                  >
                    R
                  </th>
                  <th
                    style={{ textAlign: "left", padding: "20px" }}
                    className="a"
                  >
                    A
                  </th>
                  <th
                    style={{ textAlign: "left", padding: "20px" }}
                    className="j"
                  >
                    Justif
                  </th>
                </tr>
              </thead>

              {listPersonnel.length === 0 ? (
                <>{tableBodyNoData}</>
              ) : (
                <>{tableBody}</>
              )}
            </table>
          </div>
        </>
      ) : (
        <></>
      )}

      {msg.type === "ERR_NETWORK_FETCH" ? tableBodyNoConnexion : <></>}

      <PresenceDetail
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        personnelData={singleData}
        formatDate={formatDate}
        forceUpdateOnUpdate={forceUpdateOnUpdate}
      />
    </>
  );
};

export default ListElevesCotationJournalier;
