import React, { useState } from "react";
import "./frais.scss";
import Delete from "../../../component/dialog/delete/Delete.tsx";
import SearchBar from "../../../component/searchBar/Search.tsx";
import { Tooltip } from "@mui/material";
import { Add, Print } from "@mui/icons-material";
import NewFrais from "./NewFrais.tsx";
import UpdateFrais from "./UpdateFrais.tsx";

const FraisScolaire = () => {
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  const [openAddFrais, setopenAddFrais] = React.useState<boolean>(false);
  const [openUpdateFrais, setopenUpdateFrais] = React.useState<boolean>(false);

  const [role, setRole] = useState("");

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  return (
    <>
      <div className="topFrais">
        <div className="card_Classe">
          <div className="headerFrais">
            <h2 className="card-title">Frais scolaire</h2>

            <div className="input-container">
              <SearchBar />
              <button className="add_button_pers">
                <Tooltip title="Imprimer">
                  <Print className="ico" />
                </Tooltip>
              </button>
              <button className="add_button_pers">
                <Tooltip title="Ajouter">
                  <Add className="ico" onClick={() => setopenAddFrais(true)} />
                </Tooltip>
              </button>
            </div>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Classe</th>
                  <th>Montant</th>
                  <th>Année scolaire</th>
                  <th className="actionHeader">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2 em SC</td>
                  <td>250$</td>
                  <td>2020-2021</td>
                  <td className="actionSection">
                    <span
                      className="edit"
                      onClick={() => setopenUpdateFrais(true)}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => setopenDeleteArchive(true)}
                    >
                      Archiver
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>3 em SC</td>
                  <td>250$</td>
                  <td>2020-2021</td>
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span className="archive">Archiver</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openAddFrais && <NewFrais setOpen={setopenAddFrais} />}
      {openUpdateFrais && <UpdateFrais setOpen={setopenUpdateFrais} />}

      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={"id"}
          label={"Voulez-vous vraiment archiver ce frais?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default FraisScolaire;
