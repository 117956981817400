import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ChartRetardOne from "../../chart/ChartRetardOne";
import ListElevesCotation from "../../../Tableau/elevePonctualite/ListElevesCotationJournalier.tsx";
import { Print } from "@mui/icons-material";
import { accountService } from "../../../_services/accountServices";
import toast from "react-hot-toast";
import {
  ClasseType,
  PonctualiteType,
  requestType,
} from "../../../types/GlobalType";
import { API_CLASSE } from "../../../apiCaller/classe.Caller";
import { useNavigate } from "react-router-dom";
import { API_ELEVE } from "../../../apiCaller/eleve.Caller";
import moment from "moment";

const RapportJournalier = () => {
  const printRef = useRef();

  const date = new Date();

  const exactJour = moment().format("DD-MM-YYYY");

  const [defaultDay, setDefaultDay] = useState(date.toISOString().slice(0, 10)); // default date on display (ISOFormat)
  const [formatDate, setFormatDate] = useState(exactJour); //listenner onChange and transform to our db formatDay

  // console.log(exactJour);
  // console.log(defaultDay);
  // console.log(formatDate);

  const [selectedClasse, setSelectedClasse] = useState("TOUTES");
  // const [eleveSearch, setEleveSearch] = useState("");

  // console.log(selectedClasse);

  // const [nbPresence, setNbPresence] = useState(0);
  // const [nbAbsence, setNbAbsence] = useState(0);
  // const [nbRetard, setNbRetard] = useState(0);
  let listEleve: PonctualiteType[] = [];

  const [msg, setMsg] = useState({ type: "", text: "" });

  const handleDateChange = (e) => {
    setFormatDate(e.target.value.split("-").reverse().join("-"));
    setDefaultDay(e.target.value);
  };

  // const handleChange = (e) => {
  //   setEleveSearch(e.target.value);
  // };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Liste de présence des élèves - ETPM",
  });

  const navigate = useNavigate();
  const [classes, setclasses] = useState<ClasseType[]>([]);
  const [allPonctualite, setallPonctualite] = React.useState<PonctualiteType[]>(
    []
  );

  if (allPonctualite.length > 0) {
    for (let item of allPonctualite) {
      if (
        moment(item.ponctualite_eleve_created_at).format("DD-MM-YYYY") ===
        formatDate
      ) {
        listEleve.push(item);
      }
    }
  }

  const [reducerValueOnUpdate, forceUpdateOnUpdate] = React.useReducer(
    (x) => x + 1,
    0
  );

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const fetchAllPonctualite = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();
    if (!token) return navigate("/");

    API_ELEVE.PONCTUALITE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: [] = [];

        if (response.data === "") {
          setallPonctualite(empyData);
        } else {
          setallPonctualite(response.data.data);
        }

        console.log(response);
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 403) {
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else {
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard" + err
          );
        }
      });
  };

  const fetchAllClasse = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();
    if (!token) return navigate("/");

    API_CLASSE.CLASSE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData = [];

        if (response.data === "") {
          setclasses(empyData);
        } else {
          setclasses(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401 || err.response.status === 403) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };
  useEffect(() => {
    fetchAllClasse();
    fetchAllPonctualite();
  }, [reducerValueOnUpdate]);
  return (
    <div
      style={{
        display: "grid",
        // gridTemplateColumns: "5fr 2fr",
        gridTemplateColumns: "1fr",
        gap: "10px",
        marginTop: "20px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "0px 0px 35px 35px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".2rem",
            backgroundColor: "#fff",
            borderRadius: "0px 8px 8px 0px ",
          }}
        >
          <div className="paiementMsg">
            <div className="iconPaiement">
              <span
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  fontWeight: "500",
                  color: "#888",
                }}
              >
                Classes
              </span>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <select
                defaultValue={selectedClasse}
                onChange={(e) => setSelectedClasse(e.target.value)}
                className="selectClasse"
              >
                <option value={"TOUTES"}>{"TOUTES"}</option>
                {classes.map((value, index) => {
                  return (
                    <option key={index} value={value.classe_designation}>
                      {value.classe_designation.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={handlePrint}
          >
            <Print sx={{ color: "blue", fontSize: 18 }} onClick={handlePrint} />
            <span
              style={{
                fontFamily: "poppinsRegular",
                fontSize: "12px",
                textDecoration: "underline",
              }}
            >
              Print
            </span>
          </div>
          <div className="inputDate">
            <input
              type="date"
              style={{ height: "35px", outline: "none" }}
              value={defaultDay}
              onChange={handleDateChange}
            />
          </div>

          {/* <div className="input-class-retard">
            <input
              type="text"
              value={eleveSearch}
              onChange={handleChange}
              placeholder="Rechercher par nom"
              className="search-bar-retard"
            />
          </div> */}
        </div>

        <div className="tableList" style={{ textAlign: "center" }}>
          <ListElevesCotation
            listEleve={listEleve}
            searchByClasse={selectedClasse}
            // eleveSearch={eleveSearch}
            formatDate={formatDate}
            printRef={printRef}
            msg={msg}
            forceUpdateOnUpdate={forceUpdateOnUpdate}
          />
        </div>
      </div>

      {/* -----------------LEFT SIDE/ IT SHOWS DETAIL INFORMATION OF CLASS AND SPECICIFIC STUDENT-------------- */}
      {/* <div className="detailClasse">
        <div className="detail">
          <div className="detailTitle">
            <span className="optionTitle">Information Générale</span>
            <hr />
          </div>

          <ChartRetardOne
            nbPresence={nbPresence}
            nbRetard={nbRetard}
            nbAbsence={nbAbsence}
          />
        </div>
      </div> */}
    </div>
  );
};

export default RapportJournalier;
