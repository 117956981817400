import React, { useState } from "react";
import "./eleve.scss";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  AccountCircleRounded,
  ArrowBackRounded,
  CancelRounded,
  CheckCircle,
  MoreVertOutlined,
  PersonAddAlt1,
  PictureAsPdfRounded,
  Print,
  Visibility,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import Delete from "../../../component/dialog/delete/Delete.tsx";
import SearchBar from "../../../component/searchBar/Search.tsx";
import UpdateEleve from "./UpdateEleve.tsx";
import NewEleve from "./newEleve.tsx";
import SingleEleve from "./singleEleve.tsx";

const ITEM_HEIGHT = 48;

const Eleve = () => {
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);

  const [role, setRole] = useState("");

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAdd, setOpenAdd] = useState<Boolean>(false);
  const [openSingle, setOpenSingle] = useState<Boolean>(false);
  const [openUpdate, setOpenUpdate] = useState<Boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneNumberPattern =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  //phone number state
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [existanceYear, setExistanceYear] = useState("Annee");
  const [validdNumber, setValidNumber] = useState<boolean>(true);
  const handleChangePhone = (input: string) => {
    setPhoneNumber(input);
    setValidNumber(validatePhoneNumber(input));
  };

  const navigation = useNavigate();
  return (
    <>
      <Box bgcolor="#eee" flex={5} p={1} sx={{ marginLeft: "200px" }}>
        <div className="configuration_">
          <div className="homeMenuCong">
            {/* <Link to="/home" style={{ marginTop: "5px" }}> */}
            <ArrowBackRounded
              sx={{
                color: "#0091EA",
                backgroundColor: "#fff",
                borderRadius: "50%",
                mt: "5px",
                cursor: "pointer",
              }}
              onClick={() => navigation(-1)}
            />
            {/* </Link> */}
            <div className="homeTitleItem">
              <span className="homeTitle"> Configuration</span>
            </div>
            <div className="homeTitleItem">
              <PersonAddAlt1 sx={{ color: "#1565C0", mr: "10px" }} />
              <span className="homeTitleSmall">Elève </span>
            </div>
          </div>
          <div className="right__">
            <button
              className="add_button_pers"
              onClick={() => setOpenAdd(true)}
            >
              <Tooltip title="Nouveau élève">
                <PersonAddAlt1 className="ico" />
              </Tooltip>
              Nouveau
            </button>
            <button className="add_button_pers">
              <Tooltip title="Exporter PDF">
                <PictureAsPdfRounded className="ico" />
              </Tooltip>
              exporter
            </button>
          </div>
        </div>
        <Divider sx={{ marginTop: "5px" }} />

        <div className="card_Classe">
          <div className="input-container">
            <h2 className="card-title">Liste</h2>
            <div className="right_list_part">
              <SearchBar />
              <button className="add_button_pers">
                <Tooltip title="Imprimer">
                  <Print className="ico" />
                </Tooltip>
              </button>
            </div>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Noms</th>
                  <th>Classe</th>
                  <th>Année</th>
                  <th className="headerSection">Status</th>
                  <th className="actionHeader"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td className="userName">
                    <div className="userName">
                      <AccountCircleRounded />
                      Kavira kalimubalo Yvette
                    </div>
                  </td>
                  <td>2 em</td>
                  <td>2020-2021</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Activé" placement="left">
                        <CheckCircle
                          style={{ color: "#28a745", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td>
                    <div className="actionSection">
                      <span className="more">
                        <Visibility
                          className="ico"
                          onClick={() => {
                            setOpenSingle(true);
                            navigate("enregistrement-eleve");
                          }}
                        />
                      </span>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertOutlined className="ico" />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "14ch",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setopenDeleteArchive(true);
                          }}
                          sx={{ fontSize: 12, fontWeight: "" }}
                        >
                          Activer
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setopenDeleteArchive(true);
                          }}
                          sx={{ fontSize: 12, fontWeight: "" }}
                        >
                          Archiver
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenUpdate(true);
                          }}
                          sx={{ fontSize: 12, fontWeight: "" }}
                        >
                          Modifier
                        </MenuItem>
                      </Menu>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    {" "}
                    <div className="userName">
                      <AccountCircleRounded />
                      Paluku Letakamba Blaise
                    </div>
                  </td>
                  <td>2 em</td>
                  <td>2020-2021</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Archivé" placement="left">
                        <CancelRounded
                          style={{ color: "#7f1d18", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td>
                    <div className="actionSection">
                      <span className="more">
                        <Visibility
                          className="ico"
                          onClick={() => {
                            setOpenSingle(true);
                            navigate("enregistrement-eleve");
                          }}
                        />
                      </span>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertOutlined className="ico" />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "14ch",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setopenDeleteArchive(true);
                          }}
                          sx={{ fontSize: 12, fontWeight: "" }}
                        >
                          Activer
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setopenDeleteArchive(true);
                          }}
                          sx={{ fontSize: 12, fontWeight: "" }}
                        >
                          Archiver
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenUpdate(true);
                          }}
                          sx={{ fontSize: 12, fontWeight: "" }}
                        >
                          Modifier
                        </MenuItem>
                      </Menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Box>

      {openUpdate && <UpdateEleve setOpen={setOpenUpdate} />}
      {openAdd && <NewEleve setOpen={setOpenAdd} />}
      {openSingle && <SingleEleve setOpen={setOpenSingle} />}

      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={"id"}
          label={"Voulez-vous vraiment achiver cet élève?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default Eleve;
