import {
  GroupOutlined,
  SupervisedUserCircleOutlined,
} from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import "./boxTwo.scss";

interface Props {
  data: any;
}
const BoxTwo = (props: Props) => {
  return (
    <div className="box_one">
      <div className="card_info">
        {/* <span>Elèves</span> */}
        <div className="card_icon">
          <div className="wraper">
            <SupervisedUserCircleOutlined className="ico2" />
          </div>
        </div>
        <h1>{props.data.length}</h1>
        <span>Total Personnel</span>
      </div>
    </div>
  );
};

export default BoxTwo;
