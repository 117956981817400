import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  InputAdornment,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import {
  NumbersRounded,
  Person,
  Phone,
  Telegram,
  Visibility,
} from "@mui/icons-material";
import NotificationDialog from "../../assets/constant/NotificationDialog.tsx";
import API from "../../App.constants";
import axios from "axios";

const AddAgentDialog = ({ open2, setOpen2, setReload }) => {
  const titre = [
    "Enseignant",
    "Comptable",
    "Directeur Discipline",
    "Administrateur",
  ];

  const [passType, setPassType] = useState("password");

  const handlePassVisible = () => {
    if (passType === "password") setPassType("text");
    else if (passType === "text") setPassType("password");
  };

  //input states
  const [selectedAttribut, setSelectedAttribut] = useState("Enseignant");
  const [username, setUsername] = useState("");
  const [matricule, setMatricule] = useState("");
  const [password, setPassword] = useState("");

  //handle error states
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  //succes dialog
  const [openSuccess, setOpenSuccess] = useState(false);
  const [succesMsg, setSuccesMsg] = useState("");
  const [textStyle, setTextStyle] = useState("");

  const onSelectChange = (event) => {
    setSelectedAttribut(event.target.value);
  };

  const handleSave = () => {
    setError(false);
    setErrorMsg("");

    if (username === "") {
      setError(true);
      return setErrorMsg("Veuiller compléter tous les champs");
    }

    if (matricule === "") {
      setError(true);
      return setErrorMsg("Veuiller compléter tous les champs");
    }

    if (password === "") {
      setError(true);
      return setErrorMsg("Veuiller compléter tous les champs");
    }

    const newUser = {
      isadmin: selectedAttribut === "Administrateur" ? 1 : 0,
      iscomptable: selectedAttribut === "Comptable" ? 1 : 0,
      isddiscipline: selectedAttribut === "Directeur Discipline" ? 1 : 0,
      isenseignant: selectedAttribut === "Enseignant" ? 1 : 0,
      noms: username,
      imeiagent: matricule,
      password: password,
    };

    axios
      .post(`${API}/api/agent/signup/`, newUser)
      .then((response) => {
        setOpen2(false);

        setOpenSuccess(true);
        setSuccesMsg(response.data.message);
        setTextStyle("success");
        setUsername("");
        setMatricule("");
        setPassword("");

        setReload((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          console.log(error.code);
          setError(true);
          return setErrorMsg("Connexion impossible, réessayer plutard");
        }
        setError(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const handleError = (
    <Alert severity="error" sx={{ marginY: "5px" }}>
      <strong>{errorMsg}</strong>
    </Alert>
  );
  return (
    <>
      <Dialog
        components={"div"}
        maxWidth="md"
        sx={{ height: "550px", top: ".5rem" }}
        open={open2}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            borderBottom: "1px solid #999",
          }}
        >
          {/* <StyledIconTitle> */}
          <Typography
            variant="h6"
            color="default"
            sx={{ fontWeight: "500", fontSize: "15px" }}
          >
            Nouvel agent
          </Typography>
          {/* </StyledIconTitle> */}
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <DialogContentText>
              <div>
                {/* handle error */}
                {error && handleError}

                <div style={{ display: "flex", gap: "20px" }}>
                  {/* {(errorType || errorMontant || errorMotif) && messageError} */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1rem",
                      gap: "10px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <TextField
                        placeholder="Nom de l'agent"
                        helperText="Nom de l'agent"
                        maxWidth={100}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Person
                                sx={{ color: "#C4C4C4", cursor: "pointer" }}
                              />
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                        // error={errorType}
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        size="small"
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <select
                        style={{
                          border: "1px solid #888",
                          color: "#888",
                          width: "100%",
                          height: "40px",
                        }}
                        defaultValue={selectedAttribut}
                        onChange={onSelectChange}
                      >
                        {titre.map((value, index) => {
                          return (
                            <option key={index} value={value}>
                              {value.toUpperCase()}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1rem",
                      gap: "10px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <TextField
                        placeholder="Téléphone"
                        helperText="Numéro de téléphone (Ex: 0990110000)"
                        maxWidth={100}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Phone
                                sx={{ color: "#C4C4C4", cursor: "pointer" }}
                              />
                            </InputAdornment>
                          ),
                          autoComplete: "Matricule",
                        }}
                        // error={errorType}
                        value={matricule}
                        size="small"
                        onChange={(e) => {
                          setMatricule(e.target.value);
                        }}
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <TextField
                        type={passType}
                        helperText="Mot de passe"
                        placeholder="Mot de passe"
                        required
                        maxWidth={50}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Visibility
                                onClick={handlePassVisible}
                                sx={{ color: "#C4C4C4", cursor: "pointer" }}
                              />
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: "#008080", color: "#fff" }}
                    onClick={handleSave}
                  >
                    <Typography variant="span" sx={{ fontWeight: "600" }}>
                      Enregister
                    </Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ color: "#008080" }}
                    onClick={() => {
                      setOpen2(false);
                      setError(false);
                      setUsername("");
                      setMatricule("");
                      setPassword("");
                      setSelectedAttribut("Enseignant");
                      setErrorMsg("");
                    }}
                  >
                    <Typography variant="span" sx={{ fontWeight: "600" }}>
                      Annuler
                    </Typography>
                  </Button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </DialogContent>
      </Dialog>

      <NotificationDialog
        openMsg={openSuccess}
        setOpenMsg={setOpenSuccess}
        message={succesMsg}
        textBtn="Ok"
        textStyle={textStyle}
      />
    </>
  );
};

export default AddAgentDialog;
