import React, { useState } from 'react'

import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button, Typography, TextField, InputAdornment, Divider
} from '@mui/material'
import { VisibilityRounded } from '@mui/icons-material'
import axios from 'axios'
import API from '../../App.constants'

const ConfirmationPsw = ({ open, setOpen, title, operation, data, setIsLoading, enregistrerDepense }) => {
    let { type, montant, motif } = data
    console.log("confirmation PSW")


    const user = JSON.parse(localStorage.getItem("user"))
    const connectedAgent = user.id

    const [value, setValue] = useState('')
    const [password, setPassword] = useState('password')
    const [errorPass, setErrorPass] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const handleShowPsw = () => {
        password === "password" ? setPassword("text") : setPassword("password")
    }

    const confirmPsw = async () => {

        setErrorPass(false)

        if (value === "") {
            setErrorPass(true)
            return setErrorMsg('Veuillez enter le mot de passe')
        }

        setIsLoading(true)

        await axios.post(`${API}/api/agent/signin`, {
            imeiagent: connectedAgent,
            password: value
        })
            .then(response => {
                // console.log(response)
                if (response.data.message === 'Authentification réussie') {

                    enregistrerDepense(type, montant, motif)
                    // setPswdConformed(true)
                    setOpen(false)
                }
                // setIsLoading(false);
                // setOpenDialog(false);



                if (response.data.message === 'Une erreur s\'est produite, réessayer plus tard') {
                    // setOpenDialog(true)
                    // setErrorPass(true)
                    return setErrorMsg(response.data.message);
                }

                // setOpenDialogSuccessPsw(true)
                // setSuccessMsg(response.data.message)

            }).catch(error => {
                setIsLoading(false)
                setValue('')
                if (error.code === 'ERR_NETWORK') {
                    // setOpenDialog(true)
                    setErrorPass(true)
                    return setErrorMsg(error.message + ' :Vérifier la connexion Internet');
                }

                else if (error.response.data.status === 408) {
                    // setOpenDialog(true)
                    setErrorPass(true)
                    return setErrorMsg(error.response.data.message);
                } else {
                    // setOpenDialog(true)
                    setErrorPass(true)
                    return setErrorMsg(error.response.data.message);
                }
            })
    }

    return (
        <Dialog open={open} component={'div'}>

            <DialogTitle sx={{ fontSize: "16px" }}>{title}</DialogTitle>
            <Divider />

            <DialogContent>
                <DialogContentText sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography sx={{ fontSize: "14px" }}>Veuillez entrer votre mot de passe pour confirmer cette opération</Typography>
                    <Typography sx={{ mb: "15px", fontSize: "14px" }}>{operation} : {montant} $ </Typography>

                    <TextField
                        type={password}
                        label='Mot de passe'
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        // helperText='Le devise accepté est le dollar (usd)'
                        InputProps={{
                            endAdornment: <InputAdornment position='start'><VisibilityRounded onClick={handleShowPsw} sx={{ cursor: 'pointer' }} /></InputAdornment>
                        }}
                    />
                    {errorPass &&
                        (<Typography sx={{ mt: "1rem", color: "red" }}>{errorMsg}</Typography>)
                    }
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button variant='text' color='error' onClick={() => setOpen(false)}>Annuler</Button>
                <Button variant='text' size='small' sx={{ marginRight: "15px" }} onClick={confirmPsw}>Confirmer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationPsw