import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Home";
import "../src/assets/styles/globalStyle.scss";
import Login from "./pages/login/Login.tsx";
import { AuthContext } from "./context/AuthContext";
// import NotFoundPage from './pages/pagenotfound/NotFoundPage'
import NotFoundPageOne from "./pages/pagenotfound/NotFoundPageOne";
import "moment/locale/fr";

const App = () => {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  // console.log("currentUser")
  // console.log(currentUser)

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/home/*"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route path="*" element={<NotFoundPageOne />} />
    </Routes>
  );
};

export default App;
