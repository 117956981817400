import React, { useRef, useState } from "react";
import "./PaiementJournalier.scss";
import { ChromeReaderModeRounded, Close } from "@mui/icons-material";
import "react-phone-input-2/lib/style.css";
import { Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { EleveType, PaiementType } from "../../types/GlobalType";
import moment from "moment";
import _ from "lodash";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataPaiement: PaiementType[];
  dataEleve: EleveType[];
}

const PaiementJournalier = (props: Props) => {
  const orderedData = _.orderBy(
    props.dataPaiement,
    ["paiement_created_at"],
    ["desc"]
  );

  const printRef = useRef<HTMLDivElement>(null);

  const handleClickPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: ".",
  });

  const date = new Date();
  const curr = date.toISOString().slice(0, 7);
  const jour = date.toString().split(" ")[2];

  const exactJour = (curr + "-" + jour).split("-").reverse().join("-");

  const [defaultDay, setDefaultDay] = useState(date.toISOString().slice(0, 10)); // default date on display (ISOFormat)
  const [formatDate, setFormatDate] = useState(exactJour); //listenner onChange and transform to our db formatDay

  const handleDateChange = (e) => {
    setFormatDate(e.target.value.split("-").reverse().join("-"));
    setDefaultDay(e.target.value);
  };

  return (
    <div className="paiementJournalier">
      <div className="dialog">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <ChromeReaderModeRounded color="primary" sx={{ fontSize: 17 }} />
              <Typography
                variant="h6"
                color="primary"
                sx={{ fontWeight: "500", fontSize: 15 }}
              >
                Historique des paiements
              </Typography>
            </div>
            <div className="right_">
              {/* <div className="inputDate" style={{ marginTop: "5px" }}>
                <input
                  type="date"
                  style={{ height: "35px" }}
                  defaultValue={exactJour}
                  value={defaultDay}
                  onChange={handleDateChange}
                />
              </div> */}
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </div>
          </div>
        </div>

        <div className="body">
          <table ref={printRef}>
            <thead>
              <tr>
                <th>Id</th>
                <th>Matricule</th>
                <th>Noms</th>
                <th>Classe</th>
                <th>Montant</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {orderedData.map((value, index) => {
                let uuid: string | undefined = "";
                let noms: string = "";
                for (let item of props.dataEleve) {
                  if (item.eleve_id === value.eleve_id) {
                    noms = `${item.nom} ${item.postnom} ${item.prenom}`;
                    uuid = item.eleve_uid;
                  }
                }
                return (
                  <tr key={index}>
                    <td>{`0${index + 1}`}</td>
                    <td>{uuid}</td>
                    <td>{noms.toUpperCase()}</td>
                    <td>{value.classe_designation}</td>
                    <td>{value.montant}$</td>
                    <td>
                      {moment(value.paiement_created_at).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="btn">
          <button onClick={() => props.setOpen(false)}>Retourner</button>
          <button onClick={handleClickPrint}>Imprimer</button>
        </div>
      </div>
    </div>
  );
};

export default PaiementJournalier;
