import {
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import API from "../../App.constants";
import NotificationDialog from "../../assets/constant/NotificationDialog.tsx";
// import SimpleBackdrop from '../loader/CircularBackdrop'
import ConfirmationPsw from "./ConfirmationPsw";
import MyLoading from "../loader/MyLoading.tsx";

function DepenseAddNew() {
  // console.log("newDEPENSE")

  const [typeDepense, setTypeDepense] = useState("");
  const [montantDepense, setMontantDepense] = useState("");
  const [motifDepense, setMotifDepense] = useState("");

  // open password dialog
  const [pswDiag, setPswDiag] = useState(false);
  const [pswdConfirmed, setPswdConfirmed] = useState(false);

  const [errorType, setErrorType] = useState(false);
  const [errorMontant, setErrorMontant] = useState(false);
  const [errorMotif, setErrorMotif] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const imeiagent = user.id;

  const [openMsg, setOpenMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [textBtn, setTextBtn] = useState("");
  const [textStyle, setTextStyle] = useState("");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };

  const enregistrerDepense = async (type, montant, motif) => {
    setTypeDepense("");
    setMontantDepense("");
    setMotifDepense("");

    try {
      const response = await axios.post(
        `${API}/api/finance/creer-depense/${imeiagent}`,
        {
          type: type,
          montant: montant,
          motif: motif,
        },
        config
      );

      // console.log("Depense")

      if (response.status === 200) {
        setIsLoading(false);
        setTitle("Message");
        setMessage(response.data.message);
        setTextBtn("Ok");
        setTextStyle("success");
        setOpenMsg(true);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log('Depense error');
      if (error.code === "ERR_NETWORK") {
        setTitle("Echec de connexion");
        setMessage(
          error.message + ":: Vérifier votre connexion internet et réessayer"
        );
        setTextBtn("OK");
        setTextStyle("warning");
        setOpenMsg(true);
      } else {
        setTitle("Alerte");
        setMessage("Une erreur inconnue est survenue, réessayer plus tard");
        setTextStyle("warning");
        setTextBtn("OK");
        setOpenMsg(true);
      }
    }
  };

  // Montant Validation function
  const isValidNumber = (num) => {
    const NumFormat = /^[1-9]\d*$/;

    if (num.match(NumFormat)) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickDepense = () => {
    setErrorType(false);
    setErrorMotif(false);
    setErrorMontant(false);

    if (typeDepense === "") {
      setErrorType(true);
      return setErrorMsg("Type de dépense non valide");
    }

    if (motifDepense === "") {
      setErrorMotif(true);
      return setErrorMsg("Compléter le motif de cette dépense");
    }

    if (montantDepense !== "") {
      if (!isValidNumber(montantDepense)) {
        setErrorMontant(true);
        return errorMsg("Montant non valide");
      } else {
        setPswDiag(true);
      }
    } else {
      setErrorMontant(true);
      return errorMsg("Compléter le montant dépense");
    }
  };

  const dataDepense = {
    type: typeDepense,
    montant: montantDepense,
    motif: motifDepense,
  };

  return (
    <div>
      {/* loader */}
      <MyLoading isLoading={isLoading} />

      <form>
        <div style={{ display: "flex", marginTop: "1rem", gap: "10px" }}>
          <div style={{ flex: 2 }}>
            <TextField
              select
              label="Type de dépense"
              fullWidth
              size="small"
              error={errorType}
              value={typeDepense}
              onChange={(e) => setTypeDepense(e.target.value)}
            >
              <MenuItem value="Logistique">Logistique</MenuItem>
              <MenuItem value="Scolarité">Scolarité</MenuItem>
              <MenuItem value="Paiement">Paiement</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </TextField>
          </div>

          <div style={{ flex: 1 }}>
            <TextField
              type="text"
              label="Montant"
              required
              fullWidth
              size="small"
              error={errorMontant}
              helperText="La devise acceptée est le dollar (usd)"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={montantDepense}
              onChange={(e) => setMontantDepense(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField
            type="text"
            value={motifDepense}
            onChange={(e) => {
              setMotifDepense(e.target.value);
            }}
            label="Motif"
            multiline
            rows={3}
            required
            error={errorMotif}
          />
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleClickDepense}
          >
            <Typography variant="span" sx={{ fontWeight: "600" }}>
              {" "}
              Effectuer la dépense
            </Typography>
          </Button>
        </div>
      </form>

      {openMsg && (
        <NotificationDialog
          openMsg={openMsg}
          setOpenMsg={setOpenMsg}
          title={title}
          message={message}
          textBtn={textBtn}
          textStyle={textStyle}
        />
      )}

      <ConfirmationPsw
        open={pswDiag}
        setOpen={setPswDiag}
        title={"Confirmation"}
        operation={"Dépense"}
        montant={montantDepense}
        pswdConfirmed={pswdConfirmed}
        setPswdConformed={setPswdConfirmed}
        data={dataDepense}
        enregistrerDepense={enregistrerDepense}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}

export default DepenseAddNew;
