import React, { useState } from "react";
import "./config.scss";
import { Box, Divider, Stack } from "@mui/material";
import {
  Add,
  ArrowBackRounded,
  PersonAddAlt1,
  SettingsOutlined,
  VerticalAlignBottom,
} from "@mui/icons-material";
import { Link, Outlet, useLocation } from "react-router-dom";

import axios from "axios";
import API from "../../App.constants";

const Configuration = () => {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");

  const [userData, setUserData] = useState([]);
  const [msg, setMsg] = useState({ type: "", text: "" });
  const [isLoading, setisLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  React.useEffect(() => {
    setisLoading(true);
    axios
      .get(`${API}/api/agent/all`, config)
      .then((response) => {
        setisLoading(false);
        setUserData(response.data.data);
      })
      .catch((err) => {
        // console.log("ERROR");
        // console.log(err);
        setisLoading(false);
        if (err.code === "ERR_NETWORK") {
          setMsg((prevState) => ({ ...prevState, type: "ERR_NETWORK" }));
        } else {
          setMsg((prevState) => ({
            ...prevState,
            type: "ERR_UNKNOWN",
            text: "Une erreur inconnue est survenue, réessayer plus tard",
          }));
          setTimeout(() => {
            setMsg({ type: "", text: "" });
            return;
          }, 3000);
        }
      });
  }, [reload]);

  return (
    <Box
      bgcolor="#eee"
      flex={5}
      p={1}
      sx={{ marginLeft: "200px", marginTop: "66px" }}
    >
      <div className="configuration_">
        <div className="homeMenuCong">
          <Link to="/home" style={{ marginTop: "5px" }}>
            <ArrowBackRounded
              sx={{
                color: "#0091EA",
                backgroundColor: "#fff",
                borderRadius: "50%",
              }}
            />
          </Link>
          <div className="homeTitleItem">
            <span className="homeTitle"> Configuration</span>
          </div>
          <div className="homeTitleItem">
            <SettingsOutlined sx={{ color: "#1565C0", mr: "10px" }} />
            <span className="homeTitleSmall">Système </span>
          </div>
        </div>
        <div className="right_">
          <Link to="/home/enregistrement-eleve">
            <div className="btn_commande">
              <PersonAddAlt1 className="ico" />
              <span>Elève</span>
            </div>
            <span className="ico_sm">
              <Add className="ico_sm_ico" />
            </span>
          </Link>
          <Link to="/home/enregistrement-personnel">
            <button>
              <Add className="ico" />
              <span>Personnel</span>
            </button>
            <button className="ico_sm">
              <Add className="ico_sm_ico" />
            </button>
          </Link>
        </div>
      </div>
      <Divider sx={{ marginTop: "5px" }} />

      <div className="stackMenu">
        <Link to={"/home/configuration/configuration"}>
          <button className={urlRoute[3] === "configuration" ? "active" : ""}>
            scolarite
          </button>
        </Link>
        <Link to={"/home/configuration/grade-et-fonction"}>
          <button
            className={urlRoute[3] === "grade-et-fonction" ? "active" : ""}
          >
            Grade & Fonction
          </button>
        </Link>
        <Link to={"/home/configuration/ponctualite"}>
          <button className={urlRoute[3] === "ponctualite" ? "active" : ""}>
            Ponctualite
          </button>
        </Link>
        <Link to={"/home/configuration/frais"}>
          <button className={urlRoute[3] === "frais" ? "active" : ""}>
            Frais scolaire
          </button>
        </Link>
        <Link to={"/home/configuration/depense"}>
          <button className={urlRoute[3] === "depense" ? "active" : ""}>
            Dépense
          </button>
        </Link>
        <Link to={"/home/configuration/terminal"}>
          <button className={urlRoute[3] === "terminal" ? "active" : ""}>
            Terminal
          </button>
        </Link>
        <Link to={"/home/configuration/communique"}>
          <button className={urlRoute[3] === "communique" ? "active" : ""}>
            Communication
          </button>
        </Link>
      </div>
      <Outlet />
    </Box>
  );
};

export default Configuration;
