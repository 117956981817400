import React from "react";
import "./sidebar.scss";
import { Link, useNavigate } from "react-router-dom";

import BoxOne from "../component/boxes/box1/BoxOne.tsx";
import BoxTwo from "../component/boxes/box2/BoxTwo.tsx";
import BoxThree from "../component/boxes/box3/BoxThree.tsx";
import ColumnChart from "../component/boxes/chart/ChartColumn.tsx";
import CircularChartPaimentPerClass from "../component/boxes/chart/CircularChartPaimentPerClass.tsx";
import { API_ELEVE } from "../apiCaller/eleve.Caller.js";
import { accountService } from "../_services/accountServices.js";
import toast from "react-hot-toast";
import { EleveType, PaiementType, requestType } from "../types/GlobalType.ts";
import { API_PERSONNEL } from "../apiCaller/personnel.Caller.js";
import { API_PAIEMENT } from "../apiCaller/paiement.Caller.js";
import { API_DEPENSE } from "../apiCaller/depense.Caller.js";
import { paiementContext, depenseContext } from "../context/AppContext.js";
function SideBar() {
  const [eleveData, setEleveData] = React.useState<EleveType[]>([]);
  const [personnelData, setpersonnelData] = React.useState([]);
  const [paiementData, setpaiementData] = React.useState<PaiementType[]>([]);
  const [depenseData, setdepenseData] = React.useState([]);

  const navigate = useNavigate();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const fetchAllEleve = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_ELEVE.ELEVE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        // let axios_Fake_Response = {
        //   config: null,
        //   data: { data: [], message: "Situation Rapport is Empty" },
        //   status: 205,
        //   statusText: "OK",
        // };

        let empyData: EleveType[] = [];

        if (response.data === "") {
          setEleveData(empyData);
        } else {
          setEleveData(response.data.data);
        }

        console.log(response);
      })

      .catch((err) => {
        console.log(err);
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };
  const fetchAllPersonnel = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_PERSONNEL.PERSONNEL.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        let empyData = [];

        if (response.data === "") {
          setpersonnelData(empyData);
        } else {
          setpersonnelData(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };
  const fetchAllPaiement = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_PAIEMENT.PAIEMENT.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        let empyData: PaiementType[] = [];

        if (response.data === "") {
          setpaiementData(empyData);
        } else {
          setpaiementData(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };
  const fetchAllDpense = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_DEPENSE.DEPENSE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        let empyData = [];

        if (response.data === "") {
          setdepenseData(empyData);
        } else {
          setdepenseData(response.data.data);
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  React.useEffect(() => {
    setTimeout(() => {
      fetchAllEleve();
      fetchAllPersonnel();
      fetchAllPaiement();
      fetchAllDpense();
    }, 2000);
  }, []);

  return (
    <div className="dashboard">
      <div className="header_dash">
        <span>Dashboard</span>
      </div>
      <div className="home_dash">
        <div className="box box1">
          {/* for clients */}
          <BoxOne data={eleveData} />
        </div>
        <div className="box box3">
          {/* for all orders */}
          <BoxTwo data={personnelData} />
          {/* <CardThree isLoading={state.isLoading} /> */}
        </div>
        <div className="box box4">
          {/* for oders */}
          <BoxThree data={paiementData} />
          {/* <CardFour isLoading={state.isLoading} /> */}
        </div>
        <div className="box box2">
          <div className="left_box">
            <span>Chiksoft</span>
            <span>Make perfect!</span>
          </div>
          <div className="right_box">
            {" "}
            <span>Année sc.</span>
            <span>2024-2025</span>
          </div>
          {/* <BoxFour /> */}
        </div>
        <div className="box box_graph1">
          {/* <ChartBox /> */}
          <paiementContext.Provider value={paiementData}>
            <depenseContext.Provider value={depenseData}>
              <ColumnChart />
            </depenseContext.Provider>
          </paiementContext.Provider>
          {/* <ColumnChart isLoading={state.isLoading} /> */}
        </div>
        <div className="box box_graph2">
          <CircularChartPaimentPerClass data={eleveData} />
        </div>
        {/* <div className="box box_list">
        </div> */}
      </div>
    </div>
    // <Box
    //   bgcolor="#eee"
    //   flex={5}
    //   p={1}
    //   className="d_sidebar"
    //   sx={{ marginLeft: "200px" }}
    // >
    //   ------------------PAGE TITLE--------------------
    //   <Stack direction="row" justifyContent="space-between">
    //     <div className="homeMenu">
    //       <Link to="/home" style={{ marginTop: "5px" }}>
    //         <HomeOutlined sx={{ color: "#0091EA", borderRadius: "50%" }} />
    //       </Link>
    //       <div className="homeTitleItem">
    //         <span className="homeTitle"> Accueil</span>
    //       </div>
    //       <div className="homeTitleItem2">
    //         <span className="homeTitleSmall">Tableau de bord </span>
    //       </div>
    //     </div>
    //   </Stack>
    //   <Divider sx={{ marginTop: "8px" }} />

    //   --------------------CONTAINER DASHBOARD---------------------

    //   <div className="dashboarContainer">
    //     <div className="dashbordItem">
    //       <div className="dashbordItemUp">
    //         <div className="dashDesc">
    //           <div className="descTitle1">Rapport paiement</div>
    //           <div className="descTitle2">Tous les payements mensuels</div>
    //         </div>
    //         <div className="dashIcon">
    //           <Link to="/home/paiement">
    //             <Fab color="primary" aria-label="add">
    //               <Paid />
    //             </Fab>
    //           </Link>
    //         </div>
    //       </div>
    //       <Link to="/home/paiement">
    //         <div className="dashbordItemDown">Ouvrir paiements</div>
    //       </Link>
    //     </div>

    //     <div className="dashbordItem">
    //       <div className="dashbordItemUp">
    //         <div className="dashDesc">
    //           <div className="descTitle1">Rapport Ponctualité</div>
    //           <div className="descTitle2">
    //             Rapport complet relatif aux ponctualités
    //           </div>
    //         </div>
    //         <div className="dashIcon">
    //           <Link to="/home/retard">
    //             <Fab color="error" aria-label="add">
    //               <AvTimerOutlined />
    //             </Fab>
    //           </Link>
    //         </div>
    //       </div>
    //       <Link to="/home/retard-eleve">
    //         <div className="dashbordItemDown">
    //           Ouvrir le rapport ponctualité
    //         </div>
    //       </Link>
    //     </div>

    //     <div className="dashbordItem">
    //       <div className="dashbordItemUp">
    //         <div className="dashDesc">
    //           <div className="descTitle1">Rapport Finance</div>
    //           <div className="descTitle2">
    //             Tous les états et rapports financiers{" "}
    //           </div>
    //         </div>
    //         <div className="dashIcon">
    //           <Link to="/home/finance">
    //             <Fab color="success" aria-label="add">
    //               <LocalAtmOutlined />
    //             </Fab>
    //           </Link>
    //         </div>
    //       </div>
    //       <Link to="/home/finance">
    //         <div className="dashbordItemDown">Ouvrir le rapport Financier</div>
    //       </Link>
    //     </div>
    //   </div>

    //   --------------------GRAPHIQUES---------------------

    //   <div className="graphicContainer">
    //     <div className="graphicItem">
    //       <div className="graphicItemOption">
    //         <h6>Evolution des dépenses-Ecole</h6>
    //       </div>
    //       <div className="graph">
    //         <DepenseChart />
    //       </div>
    //     </div>

    //     <div className="graphicItem">
    //       <div className="graphicItemOption">
    //         <h6>Evolution des paiements</h6>
    //       </div>
    //       <div className="graph">
    //         <AnnualChart />
    //       </div>
    //     </div>
    //   </div>

    //   <DashboardPaiementMensuel open={open} setOpen={setOpen} />
    // </Box>
  );
}

export default SideBar;
