import { BlurOn } from "@mui/icons-material";
import React from "react";

const ParentInfo = () => {
  return (
    <div className="tuteur_info">
      <h4 className="title_section">Information du tuteur</h4>
      <table>
        <tr>
          <td>Nom:</td>
          <td>Kakule</td>
        </tr>
        <tr>
          <td>Post-Nom:</td>
          <td>Witesyavwirwa</td>
        </tr>
        <tr>
          <td>Genre:</td>
          <td>Maculin</td>
        </tr>
        <tr>
          <td>Contact:</td>
          <td>0990119611</td>
        </tr>
      </table>
      <h4 className="title_section_2">Résidence</h4>
      <table>
        <tr>
          <td>Avenue:</td>
          <td>De la Conference</td>
        </tr>
        <tr>
          <td>Quartier:</td>
          <td>Kyeshero</td>
        </tr>
        <tr>
          <td>Commune:</td>
          <td>Goma</td>
        </tr>
        <tr>
          <td>Ville:</td>
          <td>Goma</td>
        </tr>
      </table>
    </div>
  );
};

export default ParentInfo;
