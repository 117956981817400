import React from "react";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";

type Props = {
  isLoading: boolean;
};
const MyLoading = ({ isLoading }: Props) => {
  return (
    <>
      {isLoading && (
        <Dialog open={isLoading}>
          <DialogContent
            sx={{
              width: "200px",
              height: "130px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <CircularProgress sx={{ color: "skyblue" }} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default MyLoading;
