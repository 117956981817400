import { Dialog, DialogContent, Typography } from '@mui/material'
import React, { useState } from 'react'
import './adminMenus.scss'
import { Book, Close, PersonAdd } from '@mui/icons-material'
import AddAgentDialog from '../AddAgentDialog'
import AddCoursDiag from '../AddCoursDiag'

const AdminMenus = ({ open, setOpen }) => {

    const [openAddAgent, setopenAddAgent] = useState(false);
    const [openAddCours, setopenAddCours] = useState(false);

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Dialog open={open} variant={'div'} >
                <DialogContent sx={{ width: 600, height: 300 }} className='adminMenus'>
                    <div className="adminHeader">
                        <Typography variant='h6' sx={{ color: 'skyblue', fontSize: '15px' }}>Configuration</Typography>
                        <Typography onClick={handleClose} variant='h6' sx={{ color: 'skyblue', fontSize: 15, cursor: 'pointer' }}><Close /></Typography>
                    </div>
                    <div className="menu">
                        <div className="item">
                            <Book sx={{ fontSize: 32, cursor: 'pointer' }}
                                onClick={() => { setopenAddCours(true); setOpen(false) }}
                            />
                            <span className='item-title'>Cours</span>
                        </div>
                        <div className="item">
                            <PersonAdd sx={{ fontSize: 32, cursor: 'pointer' }}
                                onClick={() => { setopenAddAgent(true); setOpen(false) }}
                            />
                            <span className='item-title'>Nouvel Agent </span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <AddAgentDialog open2={openAddAgent} setOpen2={setopenAddAgent} />
            <AddCoursDiag open={openAddCours} setOpen={setopenAddCours} />

        </>
    )
}

export default AdminMenus
