import styled from '@emotion/styled';
import './display.scss'
import { LibraryBooks, FileOpen, Print, Close, DoDisturbAltRounded, DeleteForever } from '@mui/icons-material'
import { Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import API from '../../../App.constants';
import axios from 'axios';
import ConfirmDialog from '../../dialog/confirmation/ConfirmationDialog';
import FeedbackNotification from '../../dialog/confirmation/FeedbackNotification';
import SimpleBackdrop from '../../loader/CircularBackdrop';

const Display = ({ open, setOpen, singleRes, reloadPage, setReloadPage }) => {

    const { id, titre, type, classe, contenu, file_name } = singleRes;
    const [openDeleteDial, setOpenDeleteDial] = useState(false)

    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState("")

    const StyledIconTitle = styled('span')({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem'
    })

    const handleDelete = (idresource) => {
        setOpenDeleteDial(false)
        setLoading(true)

        axios.delete(`${API}/api/ressource/remove/${idresource}`)
            .then(res => {

                setLoading(false)

                if (res.statusText === "OK") {
                    setMsg("success")
                    setReloadPage(true)
                }

                setTimeout(() => {
                    setMsg("");
                    setOpen(false);
                    setOpenDeleteDial(false)
                    setReloadPage(true)
                }, 3000);

                console.log(res)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)

                if (err.code === "ERR_NETWORK") {
                    setMsg("ERR_NETWORK")
                } else {
                    setMsg("ERR_UNKNOWN")
                }

                setTimeout(() => {
                    setMsg("");
                    setOpen(false);
                    setOpenDeleteDial(false)
                }, 3000);
            })
    }

    return (

        <>
            <Dialog fullWidth maxWidth='md' sx={{ height: "500px", top: "1rem" }}
                open={open}
            >
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>

                    <StyledIconTitle>
                        <LibraryBooks color='primary' />
                        <Typography color="primary" sx={{ fontWeight: "400", fontSize: '14px' }} >Ressource</Typography>
                    </StyledIconTitle>

                    <Close onClick={() => setOpen(false)} color='primary' size='small' cursor='pointer' />
                </DialogTitle>
                <Divider />

                {
                    loading &&
                    <SimpleBackdrop />
                }

                <DialogContent sx={{ pt: 0, pb: 0 }}>
                    <DialogContentText component={'div'} sx={{ paddingBottom: 1 }}>
                        <div className="container">
                            <div className="left">
                                <div className='titleRes'>
                                    <span className='titleR'>Titre:</span>
                                    <span style={{ fontSize: 14 }}>{titre}</span>
                                </div>
                                {/* <input type="text" className='title' name='title' placeholder='Titre de la ressource' value={title} disabled /> */}

                                <div className='type'>
                                    <span className='titleR'>Type:</span>
                                    <span style={{ fontSize: 14 }}>{type}</span>
                                </div>

                                <div className='classe'>
                                    <span className='titleR'>Classe:</span>
                                    <span style={{ fontSize: 14 }}>{classe}</span>
                                </div>

                                <textarea type="text" className='content' name='title' placeholder='Contenu...' value={contenu} disabled />
                                <div style={{ padding: '10px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                    {/* <div style={{ display: 'flex', gap: '10px', }}>
                                        <span style={{ fontSize: '14px' }}>Imprimer</span>
                                        <Print color="primary" cursor='pointer' />
                                    </div> */}
                                    <div style={{ display: 'flex', gap: '5px', justifyContent: "flex-end", cursor: "pointer" }} onClick={() => setOpenDeleteDial(true)}>
                                        <span style={{ fontSize: '14px', fontStyle: "italic", textDecoration: "underline" }}>Corbeille</span>
                                        {/* <Print color="primary" cursor='pointer' /> */}
                                        <DeleteForever color='warning' fontSize='small' cursor='pointer' onClick={() => setOpenDeleteDial(true)} />
                                    </div>
                                </div>
                            </div>

                            <div className="right">
                                {!!file_name === true ?
                                    (<>
                                        <Typography component={'h5'}>Pièce Jointe</Typography>
                                        {/* <a href={`http://localhost:8600/files/${file_name}`} target='_blank' rel='noreferrer'> */}
                                        <a href={`${API}/files/${file_name}`} target='_blank' rel='noreferrer'>
                                            <div className="file">
                                                <FileOpen sx={{ fontSize: 55, color: 'skyblue', cursor: 'pointer' }} />
                                            </div>
                                            <h5>Ouvrir</h5>
                                        </a>
                                    </>
                                    ) :
                                    (<>
                                        <Typography component={'h5'}>Aucune pièce Jointe</Typography>
                                        <div className="file">
                                            <DoDisturbAltRounded sx={{ fontSize: 55, color: 'skyblue', cursor: 'pointer' }} />
                                        </div>
                                    </>
                                    )}
                                <div className="ressource_btn">

                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>


            {(msg === "success") && <FeedbackNotification text={"Suppression effectuée avec succès"} severity={"success"} />}

            {(msg === "ERR_NETWORK") && <FeedbackNotification text={"Vérifier votre connexion Internet et réessayer"} severity={"warning"} />}

            {(msg === "ERR_UNKNOW") && <FeedbackNotification text={"Une erreur inconnue est survenue; réessayer plus tard"} severity={"warning"} />}


            <ConfirmDialog
                openDialog={openDeleteDial}
                setOpenDialog={setOpenDeleteDial}
                titleDialog={"Supression"}
                text1Dialog={"Voulez-vous supprimer cette ressource?"}
                cancelBtn={"Annuler"}
                actionBtn={"Suprimer"}
                setActionBtn={() => handleDelete(id)} />
        </>
    )
}

export default Display
