import {
  AccountCircleOutlined,
  AvTimerOutlined,
  ChromeReaderModeOutlined,
  ContentCopyOutlined,
  Dashboard,
  ExitToAppOutlined,
  LocalLibraryOutlined,
  PaidOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

export const classes = [
  "TOUTES",
  "7EB/A",
  "7EB/B",
  "7EB/C",
  "7EB/D",
  "7EB/E",
  "8EB/A",
  "8EB/B",
  "8EB/C",
  "8EB/D",
  "1HTEi/A",
  "1HTEi/B",
  "1HTM/A",
  "1HTM/B",
  "2HTEi",
  "2HTEo",
  "2HTMa",
  "2HTMg",
  "3HTEi",
  "3HTEo",
  "3HTMa",
  "3HTMg",
  "4HTEi",
  "4HTEo",
  "4HTMa",
  "4HTMg",
];

export const monthNames = [
  "Janvier",
  "Fevrier",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Decembre",
];

export const primaryMenu = [
  {
    label: "Dashboard",
    linkTo: "/home",
    icon: <Dashboard sx={{ fontSize: 20 }} />,
  },
  {
    label: "Paiement",
    linkTo: "/home/paiement",
    icon: <PaidOutlined sx={{ fontSize: 20 }} />,
  },
  // {
  //   label: "Finance",
  //   linkTo: "/home/finance",
  //   icon: <ChromeReaderModeOutlined sx={{ fontSize: 20 }} />,
  // },
  {
    label: "Ponctualité",
    linkTo: "/home/retard-eleve",
    icon: <AvTimerOutlined sx={{ fontSize: 20 }} />,
  },
  {
    label: "Communication",
    linkTo: "/home/communique",
    icon: <LocalLibraryOutlined sx={{ fontSize: 20 }} />,
  },
  // {
  //   label: "Ressource",
  //   linkTo: "/home/ressource",
  //   icon: <ContentCopyOutlined sx={{ fontSize: 20 }} />,
  // },
];

export const secondaryMenu = [
  // {
  //   label: "Compte",
  //   linkTo: "/home/profil",
  //   icon: <AccountCircleOutlined sx={{ fontSize: 20 }} />,
  // },
  {
    label: "Configuration",
    linkTo: "/home/configuration/configuration",
    icon: <SettingsOutlined sx={{ fontSize: 20 }} />,
  },
  {
    label: "Deconnexion",
    linkTo: "",
    icon: <ExitToAppOutlined sx={{ fontSize: 20 }} />,
  },
];

export const ERROR_TYPE = {
  ERR_NETWORK: "ERR_NETWORK",
  ERR_UNKNOWN: "ERR_UNKNOWN",
};
export const ROLES = {
  ADMINISTRATEUR: "Administrateur",
  PROVISEUR: "Proviseur",
  COMPTABLE: "Comptable",
  D_DISCIPLINE: "Directeur de discipline",
  ENSEIGNANT: "Enseignant",
};
