import { CloudOff } from '@mui/icons-material'
import React from 'react'

const ConnexionErrorDiv = ({ errMsg }) => {
    return (
        <div style={{ padding: '50px 0px' }}>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: "2rem" }}>
                <CloudOff sx={{ color: "skyblue", fontSize: '4rem' }} />
                <span style={{ fontSize: '13px', color: 'skyblue', fontStyle: 'oblique' }}>{errMsg}</span>
            </span>
        </div>
    )
}

export default ConnexionErrorDiv
