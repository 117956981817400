import React from "react";
import "./singlestyles.scss";

const PersonnelInfo = () => {
  return (
    <div className="tuteur_info">
      <h4 className="title_section">Information Elève</h4>
      <table>
        <tr>
          <td>Nom:</td>
          <td>Kakule</td>
        </tr>
        <tr>
          <td>Post-Nom:</td>
          <td>Witesyavwirwa</td>
        </tr>
        <tr>
          <td>Prénom:</td>
          <td>Esdras</td>
        </tr>
        <tr>
          <td>Classe:</td>
          <td>5sc</td>
        </tr>
        <tr>
          <td>Date de naissance:</td>
          <td>Goma, le 22 Fevrier 1995</td>
        </tr>
      </table>
      <h4 className="title_section_2">Adresse résidentielle</h4>
      <table>
        <tr>
          <td>Avenue:</td>
          <td>De la Conference</td>
        </tr>
        <tr>
          <td>Quartier:</td>
          <td>Kyeshero</td>
        </tr>
        <tr>
          <td>Commune:</td>
          <td>Goma</td>
        </tr>
        <tr>
          <td>Ville:</td>
          <td>Goma</td>
        </tr>
      </table>
    </div>
  );
};

export default PersonnelInfo;
