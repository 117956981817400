import { LinearProgress } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import "./circularchart.scss";
import { EleveType } from "../../../types/GlobalType";
import moment from "moment";

interface Props {
  data: EleveType[];
}

type trancheAgeType = {};

type trancheA = EleveType[];

const CircularChartPaimentPerClass = (props: Props) => {
  let allEleves = props.data;
  let girls: EleveType[] = [];
  let boys: EleveType[] = [];

  let under10: EleveType[] = [];
  let between10_12: EleveType[] = [];
  let between13_14: EleveType[] = [];
  let between15_16: EleveType[] = [];
  let between17_18: EleveType[] = [];
  let over18: EleveType[] = [];

  if (allEleves.length > 0) {
    for (let items of allEleves) {
      let ageBrut = moment().diff(items.date_naissance, "years", true);
      let age: number = parseFloat(ageBrut.toFixed(2));
      if (age >= 10 && age < 13) {
        between10_12.push(items);
      } else if (age >= 12 && age < 15) {
        between13_14.push(items);
      } else if (age >= 14 && age < 16) {
        between15_16.push(items);
      } else if (age >= 16 && age < 19) {
        between17_18.push(items);
      } else if (age < 10) {
        under10.push(items);
      } else if (age > 18) {
        over18.push(items);
      }

      items.sexe?.toLowerCase() === "F".toLowerCase()
        ? girls.push(items)
        : boys.push(items);
    }
  }

  const state: any = {
    series: [
      under10.length,
      between10_12.length,
      between13_14.length,
      between15_16.length,
      between17_18.length,
      over18.length,
    ],
    options: {
      chart: {
        height: 450,
        // type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: "bottom",
      },
      labels: [
        "Moins de 10 ans",
        "Entre 10-12 ans",
        "Entre 13-14 ans",
        "Entre 15-16 ans",
        "Entre 17-18 ans",
        "Plus de 18 ans",
      ],
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.2,
              //   opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: ["#888", "#333"],
              fontSize: "13px",
            },
            value: {
              formatter: function (val: string) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  };
  return (
    <div className="chartCircularContainer_">
      <div className="box11">
        <div className="header_chart">
          <div className="left">
            <span className="s_tt">Statistiques élèves</span>
            {/* <span className="s_tt">Vente/commande</span> */}
          </div>
          {/* <div className="count">
            {55}/{2}
          </div> */}
        </div>
        <div className="footer">
          <span className="progress">Nbre fille</span>{" "}
          <span className="max_progresson">
            {girls.length} / {boys.length + girls.length}
          </span>
        </div>
        <LinearProgress
          variant="determinate"
          value={
            boys.length + girls.length > 0
              ? (girls.length * 0.01) / (boys.length + girls.length)
              : 0
          }
          sx={{ height: 5, borderRadius: 1 }}
        />
        <div className="footer">
          <span className="progress">Nbre garcon</span>{" "}
          <span className="max_progresson">
            {boys.length} / {boys.length + girls.length}
          </span>
        </div>
        <LinearProgress
          variant="determinate"
          value={
            boys.length + girls.length > 0
              ? (boys.length * 0.01) / (boys.length + girls.length)
              : 0
          }
          sx={{ height: 5, borderRadius: 1 }}
        />
      </div>
      <div className="progression">
        <div className="progress">
          <span className="croiss">Statistique par tranche d'age</span>
        </div>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="donut"
          height={250}
          width={250}
        />
      </div>
    </div>
  );
};

export default CircularChartPaimentPerClass;
