import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ConnexionError from "../component/nodata/ConnexionError";
import { EleveType } from "../types/GlobalType";

type Props = {
  searchByClasse: string;
  eleveSearch: string;
  data: EleveType[];
};

const ListElevesPaiement = (props: Props) => {
  const SK_COUNT = 8;

  const skeleton = (
    <tr style={{ borderBottom: "1px solid #dad9da" }}>
      <td>
        <Skeleton
          variant="circular"
          width={25}
          height={25}
          sx={{ ml: "10px" }}
        />
      </td>
      <td>
        <Skeleton
          variant="rounded"
          width={100}
          height={25}
          sx={{ ml: "35px" }}
        />
      </td>
      <td>
        <Skeleton
          variant="rounded"
          width={150}
          height={25}
          sx={{ ml: "35px" }}
        />
      </td>
      <td>
        <Skeleton
          variant="rounded"
          width={100}
          height={25}
          sx={{ ml: "35px" }}
        />
      </td>
      <td>
        <Skeleton
          variant="rounded"
          width={100}
          height={25}
          sx={{ ml: "35px" }}
        />
      </td>
      <td>
        <Skeleton
          variant="rounded"
          width={100}
          height={25}
          sx={{ ml: "35px" }}
        />
      </td>
      {/* <td>
        <Skeleton
          variant="rounded"
          width={100}
          height={25}
          sx={{ ml: "35px" }}
        />
      </td> */}
    </tr>
  );

  return (
    <>
      <table style={{ borderCollapse: "collapse" }}>
        <thead style={{ backgroundColor: "#EEEEFF", height: "2.5rem" }}>
          <tr style={{ fontSize: 13 }}>
            <th>Num</th>
            <th>Nom</th>
            <th>Post-nom</th>
            <th>Prénom</th>
            <th>Matricule</th>
            <th>Classe</th>
            <th></th>
          </tr>
        </thead>

        {props.data.length === 0 ? (
          <tbody>{Array(SK_COUNT).fill(skeleton)}</tbody>
        ) : (
          <tbody>
            {props.data.map((value, index) => {
              //   const { id_card, noms, designation, telephone } = eleve;
              return (
                <tr className="tabRow" key={value.eleve_id}>
                  <td>{`0${index + 1}`}</td>
                  <td>{value.nom}</td>
                  <td>{value.postnom}</td>
                  <td>{value.prenom}</td>
                  <td>{value.eleve_uid}</td>
                  <td>{value.classe_designation}</td>
                  <td>
                    <Link
                      to={`/home/paiement/eleve/${value.eleve_id}`}
                      state={value}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          cursor: "pointer",
                          padding: "2px 3px",
                          backgroundColor: "#96C0EA",
                          borderRadius: "3px",
                          fontSize: "12px",
                        }}
                      >
                        Détail
                      </Typography>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
};

export default ListElevesPaiement;
