import React from 'react';
import Chart from "react-apexcharts";

const GeneralPaiementChart = ({ nothingPaidNum, advancePaidNum, finishPaidNum, selectedClasse }) => {

    // ---------------------------------------------
    //                     PI CHART
    // ---------------------------------------------
    const series = [finishPaidNum, advancePaidNum, nothingPaidNum];
    const options = {
        labels: [`Elèves en ordre ${finishPaidNum}`, `Elèves ayant avance ${advancePaidNum}`, `Elèves n'ayant rien payé ${nothingPaidNum}`],
        title: {
            text: (finishPaidNum === 0 && advancePaidNum === 0 && nothingPaidNum === 0) ? "Aucun paiement" : selectedClasse,
            style: {
                color: 'red',
            }
        },
        noData: {
            text: "There's no data",
        }
    }

    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="pie"
                width="450"
            // height="450"

            />
        </div>
    )
}

export default GeneralPaiementChart;