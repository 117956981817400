import React, { useReducer, useState } from "react";
import "./scolarite.scss";
import Delete from "../../../component/dialog/delete/Delete.tsx";
import UpdateScolarite from "./UpdateSchoolYear.tsx";
import UpdateClass from "./UpdateClass.tsx";
import toast from "react-hot-toast";
import { requestType, schooYearType } from "../../../types/GlobalType.ts";
import { API_CONFIG } from "../../../apiCaller/configuration.Caller.js";
import { accountService } from "../../../_services/accountServices.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../../component/loader/Loader.tsx";

const Scolarite = () => {
  const [yearData, setyearData] = useState("");
  const [dataSY, setDataSY] = useState<schooYearType[]>([]);
  const [singleDataSY, setSingleDataSY] = useState<schooYearType>();

  const [dataCL, setDataCL] = useState<schooYearType[]>([]);
  const [itemId, setItemId] = useState<string | number>();
  const [stateReload, dispatchReload] = useReducer((x) => x + 1, 0);

  const [isValid, setIsValid] = useState<boolean>();
  const [openDeleteYear, setOpenDeleteYear] = React.useState<Boolean>(false);
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  const [openUpdateSchoolYear, setopenUpdateSchoolYear] =
    React.useState<boolean>(false);
  const [openUpdateClass, setopenUpdateClass] = React.useState<boolean>(false);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: false,
    successMessage: false,
  });

  const navigate = useNavigate();

  const regex = /^\d{4}-\d{4}$/;

  const handleChange = (event) => {
    const value = event.target.value;
    setyearData(value);
  };

  const handleSubmit = async () => {
    if (yearData.toString().length < 1) {
      setState({
        isLoading: false,
        errorMessage: "Veuiller complétez l'année",
      });
      return toast.error("Veuiller complétez l'année");
    }

    // Check if the format is valid
    if (regex.test(yearData)) {
      const [firstYear, secondYear] = yearData.split("-").map(Number);
      // Check if the second year is greater than the first
      setIsValid(secondYear > firstYear);
    } else {
      setIsValid(false);
      return toast.error("Année scolaire entrée est invalide");
    }

    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_CONFIG.ANNEE_SCOLAIRE.create({ designation: yearData }, token)
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          toast.success("Année ajoutée avec succes", { duration: 3000 });
          setState({
            isLoading: false,
          });
          dispatchReload();
        } else {
          setState({
            isLoading: false,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard88888888"
          );
        }
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };
  const fetchAllSY = async () => {
    setState({ isLoading: true, errorMessage: null, successMessage: null });

    let token = accountService.getToken();

    API_CONFIG.ANNEE_SCOLAIRE.getAll(token)
      .then((response) => {
        setState({
          isLoading: false,
          errorMessage: null,
          successMessage: null,
        });

        setDataSY(response.data.data);
      })
      .catch((err) => {
        setState({
          isLoading: false,
          successMessage: false,
          errorMessage: false,
        });

        if (err.code === "ERR_NETWORK") {
          setState({
            ...state,
            errorMessage: "Connexion impossible, réessayer plus tard",
          });
          return toast.error("Connexion impossible, réessayer plus tard");
        } else if (err.response.status === 401) {
          setState({
            ...state,
            errorMessage: "Identifiant ou mot de passe incorrect",
          });
          toast.error("Votre session a expiré, veuillez vous reconnectez");
          accountService.logout();
          navigate("/", { replace: true });
        } else if (err.response.data.status === 406) {
          setState({
            ...state,
            errorMessage: "Adresse mail invalide",
          });
          return toast.error("Adresse mail invalide");
        } else {
          setState({
            ...state,
            errorMessage:
              "Une erreur inconnue est survenue, réessayer plus tard",
          });
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  const handleDeleteSY = (id: string | number | undefined) => {
    let token = accountService.getToken();
    API_CONFIG.ANNEE_SCOLAIRE.delete(id, token)
      .then((response) => {
        setLoadingDeleteBtn(false);
        setOpenDeleteYear(false);
        dispatchReload();
        toast.success("Suppression effectuée avec succes");
      })
      .catch((error) => {
        setLoadingDeleteBtn(false);
        // console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            return navigate("/");
          }
        } else {
          return toast.error(
            "Une erreur inconnue est survenue, réessayer plus tard"
          );
        }
      });
  };

  React.useEffect(() => {
    fetchAllSY();
  }, [stateReload]);

  return (
    <>
      <Loader isLoading={state.isLoading} />
      <div className="topScolarite">
        <div className="card_Year">
          <h2 className="card-title">Année scolaire</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Ex: 2014-2015"
              className="input-field"
              value={yearData}
              onChange={handleChange}
            />
            <button className="add-button" onClick={handleSubmit}>
              {"Ajouter"}
            </button>
          </div>
          <ul className="item-list">
            {dataSY.map((value, index) => {
              return (
                <li key={index}>
                  <span>{value.annee_scolaire_designation}</span>
                  <div className="actionSection">
                    <span
                      className="edit"
                      onClick={() => {
                        setSingleDataSY(value);
                        setopenUpdateSchoolYear(true);
                      }}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => {
                        setItemId(value.annee_scolaire_id);
                        setOpenDeleteYear(true);
                      }}
                    >
                      Sup
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="card_Classe">
          <h2 className="card-title">Classes</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Désignation"
              className="input-field"
            />
            <input type="text" placeholder="Code" className="input-field" />
            <button className="add-button">Ajouter</button>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Designation</th>
                  <th>Code</th>
                  <th className="actionHeader">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2è Scientifique A</td>
                  <td>2 SC/A</td>
                  <td className="actionSection">
                    <span
                      className="edit"
                      onClick={() => setopenUpdateClass(true)}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => setopenDeleteArchive(true)}
                    >
                      Archiver
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>2è Scientifique A</td>
                  <td>2 SC/A</td>
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span
                      className="archive"
                      onClick={() => setopenDeleteArchive(true)}
                    >
                      Archiver
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openUpdateSchoolYear && (
        <UpdateScolarite
          setOpen={setopenUpdateSchoolYear}
          data={singleDataSY}
          dispatchReload={dispatchReload}
        />
      )}
      {openUpdateClass && <UpdateClass setOpen={setopenUpdateClass} />}

      {openDeleteYear && (
        <Delete
          setOpen={setOpenDeleteYear}
          itemId={itemId}
          label={"Voulez-vous vraiment supprimer cette année?"}
          handleDelete={handleDeleteSY}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={itemId}
          label={"Voulez-vous vraiment achiver cette classe?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default Scolarite;
