// import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NoData from '../nodata/NoData';
import { Skeleton } from '@mui/material';
import HeaderPrint from '../headerPrint/HeaderPrint';
import './depense.css'

// const useStyles = {
//     table: {
//         // minWidth: 750,
//     },
// };

const sk_count = 6;


const skeleton = (
    <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
            {
                Array(sk_count).fill(
                    <TableRow key={Math.random()}>
                        <TableCell component="th" scope="row" style={{ fontSize: "12px", paddingLeft: "25px" }}>
                            <Skeleton variant="circular" width={25} height={25} />
                        </TableCell>
                        <TableCell style={{ fontSize: "12px", paddingRight: "40px", textAlign: "right" }}>
                            <Skeleton variant="rounded" width={100} height={25} />
                        </TableCell>
                        <TableCell style={{ fontSize: "12px", paddingRight: "40px", textAlign: "right" }}>
                            <Skeleton variant="rounded" width={100} height={25} />
                        </TableCell>
                        <TableCell style={{ fontSize: "12px", paddingRight: "40px", textAlign: "right" }}>
                            <Skeleton variant="rounded" width={100} height={25} />
                        </TableCell>
                        <TableCell style={{ fontSize: "12px", paddingRight: "40px", textAlign: "right" }}>
                            <Skeleton variant="rounded" width={100} height={25} />
                        </TableCell>
                        <TableCell style={{ fontSize: "12px", paddingRight: "40px", textAlign: "right" }}>
                            <Skeleton variant="rounded" width={100} height={25} />
                        </TableCell>
                    </TableRow>
                )
            }
        </Table>
    </TableContainer>
)

export default function DepenseList({ data, printRef, isLoading }) {

    // console.log("DEPENSE LIST")
    // console.log(data)
    return (
        <div>

            {isLoading && (skeleton)}

            {(!isLoading && data.length > 0)
                &&
                <TableContainer component={Paper} ref={printRef}>
                    <HeaderPrint title={'ETPM - DEPENSES EFFECTUEES'} />
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='num'>NUM</TableCell>
                                <TableCell className='date'>DATE</TableCell>
                                <TableCell className='heure'>HEURE</TableCell>
                                <TableCell className='type' style={{ textAlign: 'center' }}>TYPE DE DEPENSE</TableCell>
                                <TableCell className='montant'>MONTANT</TableCell>
                                <TableCell className='auteur'>AUTEUR</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {data.map((item, index) => {

                                // console.log(item)

                                const { noms, heure, jour, type, montant } = item
                                return (
                                    <TableRow key={heure + index} className={(index % 2) === 0 ? "" : "print_row_color"}>
                                        <TableCell component="th" scope="row" style={{ fontSize: "12px", paddingLeft: "25px" }}>{`0${index + 1}`}</TableCell>
                                        <TableCell className='jour'>{jour}</TableCell>
                                        <TableCell className='heure'>{heure}</TableCell>
                                        <TableCell className='type' style={{ textAlign: 'center' }}>{type}</TableCell>
                                        <TableCell className='montant'>{montant}</TableCell>
                                        <TableCell className='auteur'>{noms}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {(!isLoading && data.length === 0) &&

                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">

                        <NoData />

                    </Table>
                </TableContainer>
            }
        </div>
    )
}
