import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PREFIX } from '../../App.constants';


const useStyles = {
    table: {
        minWidth: 450,
        minHeight: "150px",
    },
};

export default function Tableau({ userData }) {

    return (
        <TableContainer component={Paper}>
            <Table style={useStyles.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow >
                        <TableCell>ID</TableCell>
                        <TableCell align="left">IDENTIFIANT</TableCell>
                        <TableCell align="left">MATRICULE</TableCell>
                        <TableCell align="left">ATTRIBUTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        userData.filter((items) =>
                        (
                            items.noms !== 'Esdras Wite'
                            && items.noms !== 'Eric Chim S20'
                            && items.noms !== 'Danny Katoro'
                            && items.noms !== 'ITIG POS'
                            && items.noms !== 'POS Test'
                            && items.noms !== 'POS H5'
                            // localhost
                            // && items.noms !== 'esdras'
                            // && items.noms !== 'witeesdras'
                            // && items.noms !== 'GULAIN'
                            // && items.noms !== 'Manager 1'
                            // && items.noms !== 'Presence 1'
                            // && items.noms !== 'Eric CHIM'
                            // && items.noms !== 'witeesdras@gmail.com'
                            // && items.noms !== 'Elifeleti'
                            // && items.noms !== 'Eric Chim S20'
                            // && items.noms !== 'POS ITIG'
                            // && items.noms !== 'POS Test'
                            // && items.noms !== 'POS H5'
                            // && items.noms !== 'witeesdras@gmail.com'
                        ))
                            .map((data, index) => {

                                let { imeiagent, noms, isadmin, iscomptable, isddiscipline, isenseignant } = data;

                                var attrib = 'Enseignant';

                                if (isenseignant === 1) attrib = 'Enseignant';
                                if (iscomptable === 1) attrib = 'Comptable';
                                if (isddiscipline === 1) attrib = 'Dir. de discipline';
                                if (isadmin === 1) attrib = 'Administrateur';

                                return (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {`0${index + 1}`}
                                        </TableCell>
                                        <TableCell align="left">{noms}</TableCell>
                                        <TableCell align="left">{`Mat-${PREFIX.SCHOOL_NAME}${imeiagent}`}</TableCell>
                                        <TableCell align="left">{attrib}</TableCell>
                                    </TableRow>
                                )
                            })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
