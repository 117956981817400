import { Box, Stack } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Footer from "./component/footer/Footer";
import LeftSide from "./component/leftside/LeftSide";
import NavBar from "./component/navbar/NavBar.tsx";
import RetardEleve from "./pages/RetardEleve.tsx";
import RetardPersonnel from "./pages/RetardPersonnel.tsx";
import Finance from "./pages/Finance";

import RessourceList from "./component/ressources/ressource/RessourceList";
import RessourceListPublished from "./component/ressources/partage/RessourceList";
import RessourceListPublishedNot from "./component/ressources/partageNot/RessourceList";
import Paiement from "./pages/paiement/Paiement.tsx";
import { PaiementDetail } from "./pages/PaiementDetail.tsx";
import "./index.css";
import SideBar from "./pages/SideBar.tsx";
import NotFoundPage from "./pages/pagenotfound/NotFoundPage";
import Profil from "./pages/profil";
import Configuration from "./pages/configuration";
import Communique from "./pages/communique";
import "./assets/styles/darkStyle.scss";
import Ressource from "./pages/Ressource";
import { useEffect } from "react";
import Scolarite from "./pages/configuration/scolarite/index.tsx";
import Ponctualite from "./pages/configuration/ponctualite/index.tsx";
import FraisScolaire from "./pages/configuration/frais/index.tsx";
import Grade from "./pages/configuration/gradeFonction/index.tsx";
import Depense from "./pages/configuration/depense/index.tsx";
import Terminal from "./pages/configuration/terminal/index.tsx";
import CommuniqueConfig from "./pages/configuration/communique/index.tsx";
import Eleve from "./pages/configuration/eleve/index.tsx";
import Personnel from "./pages/configuration/personnel/index.tsx";
import EleveInfo from "./pages/configuration/eleve/singleInfo/EleveInfo.tsx";
import ParentInfo from "./pages/configuration/eleve/singleInfo/ParentInfo.tsx";
import PersonnelInfo from "./pages/configuration/personnel/singleInfo/PersonnelInfo.tsx";
import ParcoursInfo from "./pages/configuration/personnel/singleInfo/ParcoursInfo.tsx";

function Home() {
  const darkMode = false;

  useEffect(() => {
    // accountService.saveClasseIntoLocalStorage();
    // accountService.saveCoursIntoLocalStorage();
    // accountService.saveTeachersIntoLocalStorage();
  });

  return (
    <div className={darkMode ? "dark" : ""}>
      <Box className="d_body">
        <NavBar className="d_navBar" />
        <Stack
          direction="row"
          spacing={0}
          justifyContent=""
          sx={{ position: "relative" }}
        >
          <LeftSide />

          <Routes>
            <Route path="/" element={<SideBar />} />
            {/* <Route path='/login' element={<Login />} /> */}
            <Route path="/retard-eleve" element={<RetardEleve />} />
            <Route path="/retard-personnel" element={<RetardPersonnel />} />
            <Route path="/paiement/eleve/:id" element={<PaiementDetail />} />
            <Route path="/paiement" element={<Paiement />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/ressource" element={<Ressource />} />
            <Route path="/all-ressources" element={<RessourceList />} />
            <Route
              path="/published-ressources"
              element={<RessourceListPublished />}
            />
            <Route
              path="/no-puplished-ressources"
              element={<RessourceListPublishedNot />}
            />
            <Route path="/communique" element={<Communique />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/configuration" element={<Configuration />}>
              <Route path="configuration" element={<Scolarite />} />
              <Route path="ponctualite" element={<Ponctualite />} />
              <Route path="frais" element={<FraisScolaire />} />
              <Route path="grade-et-fonction" element={<Grade />} />
              <Route path="depense" element={<Depense />} />
              <Route path="terminal" element={<Terminal />} />
              <Route path="communique" element={<CommuniqueConfig />} />
            </Route>
            <Route path="/enregistrement-eleve" element={<Eleve />}>
              <Route path="enregistrement-eleve" element={<EleveInfo />} />
              <Route path="parent-info" element={<ParentInfo />} />
            </Route>
            <Route path="/enregistrement-personnel" element={<Personnel />}>
              <Route
                path="enregistrement-personnel"
                element={<PersonnelInfo />}
              />
              <Route path="parcours-info" element={<ParcoursInfo />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Stack>

        <Footer />
      </Box>
    </div>
  );
}

export default Home;
