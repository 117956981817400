import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  InputAdornment,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { Visibility } from "@mui/icons-material";
// import { accountService } from '../../_services/accountServices'
import axios from "axios";
import API from "../../App.constants";
import NotificationDialog from "../../assets/constant/NotificationDialog.tsx";

const AddCoursDiag = ({ open, setOpen }) => {
  const StyledIconTitle = styled("span")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    borderBottom: "1px solid #999",
    // backgroundColor: "#D1CFD3"
  });

  //input states
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  //handle error states
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  //succes dialog
  const [openSuccess, setOpenSuccess] = useState(false);
  const [succesMsg, setSuccesMsg] = useState("");
  const [textStyle, setTextStyle] = useState("");

  //password state for visibility
  const [oldPass, setOldPass] = useState("password");
  const [newPass, setNewPass] = useState("password");

  const [isLoading, setIsLoading] = useState(false);

  const userId = JSON.parse(localStorage.getItem("user")).id;

  const handleChangePsw = () => {
    setError(false);
    setErrorMsg("");

    if (oldPassword === "") {
      setError(true);
      return setErrorMsg("Veuiller compléter tous les champs");
    }

    if (newPassword === "") {
      setError(true);
      return setErrorMsg("Veuiller compléter tous les champs");
    }

    setIsLoading(true);

    axios
      .patch(`${API}/api/agent/update-password/${userId}`, {
        oldPassword,
        newPassword,
      })
      .then((response) => {
        setIsLoading(false);
        setOpen(false);

        //handle succed dialog
        setOpenSuccess(true);
        setSuccesMsg(response.data.message);
        setTextStyle("success");
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.code === "ERR_NETWORK") {
          console.log(error.code);
          setError(true);
          return setErrorMsg(
            "Echec de connexion, vérifier votre connexion internet"
          );
        }

        setError(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const handleVisibleOld = () => {
    if (oldPass === "password") {
      setOldPass("text");
    } else if (oldPass === "text") {
      setOldPass("password");
    }
  };

  const handleVisibleNew = () => {
    if (newPass === "password") {
      setNewPass("text");
    } else if (newPass === "text") {
      setNewPass("password");
    }
  };

  const handleError = (
    <Alert severity="error" sx={{ marginY: "5px" }}>
      <strong>{errorMsg}</strong>
    </Alert>
  );

  return (
    <>
      {isLoading && (
        <Dialog open={isLoading}>
          <DialogContent
            sx={{
              width: "200px",
              height: "130px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "skyblue" }} />
          </DialogContent>
        </Dialog>
      )}

      <Dialog maxWidth="md" sx={{ height: "550px", top: ".5rem" }} open={open}>
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItem: "center",
          }}
        >
          <StyledIconTitle>
            <Typography
              variant="h6"
              color="default"
              sx={{ fontWeight: "500", fontSize: 15, padding: "5px 0px" }}
            >
              Nouveau mot de passe
            </Typography>
          </StyledIconTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <DialogContentText>
              <div>
                {/* handle error */}
                {error && handleError}

                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <TextField
                      type={oldPass}
                      placeholder="Ancien mot de passe"
                      maxWidth={100}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Visibility
                              style={{ cursor: "pointer" }}
                              onClick={handleVisibleOld}
                            />
                          </InputAdornment>
                        ),
                      }}
                      name="oldPassword"
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div style={{ flex: 1 }}>
                    <TextField
                      type={newPass}
                      placeholder="Nouveau mot de passe"
                      required
                      maxWidth={100}
                      // error={errorMontant}
                      // helperText='La devise acceptée est le dollar (usd)'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Visibility
                              style={{ cursor: "pointer" }}
                              onClick={handleVisibleNew}
                            />
                          </InputAdornment>
                        ),
                      }}
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: "#008080", color: "#fff" }}
                    onClick={handleChangePsw}
                  >
                    <Typography variant="span" sx={{ fontWeight: "600" }}>
                      Modifier
                    </Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ color: "#008080" }}
                    onClick={() => {
                      setOpen(false);
                      setError(false);
                      setErrorMsg("");
                      setOldPassword((cur) => (cur = ""));
                      setNewPassword((cur) => (cur = ""));
                      setErrorMsg((cur) => (cur = ""));
                    }}
                  >
                    <Typography variant="span" sx={{ fontWeight: "600" }}>
                      Annuler
                    </Typography>
                  </Button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </DialogContent>
      </Dialog>

      <NotificationDialog
        openMsg={openSuccess}
        setOpenMsg={setOpenSuccess}
        message={succesMsg}
        textBtn="Ok"
        textStyle={textStyle}
      />
    </>
  );
};

export default AddCoursDiag;
