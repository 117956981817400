import React, { useState } from "react";
import "./profil.scss";
import {
  Box,
  Divider,
  Stack,
  Grid,
  Typography,
  Card,
  Skeleton,
} from "@mui/material";
import {
  AccountCircle,
  Add,
  AdminPanelSettingsOutlined,
  Apps,
  ArrowBackRounded,
  Man2Rounded,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import EditAgentDialog from "./EditAgentDialog";
import AddAgentDialog from "./AddAgentDialog";
import ListAgentDialog from "./ListAgentDialog";
import axios from "axios";
import API, { PREFIX } from "../../App.constants";
// import profil_img from '../../assets/images/avatar_2.png'
import ConnexionError from "../../component/nodata/ConnexionError";
import AdminMenus from "./adminMenu/AdminMenus";
import { ROLES } from "../../assets/constant";

const Profil = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [userData, setUserData] = useState([]);
  const [msg, setMsg] = useState({ type: "", text: "" });
  const [isLoading, setisLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [OpenAdminMenu, setOpenAdminMenu] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const users = {
    nom: user.username.replace(/"/g, ""),
    email: "------------",
    attribution: user.isadmin
      ? ROLES.ADMINISTRATEUR
      : user.iscomptable
      ? ROLES.COMPTABLE
      : user.isddiscipline
      ? ROLES.D_DISCIPLINE
      : user.isenseignant
      ? ROLES.ENSEIGNANT
      : "Agent",
    matricule: user.id,
  };

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  React.useEffect(() => {
    setisLoading(true);
    axios
      .get(`${API}/api/agent/all`, config)
      .then((response) => {
        setisLoading(false);
        setUserData(response.data.data);
      })
      .catch((err) => {
        // console.log("ERROR");
        // console.log(err);
        setisLoading(false);
        if (err.code === "ERR_NETWORK") {
          setMsg((prevState) => ({ ...prevState, type: "ERR_NETWORK" }));
        } else {
          setMsg((prevState) => ({
            ...prevState,
            type: "ERR_UNKNOWN",
            text: "Une erreur inconnue est survenue, réessayer plus tard",
          }));
          setTimeout(() => {
            setMsg({ type: "", text: "" });
            return;
          }, 3000);
        }
      });
  }, [reload]);

  const handleAdminMenu = () => {
    setOpenAdminMenu(true);
  };

  const skeleton = (
    <tr style={{ padding: "5px" }}>
      <td style={{ display: "flex", gap: "5px", padding: "10px" }}>
        <Skeleton variant="circular" width={25} height={25} />
        <span>
          <Skeleton variant="rounded" width={100} height={25} />
        </span>
      </td>
      <td>
        <span>
          <Skeleton variant="rounded" width={160} height={25} />
        </span>
      </td>
      <td>
        <span>
          <Skeleton variant="rounded" width={160} height={25} />
        </span>
      </td>
    </tr>
  );
  const bodyData = (
    <tbody>
      {userData
        .filter(
          (items) =>
            items.noms !== "Esdras Wite" &&
            items.noms !== "Eric Chim S20" &&
            items.imeiagent !== "0990119611" &&
            items.imeiagent !== "0973614801" &&
            items.noms !== "Danny Katoro" &&
            items.noms !== "ITIG POS" &&
            items.noms !== "POS Test" &&
            items.noms !== "POS H5"
        )
        .slice(0, 7)
        .map((value, index) => {
          const { noms, imeiagent, isadmin } = value;
          const attribution = isadmin ? "Admin" : "Agent";

          return (
            <tr key={index}>
              <td className="userName">
                <AdminPanelSettingsOutlined />
                <span>{noms}</span>
              </td>
              <td>
                <span className="itemAdminMail">{`Mat-${PREFIX.SCHOOL_NAME}${imeiagent}`}</span>
              </td>
              <td>
                <span className="itemFunction">{attribution}</span>
              </td>
            </tr>
          );
        })}
    </tbody>
  );

  return (
    <Box bgcolor="#eee" flex={5} p={1} sx={{ marginLeft: "200px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <div className="homeMenu">
          <Link to="/home" style={{ marginTop: "5px" }}>
            <ArrowBackRounded
              sx={{
                color: "#0091EA",
                backgroundColor: "#fff",
                borderRadius: "50%",
              }}
            />
          </Link>
          <div className="homeTitleItem">
            <span className="homeTitle"> Profil</span>
          </div>
          <div className="homeTitleItem">
            <Man2Rounded sx={{ color: "#1565C0" }} />
            <span className="homeTitleSmall">{users.attribution} </span>
          </div>
        </div>

        {user.isadmin === 1 ? (
          <div className="config">
            <Apps
              sx={{ color: "#777", fontSize: 25, cursor: "pointer" }}
              onClick={handleAdminMenu}
            />
          </div>
        ) : (
          ""
        )}
      </Stack>
      <Divider sx={{ marginTop: "5px" }} />

      <Box mt={3}>
        <Card
          sx={{
            position: "relative",
            mx: 0,
            mb: 2,
            py: 2,
            px: 5,
            backgroundColor: "white",
            color: "#0091EA",
          }}
        >
          <Stack
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Stack
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <AccountCircle className="profilImage" sx={{ fontSize: 45 }} />
              <Grid item>
                <Box height="100%" lineHeight={1} mt={-0.6}>
                  <Typography
                    variant="h5"
                    fontWeight="medium"
                    sx={{ fontSize: 18 }}
                  >
                    {users.nom}
                  </Typography>
                </Box>
              </Grid>
              <Typography
                variant="button"
                color="text"
                fontWeight="regular"
                sx={{ fontSize: 14 }}
              >
                ( {users.attribution} - {PREFIX.APP_NAME})
              </Typography>
            </Stack>

            {user.isadmin === 1 ? (
              <Grid item xs={12} md={6} lg={5} sx={{ ml: "auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "3px 6px",
                    gap: "5px",
                  }}
                >
                  <Add fontSize="small" sx={{ mt: -0.25, color: "#A3C8ED" }} />

                  <Typography
                    onClick={() => setOpen2(true)}
                    sx={{
                      color: "#A3C8ED",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "13px",
                    }}
                  >
                    Nouvel agent
                  </Typography>
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Stack>
        </Card>

        <div className="topProfile">
          <div className="left">
            <div className="editButton" onClick={() => setOpen(true)}>
              Modifier le mot de passe
            </div>
            <h1 className="titleProfile">Information</h1>
            <hr />
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">Nom:</span>
                  <span className="itemValue">{users.nom}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{users.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Matricule:</span>
                  {/* {`Mat-${PREFIX.SCHOOL_NAME}${{users.matricule}`} */}
                  {/* <span className="itemValue">Mat- {users.matricule}</span> */}
                  <span className="itemValue">{`Mat-${PREFIX.SCHOOL_NAME} ${users.matricule}`}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Attribution:</span>
                  <span className="itemValue">{users.attribution}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="right">
            <div className="rightHeader">
              <span className="titleAdmin">Agents</span>
              <div className="headerLink" onClick={() => setOpen3(true)}>
                Voir plus
              </div>
            </div>
            <div className="itemContainer">
              <table>
                <tr>
                  <th>Identifiant</th>
                  <th>Matricule</th>
                  <th>attribution</th>
                </tr>
                {isLoading && (
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {Array(5).fill(skeleton)}
                  </tbody>
                )}
                {!isLoading &&
                  (msg.type === "ERR_NETWORK" ? (
                    <ConnexionError colSpan={3} padding="30px 0px" />
                  ) : (
                    bodyData
                  ))}
              </table>
            </div>
          </div>

          <EditAgentDialog open={open} setOpen={setOpen} />
          <AddAgentDialog
            open2={open2}
            setOpen2={setOpen2}
            setReload={setReload}
          />
          <ListAgentDialog
            open3={open3}
            setOpen3={setOpen3}
            userData={userData}
          />
          <AdminMenus open={OpenAdminMenu} setOpen={setOpenAdminMenu} />
        </div>
      </Box>
    </Box>
  );
};

export default Profil;
