import React from "react";
import "./communique.scss";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Delete from "../../../component/dialog/delete/Delete.tsx";
import UpdateDestinateur from "./UpdateDestinateur.tsx";

const CommuniqueConfig = () => {
  const [openDeleteArchive, setopenDeleteArchive] =
    React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  const [openUpdateDest, setopenUpdateDest] = React.useState<boolean>(false);

  return (
    <>
      <div className="topCommunique">
        <div className="card_Classe">
          <h2 className="card-title">Type de destinateur</h2>
          <div className="input-container">
            <input
              type="text"
              placeholder="Désignation"
              className="input-field"
            />
            {/* <input type="text" placeholder="Code" className="input-field" /> */}
            <button className="add-button">Ajouter</button>
          </div>
          <div className="table_component" role="region">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Désignation</th>
                  <th className="headerSection">Status</th>
                  <th className="actionHeader">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Parents</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Activé" placement="left">
                        <CheckCircle
                          style={{ color: "#28a745", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td className="actionSection">
                    <span
                      className="edit"
                      onClick={() => setopenUpdateDest(true)}
                    >
                      Edit
                    </span>
                    <span
                      className="archive"
                      onClick={() => setopenDeleteArchive(true)}
                    >
                      Archiver
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Enseignants</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Archivé" placement="left">
                        <CancelRounded
                          style={{ color: "#7f1d18", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span className="archive">Archiver</span>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Enseignants-Parents</td>
                  <td className="statusSection">
                    <span>
                      <Tooltip title="Activé" placement="left">
                        <CheckCircle
                          style={{ color: "#28a745", fontSize: "14px" }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td className="actionSection">
                    <span className="edit">Edit</span>
                    <span className="archive">Archiver</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openUpdateDest && <UpdateDestinateur setOpen={setopenUpdateDest} />}
      {openDeleteArchive && (
        <Delete
          setOpen={setopenDeleteArchive}
          itemId={"id"}
          label={"Voulez-vous vraiment achiver ce destinateur?"}
          handleDelete={() => {}}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default CommuniqueConfig;
