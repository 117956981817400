import React, { useState } from 'react'

import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment,
    Button, Stack, Alert, AlertTitle
} from '@mui/material'



const SucessMessageDialog = ({ title, text, severity }) => {

    return (

        <Dialog open={true} >
            <DialogContent>
                <DialogContentText>
                    <Stack>
                        <Alert severity={severity}>
                            <AlertTitle><strong>{title}</strong></AlertTitle>
                            {text}
                        </Alert>
                    </Stack>
                </DialogContentText>
            </DialogContent>

        </Dialog>
    )
}

export default SucessMessageDialog