import { Paper, Typography } from '@mui/material'
import React, { Fragment } from 'react'
const Footer = () => {
  return (
    <Fragment>
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          position: 'fixed',
          bottom: 0,
          zIndex: 99,
          height: '2rem',
          width: '100%',
          marginTop: "50px"
        }}>
        <Typography variant='span' sx={{ fontSize: 13, padding: "1rem 3rem", fontWeight: 400, color: "rgb(170, 170, 170);" }}> Application designed By Ehula-Technologies</Typography>
      </Paper>
    </Fragment>

  )
}


export default Footer;
