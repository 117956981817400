import toast from "react-hot-toast";

//old version
const API = "127.0.0.1/api-chiksoft";
// const API = "http://localhost:8600";
// const API = "https://api-chiksoft.buzconapp.com";

export const URL_BACKEND_APP = "https://api.chicksoft.50webs.org";
// export const URL_BACKEND_APP = "http://localhost/api-chiksoft";
export const PREFIX = {
  SCHOOL_NAME: "ETPM",
  APP_NAME: "CHIK SOFT",
  APP_VERSION: "1.0.0",
};

export const NOTIFY = {
  success: (msg) => toast.success(msg),
  error: (msg) => toast.error(msg),
  dismiss: (msg) => toast.dismiss(msg),
  loading: (msg) => toast.loading(msg),
};

export default API;
