import React, { useEffect, useState } from 'react'
import './ressourceList.scss'
import Header from '../../headerPage/Header'
import { Box, Divider } from '@mui/material'
import { ContentCopyOutlined, Edit, Visibility } from '@mui/icons-material'
import DisplayPage from '../single/Display'
import EditPage from '../single/Edit'
import axios from 'axios'
import API from '../../../App.constants'


const RessourceList = () => {

  const [openDisplay, setopenDisplay] = useState(false)
  const [openEdit, setopenEdit] = useState(false)
  const [data, setData] = useState([])
  const [resId, setResId] = useState()
  const [resItem, setResItem] = useState({})
  const [reload, setReload] = useState(false)


  const icon = (<ContentCopyOutlined sx={{ color: "#1565C0" }} />)


  //function that allow us to get one ressource
  const handleShowSigle = (event, value) => {
    console.log("SINGLE")
    setResId(value.id)
    setResItem(value)

  }

  useEffect(() => {
    const config = {
      "Content-Type": "application/json"
    }
    axios.get(`${API}/api/ressource/getallpublished`, config)
      .then(res => {
        console.log(res.data.data)
        setData(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [reload])

  return (
    <Box bgcolor="#eee" flex={5} p={1} sx={{ marginLeft: "200px" }}>
      <Header backTo='/home/ressource' primaryTitle='Ressources' secondaryTitle='List des ressources - Partagées' icon={icon} />
      <Divider sx={{ marginTop: "8px" }} />
      <div className="tableContent">
        <h5>Liste</h5>
        <div className="List">
          <table className="tab_header">
            <thead>
              <tr>
                <th width={30}>#</th>
                <th width={150}>Titre</th>
                <th width={100}>Type</th>
                <th width={100}>Classe</th>
                <th width={100}>Création</th>
                <th width={100}>Publication</th>
                <th width={100}>Status</th>
                <th width={100} align='center'>Action</th>
              </tr>
            </thead>
            <tbody>

              {data.map((value, index) => {

                const { titre, type, classe, date_creation, date_publication } = value

                return (
                  <tr key={value.id}>
                    <td>{`0${index + 1}`}</td>
                    <td>{titre.length < 20 ? (titre) : (`${titre.slice(0, 15)}...`)}</td>
                    <td>{type}</td>
                    <td>{classe}</td>
                    <td>{date_creation}</td>
                    <td>{date_publication}</td>
                    <td>Publiée</td>
                    <td>
                      <div className="actionBtn">
                        <span className='display' onClick={(event) => { handleShowSigle(event, value); setopenDisplay(true) }} >voir<Visibility sx={{ fontSize: 14, color: '#19799e', mt: '2px' }} /></span>
                        <span className='edit' onClick={(event) => { handleShowSigle(event, value); setopenEdit(true) }}>edit<Edit sx={{ fontSize: 14 }} /></span>
                      </div>
                    </td>
                  </tr>)
              })}
            </tbody>
          </table>

        </div>
      </div>

      <DisplayPage open={openDisplay} setOpen={setopenDisplay} singleRes={resItem} setSingleRes={"setSingleRes"} setReloadPage={setReload} />
      <EditPage open={openEdit} setOpen={setopenEdit} data={""} resId={resId} valueData={resItem} setReloadPage={setReload} />

    </Box >
  )
}

export default RessourceList
