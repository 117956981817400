import { ArrowBackRounded, ChromeReaderModeRounded } from "@mui/icons-material";
import { Stack, Box, Fab, Divider } from "@mui/material";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import GeneralPaiementChart from "../component/chart/GeneralPaiementChart";
import AnnualChart from "../component/chart/AnnualFinChart.js";
import Depense from "../component/dialog/Depense";
import FinancePaiementTrimesterPaid from "../component/dialog/Finance_PaiementTrimester_Paid";
import FinancePaiementTrimesterUnpaid from "../component/dialog/Finance_PaiementTrimester_Unpaid";
import axios from "axios";
import API from "../App.constants.js";
import { Link } from "react-router-dom";
import { accountService } from "../_services/accountServices";

const Finance = () => {
  const defaultClaseObj = JSON.parse(localStorage.getItem("classes"))[0];
  const defaultClase = defaultClaseObj.designation;

  const config = useMemo(() => {
    return {
      headers: {
        // 'Content-type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }, []);
  // --------------------------------------------------------------------------
  const [open_1, setOpen_1] = useState(false);
  const [open_2, setOpen_2] = useState(false);
  const [open_3, setOpen_3] = useState(false);

  const [paidNumber, setPaidNumber] = useState(0);
  const [unPaidNumber, setUnPaidNumber] = useState(0);
  const [paidList, setPaidList] = useState([]);
  const [unPaidList, setUnPaidList] = useState([]);
  const [somme, setSomme] = useState(0);

  //handle graphic trimestrial paiement by classe (nbre et list des eleves par classe-paiement semestriel)
  const [selectedSemester, setSelectedSemester] = useState("0, 0.51"); //intial seuil 0-90 pour le premier semestre au premier RENDER

  const [selectedClasse, setSelectedClasse] = useState(defaultClase);
  const [nothingPaidNum, setNothingPaidNum] = useState(0);
  const [advancePaidNum, setAdvancePaidNum] = useState(0);
  const [finishPaidNum, setFinishPaidNum] = useState(0);
  // const [nothingPaidList, setNothingPaidList] = useState([])
  // const [advancePaidList, setAdvancePaidList] = useState([])
  // const [finishPaidList, setFinishPaidList] = useState([])

  //default state first semester paiement amount (Affichache pour toute l'ecole)
  const [seuil, setSeuil] = useState(0.51);
  const [trancheName, setTrancheName] = useState("Première tranche");

  // state that handle trimester stack trigger
  const [showT1, setShowT1] = useState(true);
  const [showT2, setShowT2] = useState(false);
  const [showT3, setShowT3] = useState(false);

  // functions that handle trimester stack trigger topLeft side
  const handleT1 = () => {
    setSeuil(0.51);
    setTrancheName("Première tranche");
    setShowT1(true);
    setShowT2(false);
    setShowT3(false);
  };
  const handleT2 = () => {
    setSeuil(0.89);
    setTrancheName("Deuxième tranche");
    setShowT1(false);
    setShowT2(true);
    setShowT3(false);
  };
  const handleT3 = () => {
    setSeuil(1);
    setTrancheName("Troisième tranche");
    setShowT1(false);
    setShowT2(false);
    setShowT3(true);
  };

  const classes = accountService.getClasseList();

  const classeCopy = [...classes];
  classeCopy.shift();

  const handleAllPaiemenet = useCallback(() => {
    var listPaid = [];
    var listUnPaid = [];

    // var numberPaid = 0;
    // var numberUnPaid = 0;

    let i = 1;
    // console.log(classes.length)
    for (i; i < classes.length; i++) {
      let classe = classes[i].designation;
      // console.log("LIST ALL SCHOOL" + i)
      // console.log(classe, i)
      axios
        .get(
          `${API}/api/finance/compte/compte?percent_tranche=${seuil}&classe=${classe}`,
          config
        )
        .then((response) => {
          // console.log(response)

          //listPaid
          var tab1 = response.data.data[0].data;
          for (let value of tab1) {
            listPaid.push(value);
          }

          //listUnPaid
          var tab2 = response.data.data[1].data;
          tab2.forEach((element) => {
            listUnPaid.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
          // console.log(classes.length)
        });
    }

    // numberPaid = listPaid.length;
    // numberUnPaid = listUnPaid.length;

    setTimeout(() => {
      setPaidList(listPaid);
      setUnPaidList(listUnPaid);
      setPaidNumber(listPaid.length);
      setUnPaidNumber(listUnPaid.length);

      // console.log(listPaid.length)
      // console.log(listPaid)
      // console.log(listUnPaid.length)
      // console.log(listUnPaid)
    }, 2000);
  }, [seuil, config, classes]);

  useEffect(() => {
    //fonction that handle all classe
    handleAllPaiemenet();

    let cl = selectedClasse;
    let min = Number(selectedSemester.split(",")[0]);
    let max = Number(selectedSemester.split(",")[1]);

    // let axiosRequest1 = axios.get(`${API}/api/finance/compte?montant=${seuil}`, config);

    // trois tranches entre 0---51%---89%---100%
    // ---------------------0---0.51---0.89----1
    // ---------------------a----b
    // --------------------------a-------b
    // ---------------------------------a-----b

    let axiosRequest2 = axios.get(
      `${API}/api/finance/eleve/amount/amount?classe=${cl}&percent_min=${min}&percent_max=${max}`,
      config
    ); //
    let axiosRequest3 = axios.get(
      `${API}/api/finance/all-depense/somme`,
      config
    );

    let isCancelled = false;

    // axios.all([axiosRequest1, axiosRequest2, axiosRequest3])
    axios
      .all([axiosRequest2, axiosRequest3])
      .then(
        axios.spread(function (response2, response3) {
          if (!isCancelled) {
            setNothingPaidNum(response2.data.data[0].number);
            setAdvancePaidNum(response2.data.data[1].number);
            setFinishPaidNum(response2.data.data[2].number);
            // setNothingPaidList(response2.data.data[0].data)
            // setAdvancePaidList(response2.data.data[1].data)
            // setFinishPaidList(response2.data.data[2].data)

            //set of seconde response 3
            setSomme(response3.data.data[0].sommeDepense);
          }
        })
      )
      .catch((errorr) => {
        console.log(errorr);
      });

    return () => {
      // console.log("Fetch data cancellled, in useEffect")
      isCancelled = true;
    };
  }, [seuil, selectedSemester, selectedClasse, open_1, config]);

  const handleClick_Depense = () => {
    setOpen_1(true);
  };

  const handleClick_PaiementTrimestriel_1 = () => {
    setOpen_2(true);
  };

  const handleClick_PaiementTrimestriel_2 = () => {
    setOpen_3(true);
  };

  //[0-51%]=[percent_min, percent_max]=>tranche1
  //[51-89%]=[percent_min, percent_max]=>tranche2
  //[89-100%]=[percent_min, percent_max]=>tranche3
  const semestre = [
    { name: "tranche 1 (51%)", nb_min: 0, nb_max: 0.51 },
    { name: "tranche 2 (38%)", nb_min: 0.51, nb_max: 0.89 },
    { name: "tranche 3 (11%)", nb_min: 0.89, nb_max: 1 },
  ];

  //handle semester
  const handleOnChangeSelectSemester = (e) => {
    setSelectedSemester(e.target.value);
  };

  const handleOnchangeClasse = (e) => {
    setSelectedClasse(e.target.value);
  };

  return (
    <Box bgcolor="#eee" flex={5} p={1} sx={{ marginLeft: "200px" }}>
      {/* ------------------PAGE TITLE-------------------- */}

      <Stack direction="row" justifyContent="space-between">
        <div className="homeMenu">
          <Link to="/home" style={{ marginTop: "5px" }}>
            <ArrowBackRounded
              sx={{
                color: "#0091EA",
                backgroundColor: "#fff",
                borderRadius: "50%",
              }}
            />
          </Link>
          <div className="homeTitleItem">
            <span className="homeTitle"> Finance</span>
          </div>
          <div className="homeTitleItem2">
            <ChromeReaderModeRounded sx={{ color: "#1565C0" }} />
            <span className="homeTitleSmall">
              Sythèse du rapport financier{" "}
            </span>
          </div>
        </div>
        <div className="rightBtn">
          <span className="spanLabel">Frais scolaire:</span>
          <span
            className={showT1 === true ? "spanBtnClicked" : "spanBtn"}
            onClick={handleT1}
          >
            50 %
          </span>
          <span
            className={showT2 === true ? "spanBtnClicked" : "spanBtn"}
            onClick={handleT2}
          >
            75 %
          </span>
          <div
            className={showT3 === true ? "spanBtnClicked" : "spanBtn"}
            onClick={handleT3}
          >
            <span>100 %</span>
          </div>
        </div>
      </Stack>
      <Divider sx={{ marginTop: "8px" }} />

      <div className="dashboarContainer">
        <div className="dashbordItem">
          <div className="dashbordItemUp">
            <div className="dashDesc">
              <div className="descTitle1">Dépenses</div>
              <div className="descTitle2">
                Dépenses effectuées - année en cours
              </div>
            </div>
            <div className="dashIcon">
              <Fab color="default" aria-label="add">
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#1565C0",
                  }}
                >
                  {somme}
                </span>
              </Fab>
            </div>
          </div>
          <div className="dashbordItemDown" onClick={handleClick_Depense}>
            Ouvrir dépense
          </div>
        </div>

        <div className="dashbordItem">
          <div className="dashbordItemUp">
            <div className="dashDesc">
              <div className="descTitle1" onClick={handleAllPaiemenet}>
                {trancheName}
              </div>
              <div className="descTitle2">Nombre total d'élèves en ordre</div>
            </div>
            <div className="dashIcon">
              <Fab color="success" aria-label="add">
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  {paidNumber}
                </span>
              </Fab>
            </div>
          </div>
          <div
            className="dashbordItemDown"
            onClick={handleClick_PaiementTrimestriel_1}
          >
            Ouvrir la liste{" "}
          </div>
        </div>

        <div className="dashbordItem">
          <div className="dashbordItemUp">
            <div className="dashDesc">
              <div className="descTitle1">{trancheName}</div>
              <div className="descTitle2">
                Nombre total d'élèves non en ordre
              </div>
            </div>
            <div className="dashIcon">
              <Fab color="error" aria-label="add">
                {/* <span style={{ fontSize: "1rem", fontWeight: "600" }}>{myData.num_inf[0].nombreeleve}</span> */}
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  {unPaidNumber}
                </span>
              </Fab>
            </div>
          </div>
          <div
            className="dashbordItemDown"
            onClick={handleClick_PaiementTrimestriel_2}
          >
            Ouvrir la liste{" "}
          </div>
        </div>
      </div>
      <div className="graphicContainer">
        {/* <div className="graphicItem">
          <h6 style={{ color: "#888" }}>Evolution des paiements</h6>

          <AnnualChart />
        </div> */}
        <div className="graphicItem">
          <div className="graphicItemOption">
            <h6 style={{ paddingLeft: "20px" }}>Paiement par classe</h6>
            <div className="seclectContainer">
              <select
                defaultValue={selectedClasse}
                onChange={handleOnchangeClasse}
              >
                {classeCopy.map((value, index) => {
                  return (
                    <option key={index} value={value.designation}>
                      {value.designation.toUpperCase()}
                    </option>
                  );
                })}
              </select>
              <select
                defaultValue={selectedSemester}
                onChange={handleOnChangeSelectSemester}
              >
                {semestre.map((item, index) => {
                  return (
                    <option key={index} value={[item.nb_min, item.nb_max]}>
                      {" "}
                      {item.name.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <GeneralPaiementChart
            nothingPaidNum={nothingPaidNum}
            advancePaidNum={advancePaidNum}
            finishPaidNum={finishPaidNum}
            selectedClasse={selectedClasse}
          />
        </div>
      </div>

      {/* Modal showing daily payement */}

      <Depense open={open_1} setOpen={setOpen_1} />

      {/* Modal showing daily payement */}

      <FinancePaiementTrimesterPaid
        open={open_2}
        setOpen={setOpen_2}
        paidNumber={paidNumber}
        paidList={paidList}
      />

      {/* Modal showing daily payement */}

      <FinancePaiementTrimesterUnpaid
        open={open_3}
        setOpen={setOpen_3}
        unPaidNumber={unPaidNumber}
        unPaidList={unPaidList}
      />
    </Box>
  );
};

export default Finance;
