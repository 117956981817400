import React from 'react';
import '../assets/styles/printpage.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SimpleBackdrop from '../component/loader/CircularBackdrop';
import HeaderPrint from '../component/headerPrint/HeaderPrint';

const useStyles = {
    table: {
        minWidth: 750,
    },
};

export default function TableFinanceTrimesterHistoriqueUnpaid({ unPaidList, searchByClasse, eleveSearch, printRef }) {


    return (
        <TableContainer component={Paper} >
            <Table style={useStyles.table} size="small" aria-label="a dense table" ref={printRef}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <HeaderPrint title={'ETPM - Catalogue semestrielle des élèves irréguliers'} />
                        </TableCell>
                    </TableRow>
                    <TableRow className='print_trimester_unpaid'>
                        <TableCell>NUM</TableCell>
                        <TableCell align="left" style={{ fontSize: "12px" }}>NOM</TableCell>
                        <TableCell align="left" style={{ fontSize: "12px" }}>POST-NOM</TableCell>
                        <TableCell align="left" style={{ fontSize: "12px" }}>FRAIS</TableCell>
                        <TableCell align="left" style={{ fontSize: "12px" }}>CLASSE</TableCell>
                    </TableRow>
                </TableHead>

                {unPaidList
                    ?
                    <>

                        <TableBody>

                            {unPaidList.filter((item => {

                                // return eleveSearch.toLowerCase() === "" &&
                                return searchByClasse === "TOUTES"
                                    ? (item && eleveSearch.toLowerCase() === "" ? (item) : (item.noms.toLowerCase().includes(eleveSearch)))
                                    :
                                    // item.noms.toLowerCase().includes(eleveSearch) ||
                                    (item.designation.includes(searchByClasse) && item.noms.toLowerCase().includes(eleveSearch))

                            })).map((eleve, index) => {

                                const { noms, compte, designation } = eleve;
                                return (
                                    <TableRow key={index} className={(index % 2) === 0 ? "" : "print_row_color"}>
                                        <TableCell component="th" scope="row">
                                            {`0${index + 1}`}
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: "12px" }}>{noms.split(" ")[0]}</TableCell>
                                        <TableCell align="left" style={{ fontSize: "12px" }}>{noms.split(" ")[1]}</TableCell>
                                        <TableCell align="left" style={{ fontSize: "12px" }}>{compte.toFixed(1)}</TableCell>
                                        <TableCell align="left" style={{ fontSize: "12px" }}>{designation}</TableCell>
                                    </TableRow>)

                            })}
                        </TableBody>
                    </>
                    :
                    <>
                        (<SimpleBackdrop />)
                    </>
                }
            </Table>
        </TableContainer>
    );
}
