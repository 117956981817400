import {
  AccountBalanceWalletOutlined,
  GroupOutlined,
} from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import "./boxThree.scss";
import moment from "moment/moment";
import { PaiementType } from "../../../types/GlobalType";

interface Props {
  data: PaiementType[];
}

const BoxThree = (props: Props) => {
  let paiementNun: number = 0;
  if (props.data?.length > 0) {
    for (let objetItem of props.data) {
      let now: string = moment().format("DD-MM-YYYY");
      let date: string = moment(objetItem.paiement_created_at).format(
        "DD-MM-YYYY"
      );
      if (date === now) {
        paiementNun += paiementNun;
      }
    }
  }
  return (
    <div className="box_three">
      <div className="card_info">
        {/* <span>Elèves</span> */}
        <div className="card_icon">
          <div className="wraper">
            <AccountBalanceWalletOutlined className="ico2" />
          </div>
        </div>
        <h1>{props.data.length}</h1>
        <span>Nombre de paiement</span>
      </div>
    </div>
  );
};

export default BoxThree;
