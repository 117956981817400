import axios from "axios";
import { URL_BACKEND_APP } from "../App.constants";

const url_api = axios.create({
  baseURL: URL_BACKEND_APP + "/personnel",
});

const url_api_ponctualite = axios.create({
  baseURL: URL_BACKEND_APP + "/ponctualite_enseignant",
});

const PERSONNEL = {
  create: (data, token) => {
    return url_api.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return url_api.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return url_api.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return url_api.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return url_api.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

const PONCTUALITE = {
  create: (data, token) => {
    return url_api_ponctualite.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return url_api_ponctualite.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return url_api_ponctualite.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return url_api_ponctualite.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return url_api_ponctualite.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export const API_PERSONNEL = { PERSONNEL, PONCTUALITE };
