import React, { useState } from "react";
import "./singleEleve.scss";

import { Close } from "@mui/icons-material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import imageAvatar from "../../../assets/images/studentpp.png";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
}
const SingleEleve = (props: Props) => {
  const navigate = useNavigate();

  const handleNavidate1 = () => {
    navigate("enregistrement-eleve");
  };
  const handleNavidate2 = () => {
    navigate("parent-info");
  };

  const location = useLocation();
  let currentRoute: string = location.pathname.split("/")[3];

  return (
    <div className="UpdateEleve">
      <div className="dialog">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <span>Profile Elève </span>
            </div>
            <div className="right_">
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </div>
          </div>
        </div>

        <div className="body">
          <div className="avatar_info">
            <div className="img_container">
              <img src={imageAvatar} alt="" />
              <div className="names">
                <h3>Kakule Witestavwirwa Esdras</h3>
                <span>5e sc, Age 17 ans, Genre: M</span>
              </div>
            </div>
            <div className="btns">
              <button>Message</button>
            </div>
          </div>
          <div className="links">
            <span onClick={handleNavidate1}>
              Appercu
              <div
                className={
                  currentRoute === "enregistrement-eleve" ? "underline1" : ""
                }
              ></div>
            </span>

            <span onClick={handleNavidate2}>
              Tuteur
              <div
                className={currentRoute === "parent-info" ? "underline2" : ""}
              ></div>
            </span>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SingleEleve;
