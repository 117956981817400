import React, { useState } from "react";
import "./updateTerminal.scss";

import { Close, SchoolOutlined } from "@mui/icons-material";

import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import { IconButton } from "@mui/material";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const NewTerminal = (props: Props) => {
  return (
    <div className="UpdateTerminal">
      <div className="dialog_update">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <SchoolOutlined className="ico" />
              <span>Enregistrement - Terminal</span>
            </div>
            <IconButton>
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </IconButton>
          </div>
        </div>

        <div className="body_update">
          <div className="item">
            <label>Désignation</label>
            <input
              placeholder="Identifiant"
              type="text"
              name="schoolYear"
              // value={data.nom_Nom}
            />
          </div>
        </div>
        <div className="btn">
          <button onClick={() => props.setOpen(false)}>Annuler</button>
          <button>Enregister</button>
        </div>
      </div>
    </div>
  );
};

export default NewTerminal;
