import React, { useState } from "react";
import "./newFrais.scss";

import { Close, SchoolOutlined } from "@mui/icons-material";

import "react-phone-input-2/lib/style.css";
import { IconButton } from "@mui/material";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const NewFrais = (props: Props) => {
  const [role, setRole] = useState("");

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  return (
    <div className="NewFrais">
      <div className="dialog_update">
        <div className="headerUpdate">
          <div className="titleUpdate">
            <div className="left_">
              <SchoolOutlined className="ico" />
              <span>Enregistrement - Frais scolaire</span>
            </div>
            <IconButton>
              <Close className="ico" onClick={() => props.setOpen(false)} />
            </IconButton>
          </div>
        </div>

        <div className="body_update">
          <div className="item">
            <label>Classe</label>
            <div className="role-selector">
              <select
                value={role}
                onChange={handleChange}
                className="input-field"
                name="role"
              >
                <option value="">--Selectionner une classe--</option>
                <option value="teacher">Enseignant</option>
                <option value="student">Elève</option>
              </select>
            </div>
          </div>
          <div className="item">
            <label>Année scolaire</label>
            <div className="role-selector">
              <select
                value={role}
                onChange={handleChange}
                className="input-field"
                name="role"
              >
                <option value="">--Selectionner année--</option>
                <option value="teacher">Enseignant</option>
                <option value="student">Elève</option>
              </select>
            </div>
          </div>
          <div className="item">
            <label>{"Frais scolaire"}</label>
            <input
              placeholder="Montant"
              type="text"
              name="amount"
              // value={data.nom_Nom}
            />
          </div>
        </div>
        <div className="btn">
          <button onClick={() => props.setOpen(false)}>Annuler</button>
          <button>Enregister</button>
        </div>
      </div>
    </div>
  );
};

export default NewFrais;
