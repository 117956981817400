import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  Alert,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { accountService } from '../../_services/accountServices'
import axios from "axios";
import API from "../../App.constants";
import { accountService } from "../../_services/accountServices";
import SnackbarNotification from "../../component/dialog/confirmation/SnackbarNotification";

const AddCoursDiag = ({ open, setOpen }) => {
  const classeList = accountService.getClasseList();
  const coursList = accountService.getCoursList();
  const personnelList = accountService.getPersonnelList();

  //input states
  const [cours, setcours] = useState("");
  const [newCours, setNewCours] = useState("");
  const [classe, setclasse] = useState("");
  const [personnel, setpersonnel] = useState("");
  const [showNewInput, setshowNewInput] = useState(false);

  //component state
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState({ type: "", text: "" });

  //handleChangeFonctions
  const handleCours = (e) => {
    setcours(e.target.value);
  };
  const handleNewCours = (e) => {
    setNewCours(e.target.value);
  };
  const handleClasse = (e) => {
    setclasse(e.target.value);
  };
  const handlePersonnel = (e) => {
    setpersonnel(e.target.value);
  };

  const handleShowInput = () => {
    if (cours === "Ajouter un nouveau cours") {
      setshowNewInput(true);
    } else {
      setshowNewInput(false);
    }
  };

  //handleSubmit
  const saveCours = () => {
    setMsg((prevState) => ({ ...prevState, type: "", text: "" }));
    if (personnel === "")
      return setMsg((prevState) => ({
        ...prevState,
        type: "emptyInput",
        text: "Veuillez selectionner le personnel",
      }));
    if (classe === "")
      return setMsg((prevState) => ({
        ...prevState,
        type: "emptyInput",
        text: "Veuillez selectionner la classe",
      }));

    if (cours === "Ajouter un nouveau cours") {
      if (newCours === "")
        return setMsg((prevState) => ({
          ...prevState,
          type: "emptyInput",
          text: "Veuillez selectionner le cours",
        }));
      setIsLoading(true);
      axios
        .post(`${API}/api/cours/create`, {
          cours_name: newCours,
          enseignant_id: personnel,
          classe_id: classe,
        })
        .then((res) => {
          setIsLoading(false);
          setNewCours("");
          setpersonnel("");
          setclasse("");
          if (res.data.message === "Success") {
            setMsg((prevState) => ({
              ...prevState,
              type: "success",
              text: "Cours enregistré avec succes",
            }));

            setTimeout(() => {
              setMsg({ type: "", text: "" });
              setOpen(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 505)
            return setMsg((prevState) => ({
              ...prevState,
              type: "emptyInput",
              text: err.response.data.message,
            }));
          setNewCours("");
          setpersonnel("");
          setclasse("");

          if (err.code === "ERR_NETWORK") {
            setMsg((prevState) => ({
              ...prevState,
              type: "ERR_NETWORK",
              text: "Vérifier votre connection Internet et réessayez",
            }));
            setTimeout(() => {
              setMsg({ type: "", text: "" });
            }, 3000);
            return;
          } else {
            setTimeout(() => {
              setMsg((prevState) => ({
                ...prevState,
                type: "ERR_UNKNOWN",
                text: "Une erreur inconnue est survenue, réesayer plus tard a",
              }));
            }, 3000);
            setMsg({ type: "", text: "" });
            return;
          }
        });
    } else {
      if (cours === "")
        return setMsg((prevState) => ({
          ...prevState,
          type: "emptyInput",
          text: "Veuillez selectionner le cours",
        }));

      setIsLoading(true);
      axios
        .post(`${API}/api/cours/create`, {
          cours_name: cours,
          enseignant_id: personnel,
          classe_id: classe,
        })
        .then((res) => {
          setIsLoading(false);
          setcours("");
          setpersonnel("");
          setclasse("");
          if (res.data.message === "Success") {
            setMsg((prevState) => ({
              ...prevState,
              type: "success",
              text: "Cours enregistré avec succes",
            }));

            setTimeout(() => {
              setMsg({ type: "", text: "" });
              setOpen(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          if (err.response.status === 505)
            return setMsg((prevState) => ({
              ...prevState,
              type: "emptyInput",
              text: err.response.data.message,
            }));
          setcours("");
          setpersonnel("");
          setclasse("");

          if (err.code === "ERR_NETWORK") {
            setMsg((prevState) => ({
              ...prevState,
              type: "ERR_NETWORK",
              text: "Vérifier votre connection Internet et réessayez",
            }));
            setTimeout(() => {
              setMsg({ type: "", text: "" });
            }, 4000);
            return;
          } else {
            setMsg((prevState) => ({
              ...prevState,
              type: "ERR_UNKNOWN",
              text: "Une erreur inconnue est survenue, réesayer plus tard b",
            }));
            setTimeout(() => {
              setMsg({ type: "", text: "" });
            }, 4000);
            return;
          }
        });
    }
  };

  useEffect(() => {
    handleShowInput();
  }, [cours]);

  return (
    <>
      {isLoading && (
        <Dialog open={isLoading}>
          <DialogContent
            sx={{
              width: "200px",
              height: "130px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "skyblue" }} />
          </DialogContent>
        </Dialog>
      )}

      <Dialog maxWidth="md" sx={{ height: "550px", top: ".5rem" }} open={open}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            borderBottom: "1px solid #999",
            p: "10px ",
          }}
        >
          <Typography
            variant="h6"
            color="#555"
            sx={{ fontWeight: "600", fontSize: 15, padding: "5px 0px" }}
          >
            Attribution du Cours
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              {/* handle error */}
              {msg.type === "emptyInput" && (
                <Alert severity="error" sx={{ marginY: "5px" }}>
                  <span>{msg.text}</span>
                </Alert>
              )}
              {msg.type === "success" && (
                <SnackbarNotification text={msg.text} severty={"success"} />
              )}
              {msg.type === "ERR_NETWORK" && (
                <SnackbarNotification text={msg.text} severty={"error"} />
              )}
              {msg.type === "ERR_UNKNOWN" && (
                <SnackbarNotification text={msg.text} severty={"warning"} />
              )}

              <div style={{ flex: 1, marginBottom: 10, marginTop: 10 }}>
                <TextField
                  select
                  fullWidth
                  required
                  label="Cours"
                  value={cours}
                  onChange={handleCours}
                  inputProps={{ style: { fontSize: 10 } }}
                >
                  {coursList.map((value, index) => {
                    return (
                      <MenuItem
                        defaultValue={index === 1 ? true : false}
                        value={value}
                        sx={
                          index === 0
                            ? {
                                p: "4px 10px",
                                fontSize: "14px",
                                color: "skyblue",
                              }
                            : { p: "4px 10px", fontSize: "14px", color: "#555" }
                        }
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>

              <div
                style={
                  showNewInput
                    ? { flex: 1, marginBottom: "10px" }
                    : { display: "none" }
                }
              >
                <TextField
                  type="text"
                  placeholder="Nouveau cours"
                  fullWidth
                  required
                  value={newCours}
                  onChange={handleNewCours}
                  inputProps={{ style: { fontSize: 14 } }}
                />
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: 1 }}>
                  <TextField
                    select
                    label="Enseignant"
                    sx={{ width: "350px" }}
                    required
                    value={personnel}
                    onChange={handlePersonnel}
                  >
                    {personnelList.map((value, index) => {
                      return (
                        <MenuItem
                          defaultValue={index === 1 ? true : false}
                          value={value.id}
                          sx={{
                            p: "4px 10px",
                            fontSize: "14px",
                            color: "#555",
                          }}
                        >
                          {value.nom}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>

                <div style={{ flex: 1 }}>
                  <TextField
                    select
                    label="Classe"
                    required
                    sx={{ width: "200px" }}
                    value={classe}
                    onChange={handleClasse}
                  >
                    {classeList.map((val, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={val.id_classe}
                          sx={
                            index === 0
                              ? { display: "none" }
                              : { p: "4px 10px", fontSize: "14px" }
                          }
                        >
                          {val.designation}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{ backgroundColor: "#008080", color: "#fff" }}
                  onClick={saveCours}
                >
                  <Typography variant="span" sx={{ fontWeight: "600" }}>
                    Enregistrer
                  </Typography>
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  sx={{ color: "#008080" }}
                  onClick={() => {
                    setOpen(false);
                    setcours("");
                    setpersonnel("");
                    setclasse("");
                    setMsg({ type: "", text: "" });
                  }}
                >
                  <Typography variant="span" sx={{ fontWeight: "600" }}>
                    Annuler
                  </Typography>
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCoursDiag;
